import { useEffect, useCallback, useState, useRef, useReducer } from 'react';
import './App.css';
import $ from 'jquery';
import 'bootstrap';
import "popper.js";
import "spectrum-colorpicker";
import "./assets/css/spectrum.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./graphs/urlentryggc";
import "./graphs/iconpickerggc";
import ControlledStack from './ControlledStack';
import {Spinner} from 'react-activity';
import "react-activity/dist/library.css";
import "./assets/css/urlentryggc.css";
import "./assets/css/popover.css";
import "./assets/css/rc-input.css";
import "datatables.net-dt/css/jquery.dataTables.css";
import {setRuntimeOnly, setIsQRContest, isDeveloperDomain, getRootDomain, getApiKey, setApiKey, isQRVote, colors, isHierarchicalChart, canSort} from "./utils/Utils";
import { API, Auth } from 'aws-amplify';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import InputNumber from 'rc-input-number';
//import TreeViewSearch from './components/TreeView/TreeViewSearch';
import TreeViewAccordion from './components/TreeView/TreeViewAccordion';
import "./assets/css/amp.css";
import "./assets/css/button.css";
import "./assets/css/iconpickerggc.css";
import "./assets/css/qr.css";
import {FaRegCopy, FaCopy, FaPaste, FaTrash, FaRecycle } from "react-icons/fa";
import {AiOutlineExpand} from "react-icons/ai";
import ToolSeparator from './components/ToolSeparator/ToolSeparator';
import { getRemoteZip } from './utils/zipstuff';
import { 
  setDatabaseFiles,
  getGanttData,
 } from './utils/localdb';

import { 
  createDashboardRecord, 
  updateDashboardRecord,
  createThemeRecord, 
  updateThemeRecord,
  getDashboardRecord, 
  getAPIDashboardsByClient,
  getCompleteTreeView,
  getMyDashboardKey,
  getClientNamesFromList,
  getDashboardThemesByClient,
  getDashboardTheme,
  updateTreeViewRQA,
  deleteTreeViewRQA,
  deleteDashboardRecord,
  deleteThemeRecord,
 } from './utils/dbs';
import { 
  onCreateProjectByClientId, 
  onDeleteProjectByClientId, 
  onUpdateProjectByClientId,

  onCreateCampaignByClientId,
  onDeleteCampaignByClientId, 
  onUpdateCampaignByClientId,

  onCreateRQAByClientId,
  onDeleteRQAByClientId,
  onUpdateRQAByClientId,

} from './subscriptions';
import IconPicker from './components/IconPicker/IconPicker';
import iconBar from './images/bar.svg';
import iconSBar from './images/sbar.svg';
import iconHBar from './images/hbar.svg';
import iconSHBar from './images/shbar.svg';
import iconLine from './images/line.svg';
import iconSpline from './images/spline.svg';
import iconASpline from './images/aspline.svg';
import iconPie from './images/pie.svg';
import iconDonut from './images/donut.svg';
import iconSunburst from './images/sunburst.svg';
import iconTreemap from './images/treemap.svg';
import iconCpack from './images/cpack.svg';
import iconPivot from './images/pivot.svg';
import qrLogo from './images/qrlogo.png';
import UserMenu from './components/UserMenu/UserMenu';
import GGCMenu from './components/GGCMenu/GGCMenu';
import DialogModal from './components/DialogModal/DialogModal';
import toast, {Toaster} from 'react-hot-toast';
import ColorPicker from './components/ColorPicker/ColorPicker';
import OpenTable from './components/OpenTable/OpenTable';
import { _genId, genCleanId } from './graphs/domutils';
import IconCheckbox from './components/IconCheckbox/IconCheckbox';
import LeftCheckbox from './components/LeftCheckbox/LeftCheckbox';
import ViewHideIcon from './components/ViewHideIcon/ViewHideIcon';
import { ganttGGC } from './graphs/ganttggc';
import AccordionGroup from './components/AccordionGroup/AccordionGroup';
import { useTranslation } from 'react-i18next';
import LinkUnlink from './components/LinkUnlink/LinkUnlink';
import questionTreeData from './utils/gvars';
// DnD
import { DropArea, dropAreaPadding } from './components/DnD/DropArea';
import { dndLabelHeight } from './components/DnD/DnDLabel';
import { ItemTypes } from './components/DnD/DnDItemTypes';
import html2canvas from 'html2canvas';

const _aspectRatios = [
  "fit",
  "1:1",
  "2:1",
  "3:1",
  "4:1",
  "5:1",
  "1:2",
  "1:3",
  "1:4",
  "1:5",
  "5:4",
  "4:3",
  "3:2",
  "16:10",
  "16:9",
  "4:5",
  "3:4",
  "2:3",
  "custom"
];


const copyBufferReducer = (state, action) => {
  switch (action.type) {
    case "setLocationWidthHeight":
      return {
        ...state,
        locationWidthHeight: action.value,
      }
    case "setTextStyles":
      return {
        ...state,
        textStyles: action.textStyles,
      }
    default:
      break;
  }
  return {
    ...state
  }
}

const refreshReducer = (state, action) => {
  switch (action.type) {
    case "addOne":
      return {
        ...state,
        refreshCount: state.refreshCount + 1,
      }
    default:
      break;
  }
  return {
    ...state
  }
}

  // {root... children: [<projects>]}  <projects> has children: [<campaigns>]  <campaigns> has children: [<locations>]
  // <locations> has children: [<questions>]
const treeReducer = (state, action) => {
  switch (action.type) {
    case "fetching":
      return {
        ...state,
        fetching: action.fetching,
      }

    case "setItems":
      return {
        ...state,
        treeSource: action.items,
      }
    case "addProject":
      {
        let item = action.item;
        let oldTree = JSON.parse(JSON.stringify(state.treeSource));
        let proj = oldTree.children.find((p) => p.id === item.id);
        if (!proj) {
          oldTree.children.push({
            id: item.id,
            name: item.name,
            children: [],
          });
          return {
            ...state,
            treeSource: oldTree,
          }
        }
      }
      break;

    case "updateProject":
      {
        let item = action.item;
        let oldTree = JSON.parse(JSON.stringify(state.treeSource));
        let proj = oldTree.children.find((p) => p.id === item.id);
        if (proj) {
          proj.name = item.name;
          return {
          ...state,
          treeSource: oldTree,
          }
        }    
      }
      break;

    case "deleteProject":
      {
        let item = action.item;
        let oldTree = JSON.parse(JSON.stringify(state.treeSource));
        let ixDel = oldTree.children.findIndex((p) => p.id === item.id);
        if (ixDel !== -1) {
          oldTree.children.splice(ixDel, 1);
          return {
            ...state,
            treeSource: oldTree,
          }
        }    
      }
      break;
    case "addCampaign":
      {
        let item = action.item;
        let oldTree = JSON.parse(JSON.stringify(state.treeSource));
        let proj = oldTree.children.find((p) => p.id === item.projectID);
        if (proj) {
          if (!proj.children) {
            proj.children = [];
          }
          let camp = proj.children.find((c) => c.id === item.id);
          if (!camp) {
            proj.children.push({
              id: item.id,
              name: item.name,
              children: [],
            });
            return {
              ...state,
              treeSource: oldTree,
            }
          }
        }    
      }
      break;
    case "updateCampaign":
      {
        let item = action.item;
        let oldTree = JSON.parse(JSON.stringify(state.treeSource));
        let proj = oldTree.children.find((p) => p.id === item.projectID);
        if (proj) {
          if (!proj.children) {
            proj.children = [];
          }
          let camp = proj.children.find((c) => c.id === item.id);
          if (camp) {
            camp.name = item.name;
            return {
              ...state,
              treeSource: oldTree,
            }
          }
        }    
      }
      break;
    case "deleteCampaign":
      {
        let item = action.item;
        let oldTree = JSON.parse(JSON.stringify(state.treeSource));
        let proj = oldTree.children.find((p) => p.id === item.projectID);
        if (proj) {
          if (!proj.children) {
            proj.children = [];
          }
          let ixCamp = proj.children.findIndex((c) => c.id === item.id);
          if (ixCamp !== -1) {
            proj.children.splice(ixCamp, 1);
            return {
              ...state,
              treeSource: oldTree,
            }
          }
        }    
      }
      break;
    case "addRQA":
    case "updateRQA":
      // update could be location name, quesiton text or some answer...
      return {
        ...state,
        treeSource: updateTreeViewRQA(action.item, state.treeSource),
      }

    case "deleteRQA":
      // find enclosing campaign (if any), find matching location (if any), then find matching question (if any)
      // then, remove question from location.children if empty, remove location from campaign.children if empty,
      // remove campaign from project.children if empty, remove project from treeSource.children if empty
      return  {
        ...state,
        treeSource: deleteTreeViewRQA(action.item, state.treeSource),
      }

    case "addLocation":
      {
        let item = action.item;
        let oldTree = JSON.parse(JSON.stringify(state.treeSource));
        let proj = oldTree.children.find((p) => p.id === item.projectID);
        if (proj) {
          if (!proj.children) {
            proj.children = [];
          }
          let camp = proj.children.find((c) => c.id === item.campaignID);
          if (camp) {
            if (!camp.children) {
              camp.children = [];
            }
            let loc = camp.children.find((l) => l.id === item.id);
            if (!loc) {
              camp.children.push({
                id: item.id,
                name: item.name,
                children: [],
              });
              return {
                ...state,
                treeSource: oldTree,
              }
            }
          }
        }
      }
      break;
    case "updateLocation":
      {
        let item = action.item;
        let oldTree = JSON.parse(JSON.stringify(state.treeSource));
        let proj = oldTree.children.find((p) => p.id === item.projectID);
        if (proj) {
          if (!proj.children) {
            proj.children = [];
          }
          let camp = proj.children.find((c) => c.id === item.campaignID);
          if (camp) {
            if (!camp.children) {
              camp.children = [];
            }
            let loc = camp.children.find((l) => l.id === item.id);
            if (loc) {
              loc.name = item.name;
              return {
                ...state,
                treeSource: oldTree,
              }
            }
          }
        }
      }
      break;
    case "deleteLocation":
      {
        let item = action.item;
        let oldTree = JSON.parse(JSON.stringify(state.treeSource));
        let proj = oldTree.children.find((p) => p.id === item.projectID);
        if (proj) {
          if (!proj.children) {
            proj.children = [];
          }
          let camp = proj.children.find((c) => c.id === item.campaignID);
          if (camp) {
            if (!camp.children) {
              camp.children = [];
            }
            let ixLoc = camp.children.findIndex((l) => l.id === item.id);
            if (ixLoc !== -1) {
              camp.children.splice(ixLoc, 1);
              return {
                ...state,
                treeSource: oldTree,
              }
            }
          }
        }
      }
      break;
    case "addQuestion":
      {
        let item = action.item;
        let oldTree = JSON.parse(JSON.stringify(state.treeSource));
        let proj = oldTree.children.find((p) => p.id === item.projectID);
        if (proj) {
          if (!proj.children) {
            proj.children = [];
          }
          let camp = proj.children.find((c) => c.id === item.campaignID);
          if (camp) {
            if (!camp.children) {
              camp.children = [];
            }
            let loc = camp.children.find((l) => l.id === item.locationID);
            if (loc) {
              if (!loc.children) {
                loc.children = [];
              }
              let quest = loc.children.find((q) => q.id === item.id);
              if (!quest) {
                loc.children.push({
                  id: item.id,
                  name: item.text,
                  children: [],
                });
                return {
                  ...state,
                  treeSource: oldTree,
                }
              }
            }
          }
        }
      }
      break;
    case "updateQuestion":
      {
        let item = action.item;
        let oldTree = JSON.parse(JSON.stringify(state.treeSource));
        let proj = oldTree.children.find((p) => p.id === item.projectID);
        if (proj) {
          if (!proj.children) {
            proj.children = [];
          }
          let camp = proj.children.find((c) => c.id === item.campaignID);
          if (camp) {
            if (!camp.children) {
              camp.children = [];
            }
            let loc = camp.children.find((l) => l.id === item.locationID);
            if (loc) {
              if (!loc.children) {
                loc.children = [];
              }
              let quest = loc.children.find((q) => q.id === item.id);
              if (quest) {
                quest.name = item.text;
                return {
                  ...state,
                  treeSource: oldTree,
                }
              }
            }
          }
        }
      }
      break;
    case "deleteQuestion":
      {
        let item = action.item;
        let oldTree = JSON.parse(JSON.stringify(state.treeSource));
        let proj = oldTree.children.find((p) => p.id === item.projectID);
        if (proj) {
          if (!proj.children) {
            proj.children = [];
          }
          let camp = proj.children.find((c) => c.id === item.campaignID);
          if (camp) {
            if (!camp.children) {
              camp.children = [];
            }
            let loc = camp.children.find((l) => l.id === item.locationID);
            if (loc) {
              if (!loc.children) {
                loc.children = [];
              }
              let ixQuest = loc.children.findIndex((q) => q.id === item.id);
              if (ixQuest !== -1) {
                loc.children.splice(ixQuest, 1);
                return {
                  ...state,
                  treeSource: oldTree,
                }
              }
            }
          }
        }
      }
      break;

    default:
      break;
  }
  return { ...state }
}

const ACC_DATA_SELECTION = 0;
const ACC_GRAPH_TYPE = 1;
const ACC_GRAPH_SETTINGS = 2;
const ACC_DASHBOARD_SETTINGS = 3;
const ACC_THEMES = 4;
const ACC_EDIT_GRAPH = 5;


function App() {
  const stackRef = useRef(null);
  
  const [t] = useTranslation();

  /*
  const [baseIds, setBaseIds] = useState(null);
  */
  const [configParams, setConfigParams] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(-1);
  const [gridHeight, setGridHeight] = useState(5);
  const [gridWidth, setGridWidth] = useState(5);
  const [isDeveloper, setIsDeveloper] = useState(false);
  const [checkedDeveloperStatus, setCheckedDeveloperStatus] = useState(false);
  const [toCheckDev, setToCheckDev] = useState(false);
  const [checkingDevStatus, setCheckingDevStatus] = useState(false);
  const [devChecked, setDevChecked] = useState(false);
  const [tryUI, setTryUI] = useState(false);
  const [toggleEditDisabled, setToggleEditDisabled] = useState(true);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  const [clientId, setClientId] = useState(null);
  const [pivot, setPivot] = useState(false);
  const [gridLocked, setGridLocked] = useState(true);
  const [pivotDisabled, setPivotDisabled] = useState(true);
  const [showQuestion, setShowQuestion] = useState(true);
  const [showLocationName, setShowLocationName] = useState(true);
  const [lockLocation, setLockLocation] = useState(false);
  const [showCampaignName, setShowCampaignName] = useState(true);
  const [lockCampaign, setLockCampaign] = useState(false);
  const [showSorter, setShowSorter] = useState(true);
  const [lockSorter, setLockSorter] = useState(false);
  const [graphType, setGraphType] = useState('bar');
  const [hasImages, setHasImages] = useState(false);
  const [enableApplyImages, setEnableApplyImages] = useState(false);
  const [iconPickerDisabled, setIconPickerDisabled] = useState(true);
  const [selectionInEditMode, setSelectionInEditMode] = useState(false);

  const [changeTeamButton, setChangeTeamButton] = useState(t("menu.change_team"));

  // Graph Save Props to storage
  // pivot gets passed down to the graph to save
  // these 2 are for 'fit' aspect
  const [aspect, setAspect] = useState({name: 'fit', ratio: {w: 1, h: 1}});
  const [aspectPosition, setAspectPosition] = useState({top: 0, left: 0, bottom: 0, right: 0});
  const [dashboardBackgroundColor, setDashboardBackgroundColor] = useState('#f3f4f9');
  const [cellBackgroundColor, setCellBackgroundColor] = useState('');

  const [expandedMenuId, setExpandedMenuId] = useState(-1);
  const [menuFileNewDisabled, setMenuFileNewDisabled] = useState(false);
  const [menuFileOpenDisabled, setMenuFileOpenDisabled] = useState(false);
  const [menuFileSaveDisabled, setMenuFileSaveDisabled] = useState(true);
  const [menuFileSaveAsDisabled, setMenuFileSaveAsDisabled] = useState(true);
  const [dashboardTitle, setDashboardTitle] = useState(t("untitled"));
  const [dashboardOpenId, setDashboardOpenId] = useState(null);
  const [menuDataChangeDisabled, setMeuDataChangeDisabled] = useState(false);

  // Dialog
  const [dialogOpened, setDialogOpened] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogButtons, setDialogButtons] = useState([]);
  const [dialogContent, setDialogContent] = useState(null);
  const [dialogSmall, setDialogSmall] = useState(false);

  // Graph Question text
  const [txtFontStyle, setTxtFontStyle] = useState('normal');
  const [txtFontWeight, setTxtFontWeight] = useState('normal');
  const [txtDecoration, setTxtDecoration] = useState('none');
  const [txtColor, setTxtColor] = useState({r: 0, g: 0, b: 0, a: 1.0});
  // Location Text
  const [txtLocationFontStyle, setTxtLocationFontStyle] = useState('normal');
  const [txtLocationFontWeight, setTxtLocationFontWeight] = useState('normal');
  const [txtLocationDecoration, setTxtLocationDecoration] = useState('none');
  const [txtLocationColor, setTxtLocationColor] = useState({r: 0, g: 0, b: 0, a: 1.0});
  // Campaign Text 
  const [txtCampaignFontStyle, setTxtCampaignFontStyle] = useState('normal');
  const [txtCampaignFontWeight, setTxtCampaignFontWeight] = useState('normal');
  const [txtCampaignDecoration, setTxtCampaignDecoration] = useState('none');
  const [txtCampaignColor, setTxtCampaignColor] = useState({r: 0, g: 0, b: 0, a: 1.0});
  // Sorter text
  const [txtSorterFontStyle, setTxtSorterFontStyle] = useState('normal');
  const [txtSorterFontWeight, setTxtSorterFontWeight] = useState('normal');
  const [txtSorterDecoration, setTxtSorterDecoration] = useState('none');
  const [txtSorterColor, setTxtSorterColor] = useState({r: 0, g: 0, b: 0, a: 1.0});

  const [expandedAccordion, setExpandedAccordion] = useState(ACC_DATA_SELECTION);

  const [itemXLocation, setItemXLocation] = useState(.33);
  const [itemYLocation, setItemYLocation] = useState(.33);
  const [itemWidthLocation, setItemWidthLocation] = useState(.33);
  const [itemHeightLocation, setItemHeightLocation] = useState(.33);

  const [noResponseData, setNoResponseData] = useState(false);

//DnD
const [labelItems, setLabelItems] = useState([])
const [categoryItems, setCategoryItems] = useState([]);
const [legendItems, setLegendItems] = useState([]);

//  const [clientList, setClientList] = useState(null);

  const refreshStateInit = {
    refreshCount: 0,
  }
  const [refreshState, refreshDispatch] = useReducer(refreshReducer, refreshStateInit);
  //const [refreshCount, setRefreshCount] = useState(0);

  const copyBufferStateInit = {
    locationWidthHeight: null,
    textStyles: null,
  }
  const [copyBufferState, copyBufferDispatch] = useReducer(copyBufferReducer, copyBufferStateInit);

  // Move treeSource to a reducer so we don't have to refresh the subscriptions so much
  const treeStateInit = {
    fetching: false,
    treeSource: null,
  }
  const [treeState, treeDispatch] = useReducer(treeReducer, treeStateInit);
  const [accGroup, setAccGroup] = useState([]);
	
  //const [checkedLeaves, setCheckedLeaves] = useState({});
  const [graphItems, setGraphItems] = useState([]);
  const [editingItem, setEditingItem] = useState(null);

  const [user, setUser] = useState(null);
  let route, signOut;
  if (isDeveloperDomain()) {
     // eslint-disable-next-line react-hooks/rules-of-hooks
     route = useAuthenticator((context) => [context.route]).route;
     // eslint-disable-next-line react-hooks/rules-of-hooks
     signOut = useAuthenticator((context) => [context.user]).signOut;
  }

  const toolsWidth = 250;


  const cellBgPicked = (color) => {
    if (selectedItem !== -1) {
      stackRef.current.setCellBackgroundColor(color);
    }
  };

  const saveGraphProperties = (gtype) => {
    if (stackRef.current) {
      switch (gtype) {
        case 'hbar':
        case 'shbar':
        case 'bar':
        case 'sbar':
        case 'line':
        case 'spline':
        case 'aspline':
          {
            const paletteProps = stackRef.current.getPalettePropsOnly();
            const baseProps = stackRef.current.getBasePropsOnly();
            const axisProps = stackRef.current.getAxisPropsOnly();
            return {...paletteProps, ...baseProps, ...axisProps};
          }
        case 'circlepack':
        case 'treemap':
        case 'sunburst':
        case 'pieggc':
        default:
          {
            const paletteProps = stackRef.current.getPalettePropsOnly();
            const baseProps = stackRef.current.getBasePropsOnly();
            return {...paletteProps, ...baseProps};
          }
      }
    } else {
      return {};
    }
  }

  const graphChoicePicked = useCallback((gtype) => {
    if (selectedItem !== -1) {
      let cp = JSON.parse(JSON.stringify(configParams));

      let piv;
      if (isHierarchicalChart(gtype)) {
        if (legendItems.length > 0) {
          let lbls = [...labelItems, ...legendItems];
          setLabelItems(lbls);
        }
        cp.stackProps.gridProps[selectedItem].saveProps.legends = [];
        setLegendItems([]);
        let cats = categoryItems.map((itm) => {return itm.name});
        cp.stackProps.gridProps[selectedItem].saveProps.categories = cats;
        if (stackRef && stackRef.current) {
          stackRef.current.setProperty({categories: cats, legends: []});
        }

        //Fa $('#checkboxPivot').prop('disabled', true);
        setPivotDisabled(true);
        $('#pivotLabel').css('color', 'gray');
        //Fa $('#checkboxPivot').prop('checked', false);
        piv = false;
      } else {
        //TODO: transfer current settings to the saveProps.categories and legends (if OK)
        /*
        let cats = cp.stackProps.gridProps[selectedItem].saveProps.categories;
        let legs = cp.stackProps.gridProps[selectedItem].saveProps.legends;
        let usedFields = [...cats, ...legs];
        let allFields = ['campaign', 'location', 'answer'];
        let unusedFields = allFields.filter((itm) => {
          return usedFields.indexOf(itm) === -1;
        });
        setLabelItems(unusedFields.map((itm) => {return ({name: itm})}));
        setCategoryItems(cats.map((itm) => {return ({name: itm})}));
        setLegendItems(legs.map((itm) => {return ({name: itm})}));
        */
       let cats = categoryItems.map((itm) => itm.name);
       let legs = legendItems.map((itm) => itm.name);
        cp.stackProps.gridProps[selectedItem].saveProps.categories = cats;
        cp.stackProps.gridProps[selectedItem].saveProps.legends = legs;
        if (stackRef && stackRef.current) {
          stackRef.current.setProperty({categories: cats, legends: legs});
        }


        piv = stackRef.current.getPivot();    // which probably is just gridProps[selectedItem].saveProps.pivot
        //Fa $('#checkboxPivot').prop('disabled', false);
        setPivotDisabled(false);

        $('#pivotLabel').css('color', 'black');
      }

      setPivot(piv);

      // stackRef.current.saveProps() will return the graphProps for the selected item - so do we pass them
      // down (the ones that we can...) to the 'new' graphType? e.g. copy hbar props, then set on pie.  How do we
      // determin which props we can pass down?
      // treemap, sunburst, pie, donut - _base_saveProps()
      // bar, sbar, shbar, hbar, line, spline, aspline - _axis_saveProps()
      // Now, to 'reset' this to a default state, we need to set saveProps to something 'known' (or a theme)
      // TODO: so far, this doesn't work for margin, change from horiz to vert messes up left margin, for example
      // TODO: would need to invoke a 'resize' to redraw the graph with the new settings

      const oldProps = saveGraphProperties(graphType);
      if (oldProps.hasOwnProperty('margin')) {
        delete oldProps.margin;
      }

      // Should really save Graph Background Color and Question Text Attributes here as well.
      cp.stackProps.gridProps[selectedItem].saveProps.saveProps = oldProps;
      cp.stackProps.gridProps[selectedItem].saveProps.pivot = piv;
      cp.stackProps.gridProps[selectedItem].saveProps.graphType = gtype;
      setConfigParams(cp);
      setGraphType(gtype);
    }
  }, [selectedItem, graphType, legendItems, labelItems, categoryItems]);


  useEffect(() => {
    if (isDeveloperDomain() && isDeveloper) {
      let ag = [
        { title: t('app.acc_data_selection'), id: ACC_DATA_SELECTION },  // App.acc_data_selection
        { title: t('app.acc_graph_type'), id: ACC_GRAPH_TYPE },
        { title: t('app.acc_graph_settings'), id: ACC_GRAPH_SETTINGS },
        { title: t('app.acc_dashboard_settings'), id: ACC_DASHBOARD_SETTINGS },
        { title: t('app.acc_themes'), id: ACC_THEMES },
        { title: t('app.acc_edit_graph'), id: ACC_EDIT_GRAPH },
      ]
      setAccGroup(ag);
    }

  }, [isDeveloper]);

  useEffect(() => {
    let enableApply = false;
    if (hasImages) {
      if (isHierarchicalChart(graphType)) {
        enableApply = true;
      } else if (pivot) {
        enableApply = true;
      }
    }
    setEnableApplyImages(enableApply);
  }, [hasImages, graphType, pivot]);

  const defaultEmptyConfig = () => {
    return (
      {
        uiProps: {
          aspect: {
            name: "fit",  // ratio: only used w/ custom; otherwise parse name if not 'fit'
            ratio: {
              w: 2,
              h: 1
            }
          },
          grid: {
            width: 5,
            height: 5,
          }
        },
        stackProps: {
          resizable: true,
          draggable: true,
        }
      }
    )
  }

  useEffect(() => {
    let mounted = true;

    let baseId = null;    // only used for qr-contest
    let projectId = null; // only used for qr-contest

    // pass ID of saved configuration in the query string.
    const fetchGraphConfig = async (gid) => {
      // await fetch em...
      if (!mounted) return;

      console.log('fetchGraphConfig');
      setLoading(true);
      // 1st one: 5fe895f6-0e67-4f60-bd90-12615dda5ee0
      try {
        const mySaved = await getDashboardRecord(gid);

        if (mySaved && mounted) {
          setDashboardOpenId(gid);
          setDashboardTitle(mySaved.name);

          setRuntimeOnly(true);

          if (mySaved.hasOwnProperty('props')) {
            const props = mySaved.props;

            if (props.uiProps) {
              if (props.uiProps.aspect) {
                setAspect(props.uiProps.aspect);
              }
              if (props.uiProps.dashboardBackgroundColor) {
                setDashboardBackgroundColor(props.uiProps.dashboardBackgroundColor);
              }
              if (props.uiProps.grid) {
                if (props.uiProps.grid.height) {
                  setGridHeight(props.uiProps.grid.height);
                }
                if (props.uiProps.grid.width) {
                  setGridWidth(props.uiProps.grid.width);
                }
              }
            }

            setMenuFileSaveDisabled(false);
            setMenuFileSaveAsDisabled(false);
    
            // The db can store multiple projects in a single dashboard, however, we are
            // limiting it to 1 for now so we can clamp to a single project
            if (props.stackProps && props.stackProps.gridProps &&
              props.stackProps.gridProps.length > 0) {

              //qr-contest: insert new baseId, projectId here. used as a generic dashboard for all qr-contests
              if (baseId && projectId) {
                setIsQRContest(true);
                props.stackProps.gridProps[0].projectId = projectId;
                props.stackProps.gridProps[0].baseId = baseId;
                props.stackProps.gridProps[0].leafType = 'assignment';
                props.stackProps.gridProps[0].saveProps.showLocationName = false;
                props.stackProps.gridProps[0].saveProps.showCampaignName = false;
              }


              props.stackProps.gridProps.forEach((gp) => {
                gp._uid = genCleanId();
              });
            }
            setConfigParams(props);

          }
        }
     } catch (err) {
        console.log(err);
      }
      if (mounted) {
        setLoading(false);
      }

    }

    var gid = null;
    if (window.location.search && window.location.search.length > 0) {
      const params = window.location.search.substring(1).split('&');
      params.forEach(param => {
        try {
          const key = param.split('=')[0];
          const value = decodeURIComponent(param.split('=')[1]);
          if (key === 'gid') {
            gid = value;
          } else if (key === 'dbkey') {
            setApiKey(value);
          } else if (key === 'bid') {
            baseId = value;
          } else if (key === 'pid') {
            projectId = value;
          }
          //e.g. Flower Contest projectId=ed20fc13-727f-4570-ae61-4963d253dca7
          // baseId=8c766455-5e2f-4b13-82f6-ebde07652786_ecfe0d22-596b-49b6-942c-f8af55259c4e_91df44d8-ad8f-40a2-89e3-c6179e28d7e5_92cf80d3-ab75-4766-ab3d-37ba8ac9ef36
        } catch (err) {
          console.log(err);
        }        
      });
    }

    if (gid) {
      fetchGraphConfig(gid);
    } else {
      let cp = defaultEmptyConfig();
      setAspect(cp.uiProps.aspect);
      setConfigParams(cp);
    }

    return () => {
      mounted = false;
    }

  }, []);


  useEffect(() => {
    refreshDispatch({type: 'addOne'});
  }, [dimensions]);

  const actuallyDeleteGraph = async (delUid) => {
    let cp = JSON.parse(JSON.stringify(configParams));
    let ix = cp.stackProps.gridProps.findIndex((gp) => { return gp._uid === delUid });
    if (ix !== -1) {
      if (cp.stackProps.gridProps[ix].leafType === 'question') {
        if (questionTreeData.hasOwnProperty(cp.stackProps.gridProps[ix].baseId)) {
          delete questionTreeData[cp.stackProps.gridProps[ix].baseId];
        }
      }
      cp.stackProps.gridProps.splice(ix, 1);
    }
    if (cp.stackProps.gridProps.length === 0) {
      setMenuFileSaveDisabled(true);
      setMenuFileSaveAsDisabled(true);
    }

    if (selectedItem === ix) {
      setToggleEditDisabled(true);
      setEnableApplyImages(false);
      //Fa $('#checkboxPivot').prop('disabled', true);
      setPivotDisabled(true);
      $('#pivotLabel').css('color', 'grey');
      setIconPickerDisabled(true);
      // Need to tell QRGridLayout to clear selectedItem to -1
      stackRef.current.setSelectedItem(-1);
      localSetSelectedItem(-1);
    }

    let newItems = [...graphItems];
    ix = newItems.findIndex((gp) => { return gp._uid === delUid });
    if (ix !== -1) {
      newItems.splice(ix, 1);
      setGraphItems(newItems);
    }

    setConfigParams(cp);
  }

  const deleteGraphDialog = (delId, name) => {
    if (typeof delId === 'undefined') {
      delId = configParams.stackProps.gridProps[selectedItem]._uid;
    }
    if (typeof name === 'undefined') {
      name = '';
    } else {
      name = '[' + name + ']';
    }
    setDialogTitle(t("dlg.title_delete_graph"));
    setDialogContent(
      <div style={{ flex: 'auto', overflow: 'auto' }}>
        {t("dlg.question_sure_delete_graph", {name: name})}
      </div>
    );
    setDialogSmall(true);
    setDialogButtons([
      {
        label: t('no'),
        action: 'cancel',
        onClick: () => {
          setDialogOpened(false);
        }
      },
      {
        label: t('yes'),
        action: 'delete',
        onClick: () => { 
          setDialogOpened(false);
          actuallyDeleteGraph(delId);
        }
      },
    ]);
    setDialogOpened(true);
  }

  // Avoid triggering delete if we have a selectedItem *and* we are in an input field of the controls
  const handleKeyPress = useCallback((event) => {
    if (!(event.srcElement && event.srcElement.nodeName === "INPUT") && event.keyCode === 8) {
      if (selectedItem !== -1) {
        // Confirm delete
        deleteGraphDialog();
      }
    }
  }, [selectedItem, configParams]);

	useEffect(() => {
    /*
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }, 1000)
    */
    const quickHandleResize = function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }

		window.addEventListener('resize', quickHandleResize);
    document.addEventListener('keydown', handleKeyPress);

		return () => {
      document.removeEventListener('keydown', handleKeyPress);

      window.removeEventListener('resize', quickHandleResize)
    };
	}, [handleKeyPress]);


  // Subscriptions
  function subscribeToOnCreateProject()  {
    const subscription = API.graphql({
      query: onCreateProjectByClientId,
      variables: {
        clientID: clientId
      },
    }).subscribe({
      next: ({ provider, value }) => {
        console.log({ provider, value });
        const item = value.data.onCreateProjectByClientId;
        treeDispatch({type: 'addProject', item: item});
      },
      error: (error) => console.warn(error),
    });

    return subscription;
  }
  function subscribeToOnUpdateProject()  {
    const subscription = API.graphql({
      query: onUpdateProjectByClientId,
      variables: {
        clientID: clientId
      },
    }).subscribe({
      next: ({ provider, value }) => {
        const item = value.data.onUpdateProjectByClientId;
        treeDispatch({type: 'updateProject', item: item});
      },
      error: (error) => console.warn(error),
    });

    return subscription;
  }

  function subscribeToOnDeleteProject()  {
    const subscription = API.graphql({
      query: onDeleteProjectByClientId,
      variables: {
        clientID: clientId
      },
    }).subscribe({
      next: ({ provider, value }) => {
        const item = value.data.onDeleteProjectByClientId;
        treeDispatch({type: 'deleteProject', item: item});
      },
      error: (error) => console.warn(error),
    });

    return subscription;
  }

  //Campaigns
  function subscribeToOnCreateCampaign() {
    const subscription = API.graphql({
      query: onCreateCampaignByClientId,
      variables: {
        clientID: clientId,
      },
    }).subscribe({
      next: ({ provider, value }) => {
        const item = value.data.onCreateCampaignByClientId;
        treeDispatch({type: 'addCampaign', item: item});
      },
      error: (error) => console.warn(error),
    });

    return subscription;
  }

  function subscribeToOnUpdateCampaign() {
    const subscription = API.graphql({
      query: onUpdateCampaignByClientId,
      variables: {
        clientID: clientId,
      },
    }).subscribe({
      next: ({ provider, value }) => {
        const item = value.data.onUpdateCampaignByClientId;
        treeDispatch({type: 'updateCampaign', item: item});
      },
      error: (error) => console.warn(error),
    });

    return subscription;
  }
  function subscribeToOnDeleteCampaign() {
    const subscription = API.graphql({
      query: onDeleteCampaignByClientId,
      variables: {
        clientID: clientId,
      },
    }).subscribe({
      next: ({ provider, value }) => {
        const item = value.data.onDeleteCampaignByClientId;
        treeDispatch({type: 'deleteCampaign', item: item});
      },
      error: (error) => console.warn(error),
    });

    return subscription;
  }

  //TODO: RQA - when we imlement this, be sure to set the imageUrl on the answers if there.

  function subscribeToOnCreateRQA() {
    const subscription = API.graphql({
      query: onCreateRQAByClientId,
      variables: {
        clientID: clientId,
      },
    }).subscribe({
      next: ({ provider, value }) => {
        let item = value.data.onCreateRQAByClientId;
        item.questionAnswers = JSON.parse(item.questionAnswers);
        treeDispatch({type: 'addRQA', item: item});
      },
      error: (error) => console.warn(error),
    });

    return subscription;
  }

  function subscribeToOnUpdateRQA() {
    const subscription = API.graphql({
      query: onUpdateRQAByClientId,
      variables: {
        clientID: clientId,
      },
    }).subscribe({
      next: ({ provider, value }) => {
        let item = value.data.onUpdateRQAByClientId;
        item.questionAnswers = JSON.parse(item.questionAnswers);
        treeDispatch({type: 'updateRQA', item: item});
      },
      error: (error) => console.warn(error),
    });

    return subscription;
  }
  function subscribeToOnDeleteRQA() {
    const subscription = API.graphql({
      query: onDeleteRQAByClientId,
      variables: {
        clientID: clientId,
      },
    }).subscribe({
      next: ({ provider, value }) => {
        const item = value.data.onDeleteRQAByClientId;
        item.questionAnswers = JSON.parse(item.questionAnswers);
        treeDispatch({type: 'deleteRQA', item: item});
      },
      error: (error) => console.warn(error),
    });

    return subscription;
  }


  /*
  const fetchRQA = async (campId) => {
    if (campId) {
      if (!rqaState.campaignHash[campId]) {
        rqaDispatch({ type: 'fetching', fetching: true });
        const retv = await listResponseQA(campId, clientId); 
        rqaDispatch({ type: 'fetching', fetching: false });
        if (retv) {
          rqaDispatch({ type: 'setItems', campaignId: campId, items: retv });
          if (retv.length > 0) {
            setActiveRQA(retv[0].id);
          }
        } else {
          console.log('fetchRQA error');
        }
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchRQA(activeCampaign);
    }
    fetchData();
  }, [activeCampaign, rqaState.campaignHash]);
  */

  useEffect(() => {
    if (clientId && treeState.treeSource) {
      const onCreate = subscribeToOnCreateRQA();
      const onDelete = subscribeToOnDeleteRQA();
      const onUpdate = subscribeToOnUpdateRQA();
      return () => {
        onCreate.unsubscribe();
        onDelete.unsubscribe();
        onUpdate.unsubscribe();
      };
    }
  }, [clientId, treeState.treeSource]);

  useEffect(() => {
    if (clientId && treeState.treeSource) {
      const onCreateCampaignSub = subscribeToOnCreateCampaign();
      const onDeleteCampaignSub = subscribeToOnDeleteCampaign();
      const onUpdateCampaignSub = subscribeToOnUpdateCampaign();
      return () => {
        onCreateCampaignSub.unsubscribe();
        onDeleteCampaignSub.unsubscribe();
        onUpdateCampaignSub.unsubscribe();
      };
    }
  }, [clientId, treeState.treeSource]);

  useEffect( () => {
    if (clientId && treeState.treeSource) {
      const onCreateSub = subscribeToOnCreateProject();
      const onDeleteSub = subscribeToOnDeleteProject();
      const onUpdateSub = subscribeToOnUpdateProject();
      return () => {
        onCreateSub.unsubscribe();
        onDeleteSub.unsubscribe();
        onUpdateSub.unsubscribe();
      }
    }
  }, [clientId, treeState.treeSource])


 // Disable toggleEdit if we are 'loaded'

  useEffect(() => {
    let mounted = true;

    async function fetchData() {
      if (!mounted) return;
      treeDispatch({ type: 'fetching', fetching: true });
      let tv = await getCompleteTreeView(clientId);
      if (mounted) {
        treeDispatch({ type: 'fetching', fetching: false });
        treeDispatch({ type: 'setItems', items: tv });
      }
    }

    if (clientId) {
      fetchData();
    }

    return () => {
      mounted = false;
    }

  }, [isDeveloper, clientId ]);

  useEffect(() => {
    let mounted = true;

    const fetchDeveloperStatus = async () => {
      // await fetch em...
      if (!mounted) return;

      console.log('fetchDeveloperStatus');
      setCheckingDevStatus(true);
      try {
        console.log('Auth', clientId, toCheckDev);

        const thisUser = await Auth.currentAuthenticatedUser({ bypassCache: true })
        let cid;
        if (getRootDomain() === 'qr-contest.com') {
          cid = thisUser.attributes['custom:voteClient'];
        } else {
          cid = thisUser.attributes['custom:currentClient'];
        }
        if (!mounted) return;

        setClientId(cid);
        setUser(thisUser)
        const groups = thisUser.signInUserSession.accessToken.payload['cognito:groups'];
        if (groups && groups.length > 0) {
          for (let i=0; i<groups.length; i++) {
            if (groups[i].startsWith('Project_Develop_') || groups[i].startsWith('SubProject_Develop_')) {
              setIsDeveloper(true);
              // get the APIDashboardKey <cid>_<sub>
              let apik = cid + '_' + thisUser.attributes.sub;
              const ky = await getMyDashboardKey(apik);
              if (!ky && mounted) {
                // They are a dev, but have not accepted the user agreement.
                toast.error(t("toast.user_agreement"));
              }
              break;
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
      if (mounted) {
        setCheckingDevStatus(false);
        setCheckedDeveloperStatus(true);
        setDevChecked(true);
      }
    }
    if (toCheckDev && isDeveloperDomain()) {
      fetchDeveloperStatus();
    }

    return () => {
      mounted = false;
    }

  }, [toCheckDev, clientId]);


  useEffect(() => {
    if (aspect && tryUI) {
      configAspect(aspect);
      refreshDispatch({type: 'addOne'});
    }
  }, [tryUI, aspect, dimensions]);



  // To see if any changes, we can load this on start up and compare it
  // to saveProps().
  const saveStuff = async (saveAsName, saId) => {
    if (configParams && configParams.stackProps && configParams.stackProps.gridProps &&
      configParams.stackProps.gridProps.length > 0) {
      if (stackRef.current) {
        const props = stackRef.current.saveProps();
        // Update props.gridProps[x].name to match graphItems[x].name
        for (let i=0; i<graphItems.length; i++) {
          let gp = props.gridProps.find((gp) => { return gp._uid === graphItems[i]._uid });
          if (gp) {
            gp.name = graphItems[i].name;
            gp.projectId = graphItems[i].projectId;
          }
        }
        var toSave = {
          uiProps: {
            aspect: aspect,
            dashboardBackgroundColor: dashboardBackgroundColor,
            grid: {
              width: gridWidth,
              height: gridHeight,
            }
          },
          stackProps: props,
        }
        const baseProjs = toSave.stackProps.gridProps.map((gp) => {
          return { baseId: gp.baseId, projectId: gp.projectId };
        });

        const dashboardName = saveAsName || dashboardTitle;
        // The logic below is if we have an Id, use it. If not and we have a saveAsName, then this is
        // being called from a SaveAs, so we need to create a new dashboard.
        const dashboardId = saId ? saId : (saveAsName ? null : dashboardOpenId);  // on saveAs, need new ID if !saId
        if (dashboardId !== null) {
            const sRes = await updateDashboardRecord(dashboardId, clientId, dashboardName, baseProjs, toSave);
            if (sRes.error) {
              throw new Error(sRes.error);
            }
        } else {
          const sRes = await createDashboardRecord(clientId, dashboardName, baseProjs, toSave);
          if (sRes.success) {
            setDashboardOpenId(sRes.data);
            setDashboardTitle(dashboardName);
          }
        }
      }
    }

    //console.log(JSON.stringify(toSave));
  }

  // this function is only enabled when the graph type is set to pie, donut, sunburst, or treemap or
  // when pivot is enabled on the other graph types.
  const useImagesAsFill = () => {
    // apply the images to the color table of the current graph
    // we need to get the current graph, and then apply the images to the color table
    stackRef.current.useImagesAsFill();

  }
  const useAnswerColorAsFill = () => {
    stackRef.current.useAnswerColorAsFill();
  }

  const toggleLockLocation = () => {
    setLockLocation(!lockLocation);
    stackRef.current.setProperty({lockLocation: !lockLocation});
    refreshDispatch({type: 'addOne'});
  }
  const toggleLockCampaign = () => {
    setLockCampaign(!lockCampaign);
    stackRef.current.setProperty({lockCampaign: !lockCampaign});
    refreshDispatch({type: 'addOne'});
  }
  const toggleLockSorter = () => {
    setLockSorter(!lockSorter);
    stackRef.current.setProperty({lockSorter: !lockSorter});
    refreshDispatch({type: 'addOne'});
  }

  const toggleEditMode = () => {
    let test =stackRef.current.getSelectedIsEditing();
    if (!test) {
      setSelectionInEditMode(true);
    } else {
      setSelectionInEditMode(false);
    }
    stackRef.current.toggleSelectedEditMode();
  }

  const renderNoContent = () => {
    return (
      <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {t("msg.no_content")}
      </div>
    )
  }

  useEffect(() => {
    if (isDeveloperDomain()) {
      if (route === 'authenticated') {
        setToCheckDev(true);
      } else if (route === 'signIn') {
        setToCheckDev(false);
        setCheckedDeveloperStatus(false);
        setDevChecked(false);
      }
    }
  }, [route]);

  const renderCheckDeveloperStatus = (/*signOut*/) => {

    if (!checkedDeveloperStatus && !checkingDevStatus && !devChecked) {
      return (
        <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Toaster />
          {t("msg.verifying_developer_status")}
        </div>
      )
    } else if (checkingDevStatus) {
      return (
        <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Toaster />
          {t("msg.verifying_developer_status")}<Spinner />
        </div>
      )
    } else if (isDeveloper) {
      return renderUI(signOut);
    } else {
      return (
        <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {user && user.username &&
              user.username
              } - {t("msg.not_developer")}
            </div>
            <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row' }}>
              <button className="button-qr"
                onClick={() => {
                  handleUserSelect('logout');
                }}>
                {t("menu.sign_out")}
              </button>
              <button className="button-qr" style={{marginLeft: 20}}
                onClick={() => {
                  setChangeTeamButton(t("progress.processing"))
                  handleUserSelect('team');
                }}>
                {changeTeamButton}
              </button>
            </div>
            {renderDialogComponent()}
          </div>
    </div>
    )
    }
  }

  const configAspect = (aspect) => {
    const fixDims = (w, h) => {
      let monitorWidth = $('#monitor').width();
      if (isNaN(monitorWidth)) return;

      let monitorHeight = $('#monitor').height();
      let monAspect = monitorWidth / monitorHeight;
      let dAspect = w / h;
      if (dAspect > monAspect) {
        let newHeight = Math.floor(monitorWidth / dAspect);
        let newTop = Math.floor((monitorHeight - newHeight) / 2);
        //setAspectPosition({top: newTop, left: 0, bottom: newTop, right: 0});
        setAspectPosition({top: 0, left: 0, bottom: newTop*2, right: 0});
      } else {
        let newWidth = Math.floor(monitorHeight * dAspect);
        let newLeft = Math.floor((monitorWidth - newWidth) / 2);
        //setAspectPosition({top: 0, left: newLeft, bottom: 0, right: newLeft});
        setAspectPosition({top: 0, left: 0, bottom: 0, right: newLeft*2});
      }
    }
    if (aspect) {
      // fit, 5:4, 4:3, 3:2, 16:10, 16:9 , etc.
      const nColonN = /^\d+:\d+$/;
      if (nColonN.test(aspect.name)) {
        // *could* parseFloat here...
        fixDims(parseInt(aspect.name.split(':')[0]), parseInt(aspect.name.split(':')[1]));
      } else if (aspect.name === 'custom' && aspect.ratio) {
        fixDims(aspect.ratio.w, aspect.ratio.h);
      } else {
        setAspectPosition({top: 0, left: 0, bottom: 0, right: 0});
      }
    }
  }

  // we have a tree of id, name, etc. Return the projectId - which is treeState.treeSource.children[x].id for the matching
  // leaf (which can be a non-leaf) node.
  /*
  const findProjectId = (baseId) => {
    if (treeState.treeSource.children) {
      for (let p = 0; p < treeState.treeSource.children.length; p++) {
        if (treeState.treeSource.children[p].id === baseId) {
          return treeState.treeSource.children[p].id;
        }

        if (treeState.treeSource.children[p].children) {
          for (let c = 0; c < treeState.treeSource.children[p].children.length; c++) {
            if (treeState.treeSource.children[p].children[c].id === baseId) {
              return treeState.treeSource.children[p].id;
            }

            if (treeState.treeSource.children[p].children[c].children) {
              for (let l = 0; l < treeState.treeSource.children[p].children[c].children.length; l++) {
                if (treeState.treeSource.children[p].children[c].children[l].id === baseId) {
                  return treeState.treeSource.children[p].id;
                }

                if (treeState.treeSource.children[p].children[c].children[l].children) {
                  for (let q = 0; q < treeState.treeSource.children[p].children[c].children[l].children.length; q++) {

                    if (treeState.treeSource.children[p].children[c].children[l].children[q].id === baseId) {
                      return treeState.treeSource.children[p].id;
                    }

                  }
                }
              }
            }
          }
        }
      }
    }

    return null;
  }
  */

  const findProjectFromKids = (baseId, kids, projId) => {
    if (kids) {
      for (let p = 0; p < kids.length; p++) {
        if (kids[p].id === baseId) {
          return {projectId: projId, leafNode: kids[p]};
        }
        const {projectId, leafNode} = findProjectFromKids(baseId, kids[p].children, projId);
        if (projectId) {
          return {projectId: projectId, leafNode: leafNode};
        }
      }
    }
    return {projectId: null, leafNode: null};
  }

  const findProjectIdFromLeafId = (baseId) => {
    if (treeState.treeSource.children) {
      for (let p = 0; p < treeState.treeSource.children.length; p++) {
        const projId = treeState.treeSource.children[p].id;
        const {projectId, leafNode} = findProjectFromKids(baseId, treeState.treeSource.children[p].children, projId);
        if (projectId) {
          return {projectId: projectId, leafNode: leafNode};
        }
      }
    }

    return {projectId: null, leafNode: null};
  }
  const localSetSelectedItem = (indx) =>  {
    setIconPickerDisabled(indx === -1);
    setSelectedItem(indx);
  }

  const onLeafSelected = (leafId) => {
    let cp = JSON.parse(JSON.stringify(configParams));
    if (!cp.stackProps.hasOwnProperty('gridProps')) {
      cp.stackProps.gridProps = [];
    }
    const {projectId, leafNode} = findProjectIdFromLeafId(leafId); //TODO: we should just put this on the leaf when we create the tree.
    if (!projectId || !leafNode) {
      return;
    }

    // leafNode.type == 'asignment' (questionlocation), 'question' (project Question), 'location' or 'campaign'
    if (leafNode.type === 'question') {
      // start at treeState.treeSource.children[x].id matches projectId
      const myProjectNode = treeState.treeSource.children.find((proj) => { return proj.id === projectId });
      // Go down the 'CAMPAIGNS' child - which is myProjectNode.children[1] to look for leaf Nodes
      const myCampaignNode = myProjectNode.children.find((camp) => { return camp.id === 'campaigns' });
      // myCampaignNode.children are the campaigns (name, id)
      // Go down the tree and get all the assignment nodes whose id ends in leafId
      let allCampaigns= []; // {id: <>, name: <>}
      let allLocations = []; // {id: <>, name: <>}
      let myAssignmentNodes = [];
      myCampaignNode.children.forEach((camp) => {
        allCampaigns.push({ id: camp.id, name: camp.name });
        camp.children.forEach((loc) => {
          if (allLocations.findIndex((al) => { return al.id === loc.id }) === -1) {
            allLocations.push({ id: loc.id, name: loc.name });
          }
          loc.children.forEach((asgn) => {
            if (asgn.id.endsWith(leafId)) {
              allCampaigns[allCampaigns.length - 1].use = true;
              allLocations[allLocations.length - 1].use = true;
              myAssignmentNodes.push({ id: asgn.id, name: asgn.name });
            }
          });
        });
      });
      allCampaigns = allCampaigns.filter((camp) => { return camp.use });
      allLocations = allLocations.filter((loc) => { return loc.use });
      if (allCampaigns.length === 0 || allLocations.length === 0 || myAssignmentNodes.length === 0) {
        toast.error(t("error.question_not_used"));
        return;
      }   
      questionTreeData[leafId] = {campaigns: allCampaigns, locations: allLocations, assignments: myAssignmentNodes};

      setLabelItems([
      {name: 'campaign'}
      ]);
      setCategoryItems([
      {name: 'answer'}
      ]);
      setLegendItems([
      {name: 'location'}
      ]);
    
    }
    
    let graphName = t("lbl.new_graph", {number: cp.stackProps.gridProps.length + 1});
    let myUid = genCleanId();
    let oldItems = [...graphItems];
    oldItems.push( {name: graphName, _uid: myUid, baseId: leafId, projectId: projectId} );
    setGraphItems(oldItems);

    cp.stackProps.gridProps.push({
      _uid: myUid,
      name: graphName,
      manualChange: false,
      lockAspectRatio: false,
      size: {     // maybe set size to something so the QRGridLayout can figure out where to put it?
        x: .25,
        y: .25,
        width: .50,
        height: .50,
      },
      baseId: leafId,
      leafType: leafNode.type,
      projectId: projectId,
      saveProps: {
        pivot: false,
        graphType: 'bar',
        categories: ['answer'],
        legends: ['location'],
      },
    });

    //setMenuFileSaveDisabled(false);
    setMenuFileSaveAsDisabled(false);

    setConfigParams(cp);
  }

  /*
  const onLeafSelected = (leafId) => {
    let oldChecks = {...checkedLeaves};
    if (oldChecks[leafId]) {
      oldChecks[leafId] = false;
      // remove from graph grid
      let cp = JSON.parse(JSON.stringify(configParams));
      let ix = cp.stackProps.gridProps.findIndex((gp) => { return gp.baseId === leafId });
      if (ix !== -1) {
        cp.stackProps.gridProps.splice(ix, 1);
      }
      if (cp.stackProps.gridProps.length === 0) {
        setMenuFileSaveDisabled(true);
        setMenuFileSaveAsDisabled(true);
      }

      if (selectedItem === ix) {
        setToggleEditDisabled(true);
        setEnableApplyImages(false);
        $('#checkboxPivot').prop('disabled', true);
        $('#pivotLabel').css('color', 'grey');
        setIconPickerDisabled(true);
        localSetSelectedItem(-1);
      }
      setConfigParams(cp);
    } else {
      oldChecks[leafId] = true;
      // add to graph grid
      let cp = JSON.parse(JSON.stringify(configParams));
      if (!cp.stackProps.hasOwnProperty('gridProps')) {
        cp.stackProps.gridProps = [];
      }
      let projid = findProjectId(leafId); //TODO: we should just put this on the leaf when we create the tree.
      cp.stackProps.gridProps.push({
        _uid: genCleanId(),
        manualChange: false,
        lockAspectRatio: false,
        size: {     // maybe set size to something so the QRGridLayout can figure out where to put it?
          x: .33,
          y: .33,
          width: .33,
          height: .33,
        },
        baseId: leafId,
        projectId: projid,
        saveProps: {
          pivot: false,
          graphType: 'bar',
        },
      });
      //setMenuFileSaveDisabled(false);
      setMenuFileSaveAsDisabled(false);

      setConfigParams(cp);
    }
    setCheckedLeaves(oldChecks);
  }
  */

  const chooseIndex = (index) => {
    // Select the graph item.
    let ixFound = configParams.stackProps.gridProps.findIndex((gp) => { return gp._uid === graphItems[index]._uid });   
    if (ixFound !== -1) {
      if (stackRef.current) {
        stackRef.current.setSelectedItem(ixFound);
      }
      setSelectedItem(ixFound);
    }
  }
  const onItemChosen = (e) => {
    let index = +e.currentTarget.dataset.index;
    if (index !== editingItem) {
      chooseIndex(index);
      setEditingItem(null);
    }
  }
  const onDeleteItem = (e) => {
    let index = +e.currentTarget.dataset.index;
    deleteGraphDialog(graphItems[index]._uid, graphItems[index].name);
  }

  let pressTimer = null;

  const gdMouseDown = (e) => {
    let index = e.currentTarget.dataset.index;
    pressTimer = window.setTimeout(() => {
      chooseIndex(+index);
      setEditingItem(+index);
      console.log('longPress');
    }, 1000);
  }
  const gdMouseUp = (e) => {
    clearTimeout(pressTimer);
  }

  // Perhaps (if multiple dimensions, allow them to change in this interface by up/down arrows)
  // graphItems[index] === item
  const renderGraphDataItem = (item, index, ixFound) => {
    return (
      <div key={index} style={{
        paddingLeft: 12, paddingTop: 8, paddingBottom: 8,
        display: 'flex', flexDirection: 'row', alignItems: 'center',
        background: (ixFound !== -1) ? '#ecedef' : 'white',
        cursor: 'poiner',
      }}
        data-index={index}
        onClick={onItemChosen}
        onMouseDown={gdMouseDown}
        onMouseUp={gdMouseUp}
      >
        { editingItem != null && editingItem === index ?
          <input type="text" value={item.name} onChange={(e) => {
            let oldItems = [...graphItems];
            oldItems[index].name = e.target.value;
            setGraphItems(oldItems);
          }} onBlur={() => {
            setEditingItem(null);
          }
          } />
          :
          <div style={{ cursor: 'pointer' }}>
            {item.name}
          </div>
        }
        <FaTrash data-index={index} style={{ marginLeft: 'auto', marginRight: 4, color: colors.primary, cursor: 'pointer' }} onClick={onDeleteItem} />
      </div>
    )
  }

  const renderTreeAccordion = () => {
    if (treeState.treeSource) {
      // if want to use checkboxes... add checkedLeafs={checkedLeaves}
      return (
        <div style={{ backgroundColor: 'white' }}>
          {graphItems && graphItems.length > 0 && graphItems.map((item, index) => {
            let ixFound = configParams.stackProps.gridProps.findIndex((gp) => { return gp._uid === item._uid });
            if (ixFound !== selectedItem) {
              ixFound = -1;
            }
            return renderGraphDataItem(item, index, ixFound);
          })}

          <TreeViewAccordion data={treeState.treeSource}
            compactStyle={{ marginLeft: 8, backgroundColor: 'white', paddingTop: 10, paddingBottom: 10 }}
            label={t("lbl.results_to_map")}
            leafProp='leaf'
            onSelect={onLeafSelected}
            style={{
              position: 'absolute', top: 50, left: 0, width: 320, bottom: 20, zIndex: 100,
              background: '#ffffff',
              marginLeft: 16,
              boxShadow: '4px 8px #22222240',
            }}
          />

        </div>
      )
    } else if(treeState.fetching) {
      return (
        <div style={{ marginLeft: 0, backgroundColor: 'white', paddingTop: 10, paddingBottom: 10, maxWidth: toolsWidth, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Spinner />
        </div>
      )
    } else {
      return null;
    }
  }

/*
  const renderTreeView = () => {
    if (treeState.treeSource) {
      // if want to use checkboxes... add checkedLeafs={checkedLeaves}
      return (
        <div style={{ backgroundColor: 'white' }}>
          {graphItems && graphItems.length > 0 && graphItems.map((item, index) => {
            let ixFound = configParams.stackProps.gridProps.findIndex((gp) => { return gp._uid === item._uid });
            if (ixFound !== selectedItem) {
              ixFound = -1;
            }
            return renderGraphDataItem(item, index, ixFound);
          })}
          <TreeViewSearch data={treeState.treeSource}
            compactStyle={{ marginLeft: 8, backgroundColor: 'white', paddingTop: 10, paddingBottom: 10 }}
            label={t("lbl.results_to_map")}
            leafProp='leaf'
            onSelect={onLeafSelected}
            style={{
              position: 'absolute', top: 50, left: 0, width: 420, bottom: 0, zIndex: 100,
              background: '#ecedef',
              marginLeft: 16,
            }}
          />
        </div>
      )
    } else if(treeState.fetching) {
      return (
        <div style={{ marginLeft: 0, backgroundColor: 'white', paddingTop: 10, paddingBottom: 10, maxWidth: toolsWidth, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Spinner />
        </div>
      )
    } else {
      return null;
    }
  }
  */

  // This is called to pass messages from a lower child to this container.
  // For example, we read data in GraphComponent if there are imageUrls in the answers, we call this
  // and pass msg={type:"hasImages" and value: true}
  // We can use this as a generic notification to pass things 'up' the stack.
  const notificationFromChild = (msg) => {
    switch (msg.type) {
      case "hasImages":
        setHasImages(msg.value);  // controls state of Apply Image as Fill
        break;
      case "resize":      // index, size (in percentages)
        // save index, size in percentages.  index should match selectedItem
        // update the size/placement edit boxes with msg.size. On change of those text edit boxes, update configParams.stackProps.gridProps[msg.index]
        if (selectedItem === msg.index) { // better be...
          setItemXLocation(Math.round(10000 * msg.size.x) / 10000);   // .331234
          setItemYLocation(Math.round(10000 * msg.size.y) / 10000);
          setItemWidthLocation(Math.round(10000 * msg.size.width) / 10000);
          setItemHeightLocation(Math.round(10000 * msg.size.height) / 10000);
        }
        break;
      case "contextmenu":   // index, event
        if (selectedItem === msg.index) {
          deleteGraphDialog();
        }
        break;
      case "toggleEdit":
        toggleEditMode();
        break;
      case "noresponsedata":
        if (!msg.noResponseData) {    // meaning we have new data
          if (noResponseData) {
            setNoResponseData(false);   // removes UI
            toast.success(t("msg.data_received"));
          }
        }
        break;
      default:
        break;
    }
  }

  const openDashboardItem = async (id) => {
    try {
      const retv = await getDashboardRecord(id);
      if (retv) {
        if (stackRef && stackRef.current) {
          stackRef.current.resetAll();
        }
        setDashboardOpenId(id);
        setDashboardTitle(retv.name);
        //TODO: set list of items to go under Data 

        /*
        try {
          let cks = {};
          retv.baseIDs.forEach((id) => {
            cks[id] = true;
          });
          setCheckedLeaves(cks);
        } catch (err) {
          console.log(err);
        }
        */


        // Background color, aspect  props.uiProps.aspect  .dashboardBackgroundColor
        if (retv.props && retv.props.uiProps) {
          if (retv.props.uiProps.dashboardBackgroundColor) {
            setDashboardBackgroundColor(retv.props.uiProps.dashboardBackgroundColor);
          }
          if (retv.props.uiProps.aspect) {
            setAspect(retv.props.uiProps.aspect);
          }
        }
        // NOTE: the real problem with all this is that if you open up the same one you are closing
        // then some properties don't get reset because they appear to be the same.
        // So, is there a way to delay setConfigParams until we force a render of 'nothing'?
        //Fa $('#checkboxPivot').prop('disabled', true);
        setPivotDisabled(true);
        $('#pivotLabel').css('color', 'gray');
        localSetSelectedItem(-1);

        setMenuFileSaveDisabled(false);
        setMenuFileSaveAsDisabled(false);
        // It's possible that the 'old' dashboard had a selected item. So, we need to set pivot of this new one
        if (selectedItem !== -1) {
          if (retv.props.stackProps && retv.props.stackProps.gridProps && retv.props.stackProps.gridProps.length > selectedItem) {
            let piv = retv.props.stackProps.gridProps[selectedItem].saveProps.pivot; 
            setPivot(piv);
            if (piv) {
              //Fa $('#checkboxPivot').prop('disabled', false);
              setPivotDisabled(false);

              $('#pivotLabel').css('color', 'black');
            } else {
              //Fa $('#checkboxPivot').prop('disabled', true);
              setPivotDisabled(true);

              $('#pivotLabel').css('color', 'gray');
            }
            //Fa $('#checkboxPivot').prop('checked', piv);

          }
        }
        if (retv.props.stackProps && retv.props.stackProps.gridProps) {
          let gi = [];
          retv.props.stackProps.gridProps.forEach((gp) => {
            gp._uid = genCleanId();
            gi.push( {
              _uid: gp._uid,
              name: gp.name,
              baseId: gp.baseId,
              projectId: gp.projectId,              
            })
          });
          setGraphItems(gi);

        } else {
          setGraphItems([]);
        }


        setConfigParams(retv.props);          

      }
    } catch (err) {
      console.log(err);
    }
  }

  const applyThemeItem = async (id) => {
    // get the theme
    try {
      const item = await getDashboardTheme(clientId, id);
      // item is guaranteed, or we would have thrown an error
      // item.axisProps, item.baseProps, item.paletteProps
      if (selectedItem !== -1) {    // shouldn't be since this feature is disabled unless a graph is selected
        if (stackRef && stackRef.current) {
          stackRef.current.applyThemes(item);
          // applThemes on the GraphCompnent uses uiDispatch, which takes a cycle to activate
          // so, we need to take the props from item vs. stackRef.current.getProperty.
          let curProps = item.containerProps;
          for (const prop in curProps) {
            switch (prop) {
              case 'cellBackgroundColor':
                setCellBackgroundColor(curProps[prop]);
                break;
              case 'txtColor':  // need to update control, not set property... duh
                setTxtColor(curProps[prop]);
                break;
              case 'txtFontStyle':
                setTxtFontStyle(curProps[prop]);
                break;
              case 'txtFontWeight':
                setTxtFontWeight(curProps[prop]);
                break;
              case 'txtDecoration':
                setTxtDecoration(curProps[prop]);
                break;
              case 'txtLocationColor':
                setTxtLocationColor(curProps[prop]);
                break;
              case 'txtLocationFontStyle':
                setTxtLocationFontStyle(curProps[prop]);
                break;
              case 'txtLocationFontWeight':
                setTxtLocationFontWeight(curProps[prop]);
                break;
              case 'txtLocationDecoration':
                setTxtLocationDecoration(curProps[prop]);
                break;
              case 'txtCampaignColor':
                setTxtCampaignColor(curProps[prop]);
                break;
              case 'txtCampaignFontStyle':
                setTxtCampaignFontStyle(curProps[prop]);
                break;
              case 'txtCampaignFontWeight':
                setTxtCampaignFontWeight(curProps[prop]);
                break;
              case 'txtCampaignDecoration':
                setTxtCampaignDecoration(curProps[prop]);
                break;
              case 'txtSorterColor':
                setTxtSorterColor(curProps[prop]);
                break;
              case 'txtSorterFontStyle':
                setTxtSorterFontStyle(curProps[prop]);
                break;
              case 'txtSorterFontWeight':
                setTxtSorterFontWeight(curProps[prop]);
                break;
              case 'txtSorterDecoration':
                setTxtSorterDecoration(curProps[prop]);
                break;
              case 'showQuestion':
                setShowQuestion(curProps[prop]);
                break;
              case 'showLocationName':
                setShowLocationName(curProps[prop]);
                break;
              case 'showCampaignName':
                setShowCampaignName(curProps[prop]);
                break;
              case 'lockLocation':
                setLockLocation(curProps[prop]);
                break;
              case 'lockCampaign':
                setLockCampaign(curProps[prop]);
                break;
              case 'lockSorter':
                setLockSorter(curProps[prop]);
                break;
              case 'showSorter':
                  setShowSorter(curProps[prop]);
                  break;
  
              default:
                break;
            }

          }
        }
      }

    } catch (err) {
      toast.error(t("error.retrieving_theme"));
    }
  }

  const saveThemeItem = async (name, oid, containerProps, axisProps, baseProps, paletteProps, base64Img) => {

    if (oid !== null) {
      const sRes = await updateThemeRecord(clientId, name, oid, containerProps, axisProps, baseProps, paletteProps, base64Img);
      if (sRes.error) {
        throw new Error(sRes.error);
      }
    } else {
      await createThemeRecord(clientId, name, oid, containerProps, axisProps, baseProps, paletteProps, base64Img);
    }
  }

  const saveTheme = async () => {
    if (selectedItem !== -1) {
      try {
        // Take a screengrab before the dialog comes up.
        const base64Img = await capture(stackRef.current.getOuterId(), 320);

        const containerProps = stackRef.current.getContainerPropsOnly();
        const axisProps = stackRef.current.getAxisPropsOnly();  // could be {} if not axis
        const baseProps = stackRef.current.getBasePropsOnly();
        const paletteProps = stackRef.current.getPalettePropsOnly();

        toast.promise(getDashboardThemesByClient(clientId, false), {
          loading: t("progress.retrieving_themes"),
          success: (list) => {
            // Put the ones with sub === user.attributes.sub at the top of the list
            let mine = [];
            let others = [];
            list.forEach((itm) => {
              if (itm.username === user.username) {
                mine.push(itm);
              } else {
                others.push(itm);
              }
            });
            /*
            mine.sort((a, b) => (a.name.localeCompare(b.name)));
            */
  
            list = mine.concat(others);
                
              setDialogTitle(t("dlg.title_save_theme"));
              setDialogButtons([
                {
                  label: t('cancel'), value: 'cancel',
                  onClick: () => {
                    setDialogOpened(false);
                  }
                },
                {
                  label: t('save'), value: 'save',
                  onClick: async () => {
                    let saName = $('#saveAsName').val();
                    if (saName.trim().length > 0) {
                      let oid = null;

                      let fnd = list.find((itm) => itm.name === saName);
                      if (!$("#saoverwrite").prop('checked')) {
                        let msg;
                        if (fnd) {
                          if (fnd.username === user.username) {
                            msg = t("msg.theme_exists_username", { name: saName });
                          } else {
                            msg = t("msg.theme_exists_team", { name: saName });
                          }
                        toast.error(msg, { duration: 6000 });
                        return;
                        }
                      } else {
                        if (fnd) {
                          oid = fnd.id;
                        } else {
                          oid = null;
                        }
                      }
          
                      setDialogOpened(false);

                      toast.promise(
                        saveThemeItem(saName, oid, containerProps, axisProps, baseProps, paletteProps, base64Img), 
                        {loading: t("msg.saving_as", {name: saName}),
                        success: t("saved"), 
                        error: (err) => err ? err : t("error.saving")});
                    } else {
                      toast.error(t("msg.please_enter_name"));
                    } 
                  }
                }
              ]);
              let dtData = [];
              for (let i=0; i<list.length; i++) {
                dtData.push([list[i].id, list[i].name, list[i].thumbNail ? list[i].thumbNail : null, list[i].username, list[i].updatedAt, null]);
              }
              const dtHeader = [
                {title: t("dlg.theme_header_id")},
                {title: t("dlg.theme_header_name")},
                {title: t("dlg.theme_header_thumbnail")},
                {title: t("dlg.theme_header_username")},
                {title: t("dlg.theme_header_modified")},
                {title: t("delete")}];
              const hidden = [0];

              cleanupSaveAs();
              setDialogSmall(false);
              setDialogContent(
                <>
                  <div style={{ marginLeft: 8, marginRight: 8,  display: 'flex', flexDirection: 'column', 
                    alignItems: 'center', borderBottom: '1px solid ' + colors.primary, marginBottom: 20 }}>
                    <div style={{ marginLeft: 8, marginRight: 8, width: '100%', fontSize: 16, display: 'flex', flexDirection: 'row' }}>
                      <div style={{ marginRight: 10 }}>{t("name_colon")}</div>
                      <input type='text' id='saveAsName' name='saveAsName' style={{ width: '80%', marginRight: 16 }} />
                      <input type='text' id='saveAsId' name='saveAsId' value="" style={{ display: 'none' }} />
                      <button id='saveAsApply' name='saveAsApply' style={{ marginRight: 8, width: 100 }} 
                        onClick={() => {
                          $('#saveAsId').val('');
                          $('#saveAsName').val('');    
                          $('#saveAsName').attr('disabled', false);
                          $('#saoverwrite').prop('checked', false);
                          setDialogTitle("Save Theme");
                        }}>{t("btn.reset")}</button>
                    </div>
                    <div style={{ marginTop: 10, marginBottom: 10, marginRight: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <div style={{ marginRight: 8 }}>{t("checkbox_overwrite")}</div><input type='checkbox' id='saoverwrite' name='saoverwrite' style={{ marginRight: 8 }} />
                    </div>
                  </div>
                  <div style={{ flex: 'auto', overflow: 'auto' }}>
                    <OpenTable data={dtData} header={dtHeader} hidden={hidden} order={[[4, 'desc']]}
                      onRowClick={(rowD) => { // saveTheme
                        $('#saveAsId').val(rowD[0]);
                        $('#saveAsName').attr('disabled', true);
                        $('#saveAsName').val(rowD[1]);
                        $('#saoverwrite').prop('checked', true);
                        setDialogTitle(t("dlg.title_replace_theme"));
                      }} 
                      onDelete={ async (rowD) => {
                        console.log('delete ' + rowD[0]);
                        return await deleteThemeRecord(rowD[0], clientId);
                      }}
    
                      />
                  </div>
                </>
              )

              setDialogOpened(true);
            return "Done";    
          },
          error: (err) =>  err ? err.toString() : t("error.retrieving_theme")
        });

      } catch (err) {
        toast.error(t("error.retrieving_theme_properties"));
      }
    }
  }

  const applyThemes = async () => {
    if (user) {
      toast.promise(getDashboardThemesByClient(clientId, true), {
        loading: t("progress.retrieving_themes"),
        success: (list) => {
          // Put the ones with sub === user.attributes.sub at the top of the list
          let mine = [];
          let others = [];
          list.forEach((itm) => {
            if (itm.username === user.username) {
              mine.push(itm);
            } else {
              others.push(itm);
            }
          });
          mine.sort((a, b) => (a.name.localeCompare(b.name)));

          list = mine.concat(others);


          if (list && list.length > 0) {
            setDialogTitle(t("dlg.title_apply_theme"));
            setDialogButtons([{
              label: t('cancel'), value: 'cancel',
              onClick: () => {
                setDialogOpened(false);
              }
            }]);
            let dtData = [];
            for (let i=0; i<list.length; i++) {
              dtData.push([list[i].id, list[i].name, list[i].thumbNail ? list[i].thumbNail : null, list[i].username, list[i].updatedAt, null]);
            }
            const dtHeader = [
              {title: t("dlg.theme_header_id")},
              {title: t("dlg.theme_header_name")}, 
              {title: t("dlg.theme_header_thumbnail")}, 
              {title: t("dlg.theme_header_username")},
              {title: t("dlg.theme_header_modified")},
              {title: t("delete")}];
            const hidden = [0];

            setDialogSmall(false);

            // applyThemes
            setDialogContent(
              <div style={{flex: 'auto', overflow: 'auto' }}>
                <OpenTable data={dtData} header={dtHeader} hidden={hidden} order={[[4, 'desc']]}
                onRowClick={(rowD) => {
                  setDialogOpened(false); 
                  applyThemeItem(rowD[0])
                }}
                onDelete={ async (rowD) => {
                  console.log('delete ' + rowD[0]);
                  return await deleteThemeRecord(rowD[0], clientId);
                }}
                />
              </div>
            )

            setDialogOpened(true);
          }
          return t('done');    
        },
        error: (err) =>  err ? err.toString() : t("error.retrieving_theme")
      });
    }
  }

  //let dialogList = [];
  /* repalced by DataTable
	const dlgMouseEnter = (e) => {
		const me = dialogList.find((itm) => {
			return itm.id === e.currentTarget.dataset.label
		});
		if (me && !me.disabled) {
			e.currentTarget.style.backgroundColor = bgHighlightColor;
			e.currentTarget.style.color = txtHighlightColor;
			e.currentTarget.style.fontWeight = 'bold';
		}
	}
	const dlgMouseLeave = (e) => {
		const me = dialogList.find((itm) => {
			return itm.id === e.currentTarget.dataset.label
		});
		if (me && !me.disabled) {
			e.currentTarget.style.backgroundColor = bgColor;
			e.currentTarget.style.color = txtColor;
			e.currentTarget.style.fontWeight = 'normal';
		}
	}
  */

  const openDashboard = async () =>{
    if (user) {
      toast.promise(getAPIDashboardsByClient(clientId), {
        loading: t("progress.retrieving_dashboards"),
        success: (list) => {
          // Put the ones with sub === user.attributes.sub at the top of the list
          let mine = [];
          let others = [];
          list.forEach((itm) => {
            if (itm.username === user.username) {
              mine.push(itm);
            } else {
              others.push(itm);
            }
          });
      
          /* was thinking to put 'mine' at top, but now just sorting by updatedAt
          mine.sort(function (a, b) {
            return (a.name.localeCompare(b.name));
          });
          */

          list = mine.concat(others);

          if (list && list.length > 0) {
            setDialogTitle(t("dlg.title_open_dashboard"));
            setDialogButtons([{
              label: t('cancel'), value: 'cancel',
              onClick: () => {
                setDialogOpened(false);
              }
            }]);

            let dtData = [];
            let apik = getApiKey();
            for (let i=0; i<list.length; i++) {
              let url = apik ? generateDashboardUrl(list[i].id, apik) : '';
              dtData.push([list[i].id, list[i].name, list[i].username, list[i].updatedAt, url, null]);
            }
            const dtHeader = [
              {title: t("dlg.theme_header_id")}, 
              {title: t("dlg.theme_header_name")}, 
              {title: t("dlg.theme_header_username")}, 
              {title: t("dlg.theme_header_modified")}, 
              {title: t("dlg.theme_header_liveurl")},
              {title: t("delete")}];
            const hidden = [0];

            setDialogSmall(false);

            // openDashboard
            setDialogContent(
              <div style={{ flex: 'auto', overflow: 'auto' }}>
                <OpenTable data={dtData} header={dtHeader} hidden={hidden} order={[[3, 'desc']]}
                  onRowClick={(rowD) => {
                    setDialogOpened(false);
                    openDashboardItem(rowD[0])
                  }}
                  onDelete={ async (rowD) => {
                    console.log('delete ' + rowD[0]);
                    return await deleteDashboardRecord(rowD[0], clientId);
                  }}
                />
              </div>
            )

            setDialogOpened(true);
            return t('done')
          } else {
            return t('msg.no_dashboards');
          }
        },
        error: t("error.retrieving_dashboards")
      });

    }
  }

  const resetToNew = () => {
    let cp = JSON.parse(JSON.stringify(configParams));
    cp.stackProps.gridProps = [];
    if (stackRef && stackRef.current) {
   //   stackRef.current.resetAll();
    }
    setConfigParams(cp);
    //setCheckedLeaves({});
    setMenuFileSaveDisabled(true);
    setMenuFileSaveAsDisabled(true);
    setDashboardOpenId(null);
    setDashboardTitle(t("untitled"));
    localSetSelectedItem(-1);
    setGraphItems([]);
  }

  const cleanupSaveAs = () => {
    if ($('#saveAsId').length) {
      $('#saveAsId').val('');
    }
    if ($('#saveAsName').length) {
      $('#saveAsName').val('');    
      $('#saveAsName').attr('disabled', false);
    }
    if ($('#saoverwrite').length) {
      $('#saoverwrite').prop('disabled', false);
      $('#saoverwrite').prop('checked', false);
    }
  }

  const saveAs = async () =>{
    if (user) {
      toast.promise(getAPIDashboardsByClient(clientId), {
        loading: t("progress.retrieving_dashboards"),
        success: (list) => {
          // Put the ones with sub === user.attributes.sub at the top of the list
          let mine = [];
          let others = [];
          list.forEach((itm) => {
            if (itm.username === user.username) {
              mine.push(itm);
            } else {
              others.push(itm);
            }
          });
          mine.sort((a, b) => (a.name.localeCompare(b.name)));

          list = mine.concat(others);

            setDialogTitle(t("dlg.title_save_dashboard"));
            setDialogButtons([
              {
              label: t('cancel'), value: 'cancel',
              onClick: () => {
                setDialogOpened(false);
              }},
              {
                label: t('save'), value: 'save',
                onClick: async () => {
                  let saName = $('#saveAsName').val();
                  if (saName.trim().length > 0) {
                    let oid = null;

                    let fnd = list.find((itm) => itm.name === saName);
                    if (!$("#saoverwrite").prop('checked')) {
                      let msg;
                      if (fnd) {
                        if (fnd.username === user.username) {
                          msg = t("msg.dashboard_exists_username", {name: saName});
                        } else {
                          msg = t("msg.dashboard_exists_team", {name: saName})
                        }

                        toast.error(msg, { duration: 6000 });

                        return;
                      }
                    } else {
                      if (fnd) {
                        oid = fnd.id;
                      } else {
                        oid = null;
                      }
                    }
        
                    setDialogOpened(false);
                    toast.promise(
                      saveStuff(saName, oid), 
                      {loading:  t("msg.saving_as", {name: saName}), 
                      success: (msg) => {
                        setDashboardTitle(saName);
                        setMenuFileSaveDisabled(false);
                        return t("saved")
                      }, 
                      error: (err) => err ? err : t("error.saving")});
                  } else {
                    toast.error(t("msg.please_enter_name"));
                  } 
                }
              }
                      ]);
            let dtData = [];
            let apik = getApiKey();
            for (let i=0; i<list.length; i++) {
              let url = apik ? generateDashboardUrl(list[i].id, apik) : '';
              dtData.push([list[i].id, list[i].name, list[i].username, list[i].updatedAt, url, null]);
            }
            const dtHeader = [
              {title: t("dlg.theme_header_id")},
              {title: t("dlg.theme_header_name")}, 
              {title: t("dlg.theme_header_username")},
              {title: t("dlg.theme_header_modified")}, 
              {title: t("dlg.theme_header_liveurl")},
              {title: t("delete")}];
            const hidden = [0];
            cleanupSaveAs();

            setDialogSmall(false);

          setDialogContent(
            <>
              <div style={{
                marginLeft: 8, marginRight: 8, display: 'flex', flexDirection: 'column',
                alignItems: 'center', borderBottom: '1px solid ' + colors.primary, marginBottom: 20
              }}>
                <div style={{ marginLeft: 8, marginRight: 8, width: '100%', fontSize: 16, display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: 10 }}>{t("name_colon")}</div>
                  <input type='text' id='saveAsName' name='saveAsName' style={{ width: '80%', marginRight: 16 }} defaultValue={''} />
                  <input type='text' id='saveAsId' name='saveAsId' defaultValue="" style={{ display: 'none' }} />
                  <button id='saveAsApply' name='saveAsApply' style={{ marginRight: 8, width: 100 }}
                    onClick={() => {
                      $('#saveAsId').val('');
                      $('#saveAsName').val('');
                      $('#saveAsName').attr('disabled', false);
                      $('#saoverwrite').prop('disabled', false);
                      $('#saoverwrite').prop('checked', false);
                      setDialogTitle(t("dlg.title_save_dashboard"));
                    }}>Reset</button>
                </div>
                <div style={{ marginTop: 10, marginBottom: 10, marginRight: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ marginRight: 8 }}>{t('checkbox_overwrite')}</div><input type='checkbox' id='saoverwrite' name='saoverwrite' style={{ marginRight: 8 }} />
                </div>
              </div>
              <div style={{ flex: 'auto', overflow: 'auto' }}>
                <OpenTable data={dtData} header={dtHeader} hidden={hidden} order={[[3, 'desc']]}
                  onRowClick={(rowD) => {
                    $('#saveAsId').val(rowD[0]);
                    $('#saveAsName').attr('disabled', true);
                    $('#saveAsName').val(rowD[1]);
                    $('#saoverwrite').prop('disabled', true);
                    $('#saoverwrite').prop('checked', true);
                    setDialogTitle(t("dlg.title_replace_dashboard"));
                  }} 
                  onDelete={ async (rowD) => {
                    console.log('delete ' + rowD[0]);
                    return await deleteDashboardRecord(rowD[0], clientId);
                  }}
                  />
              </div>
              </>
          )

            setDialogOpened(true);
          return t('done');    
        },
        error: t("error.retrieving_dashboards")
      });

    }
  }
  /*
  let overwriteId = null;
  const saveAsOld = () => {
    if (configParams.stackProps && configParams.stackProps.gridProps && configParams.stackProps.gridProps.length > 0) {
      // prompt to save first
      setDialogTitle("Save Dashboard");
      let buttons = [{
        label: 'Save', value: 'save',
        onClick: async () => {
          let saName = $('#saveAsName').val();
          if (saName.trim().length > 0) {
            let oid = null;
            if (!$("#saoverwrite").prop('checked')) {
              overwriteId = null;
              try {
                const res = await API.get('apiDashboard', `/v1/dashboardname/exists/${clientId}?name=${encodeURIComponent(saName)}`);
                if (res.success) {
                  if (res.data.length > 0) {
                    // list of all names matching ours, but also has username.  So, we can see if we have are 'owner' or not.
                    const mine = res.data.filter((itm) => {
                      return itm.username === user.username;
                    });
                    let msg;
                    if (mine.length > 0) {
                      overwriteId = mine[0].id;
                      msg = `Dashboard name '${saName}' already exists (with your username).  To overwrite select the Overwrite checkbox?`;
                    } else {
                      msg = `Dashboard name '${saName}' already exists within your team, but not your username.  To save your version, select the Overwrite checkbox (it won't overwrite the other user's)?`;
                    }
                    toast.error(msg, {duration: 6000});
                    return;
                  }
                }

              } catch (err) {
                console.log(err);
              }
            } else {
              oid = overwriteId;
              overwriteId = null;
            }

            setDialogOpened(false);
            toast.promise(saveStuff(saName, oid), {loading: `Saving As ${saName}`, success: "Saved", error: "Error Saving"});
          } else {
            toast.error("Please enter a name");
          }
        }
      }, { 
        label: 'Cancel', value: 'cancel',
        onClick: () => {
          setDialogOpened(false);
        }
       }];

      setDialogButtons(buttons);

      setDialogContent(
        <div>
          <div style={{ marginLeft: 8, marginRight: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ marginLeft: 8, marginRight: 8, width: '100%', fontSize: 16, display: 'flex', flexDirection: 'row' }}>
              <div style={{marginRight: 10}}>Name:</div><input type='text' id='saveAsName' name='saveAsName' 
              style={{width: '100%', marginRight: 16}}/>
            </div>
            <div style={{ marginTop: 10, marginRight: 'auto', marginLeft: 8, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={{marginRight: 8}}>Overwrite</div><input type='checkbox' id='saoverwrite' name='saoverwrite' style={{marginRight: 8}}/>
              </div>
          </div>
        </div>
      )
      setDialogOpened(true);
    } else {
      toast("Nothing to save");
    }
  }
  */

  const checkIfSave = (procContinue, action) => {
    if (configParams.stackProps && configParams.stackProps.gridProps && configParams.stackProps.gridProps.length > 0) {
      // prompt to save first
      setDialogTitle(t("dlg.title_save_dashboard_question"));
      let buttons = [
        {
          label: t('cancel'), value: 'cancel',
          onClick: () => {
            setDialogOpened(false);
          }
        },
        {
          label: t('save'), value: 'save',
          onClick: () => {
            setDialogOpened(false);
            toast.promise(saveStuff(), { loading: t("saving"), success: t("saved"), error: t("error.saving") });
            resetToNew();
          }
        }
      ];

       if (action === 'creating') {
        buttons.splice(2, 0, {
          label: t('lbl.new'), value: 'New',
          onClick: () => {
            setDialogOpened(false);
            procContinue();
          }
        });
       } else if (action === 'opening') {
          buttons.splice(2, 0, {
            label: t('lbl.open'), value: 'open',
            onClick: () => {
              setDialogOpened(false);
              procContinue();
            }
          });
       } else if (action === 'logout') {
        buttons.splice(2, 0, {
          label: t('lbl.logout'), value: 'logout',
          onClick: () => {
            setDialogOpened(false);
            procContinue();
          }
        });
       }

      setDialogButtons(buttons);
      setDialogSmall(true);

      setDialogContent(
        <div style={{ flex: 'auto', overflow: 'auto' }}>
          <div style={{ marginLeft: 8, marginRight: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ marginLeft: 8, marginRight: 8, fontSize: 16 }}>
              {action === "logout" ?
                t("msg.save_before_logout", { title: dashboardTitle })
                :
                t("msg.save_dashboard", { title: dashboardTitle, action: action })
              }
            </div>
          </div>
        </div>
      )
      setDialogOpened(true);

    } else {
      if (action === 'opening' || action === 'logout') {
        procContinue();
      } else {
        resetToNew();
      }
    }

  }

  const menuFileSelected = (sel) => {
    switch (sel) {
      case "new":
        checkIfSave( resetToNew, 'creating' );
        break;
      case "open":
        checkIfSave( openDashboard, 'opening' );
        break;
      case "save":
        if (!menuFileSaveDisabled) {
          toast.promise(saveStuff(), {loading: t("saving"), success: t("saved"), error: t("error.saving")});
        }
        break;
      case "saveas":
        // Need dialog to have user enter name. 
        if (!menuFileSaveAsDisabled) {
          saveAs();
        }
        break;
      default:
        break;
    }
  }
  const updateOurTeam = async (teamId) => {
    try {
      const res = await API.put('apiDashboard', `/v1/client/select/${teamId}`);

		  if (res.success) {
        user.attributes["custom:currentClient"] = teamId;
      } else {
        toast.error(res.error);
      }
    } catch (err) {
      console.log(err);
    }
  }
  const switchTeam = async (rowD) => {
    // set clientId, refresh..
    await updateOurTeam(rowD[0]);
    user.attributes["custom:currentClient"] = rowD[0];  // qr-answers only, don't abstract
    setClientId(rowD[0]);
    toast.success(t("msg.switched_to_team", {name: rowD[1]}));

    // refresh the page
    window.location.reload();

//    resetToNew();
  }

  const showChooseTeamDialog = (list) => {
    setDialogTitle(t("dlg.title_change_team"));
    setDialogButtons([{
      label: t('cancel'), value: 'cancel',
      onClick: () => {
        setDialogOpened(false);
      }
    }]);
    let dtData = [];
    for (let i=0; i<list.length; i++) {
      dtData.push([list[i].id, list[i].name, list[i].owner ? 'yes' : 'no']);
    }
    const dtHeader = [{title: t("dlg.team_header_id")}, {title: t("dlg.team_header_name")}, 
        {title: t("dlg.team_header_owner")}];
    const hidden = [0];
    setDialogSmall(false);

    setDialogContent(
      <div style={{ flex: 'auto', overflow: 'auto' }}>
        <OpenTable data={dtData} header={dtHeader} hidden={hidden}
          onRowClick={(rowD) => {
            setDialogOpened(false);
            switchTeam(rowD)
          }} />
      </div>
    )

    setDialogOpened(true);
  }

  const loadPossibleClients = async () => {
      try {
        var clients = null;
        if (isQRVote()) {
          clients = [user.attributes["custom:voteClient"]];
        } else if (user.attributes["custom:clients"]) {
          clients = user.attributes["custom:clients"].trim().split(' ');
        }
        if (clients) {
          // go get companyName or name from CLIENTTABLE for each client
          const clist = await getClientNamesFromList(clients);   // returns [{id: <somesub>, name: <name or companyName>}...]
          if (clist) {
            //setClientList(clist);
            showChooseTeamDialog(clist);
          }
        }
        setChangeTeamButton(t("menu.change_team"))
      } catch (err) {
        toast.error(t("error.loading_teams") + err.toString());
      }
  }

  const thisLogout = () => {
    setUser(null);
    setCheckedDeveloperStatus(false)
    setCheckingDevStatus(false)
    setDevChecked(false);
    setIsDeveloper(false);
    setToCheckDev(false);
    setTryUI(false);
    // Trying to clear opened dashboard. need to reset all params to default
    resetToNew();

    setDashboardBackgroundColor('#f3f4f9')

    signOut();
  }

  // Need to clear current dashboard
  const handleUserSelect = (sel) => {
    if (sel === 'logout' && signOut) {
      // 2024-04-29 - need to check if we need to save first?
      checkIfSave( thisLogout, 'logout' );

    } else if (sel === 'team') {
      // Change teams..
      loadPossibleClients()

    }
  }

  const renderDialogComponent = () => {
    return (
      <DialogModal
        title={dialogTitle}
        buttons={dialogButtons}
        isOpened={dialogOpened}
        onClose={() => setDialogOpened(false)}
        isSmall={dialogSmall}
      >
        {dialogContent}
      </DialogModal>
    )
  }

  const menuDisplayableClicked = (mid) => {
    if (expandedMenuId !== mid) {
      setExpandedMenuId(mid);
    } else {
      setExpandedMenuId(-1);
    }
  }

  const renderMenuItems = () => {
    return (
      <div style={{ marginLeft: 6, marginRight: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <GGCMenu menuId={0} menuList={[
          { value: 'new', label: t('menu.new'), disabled: menuFileNewDisabled },
          { value: 'open', label: t('menu.open'), disabled: menuFileOpenDisabled },
          { value: 'save', label: t('menu.save'), disabled: menuFileSaveDisabled },
          { value: 'saveas', label: t('menu.save_as'), disabled: menuFileSaveAsDisabled },
        ]}
          expanded={expandedMenuId === 0}
          onClick={menuDisplayableClicked}
          onSelect={menuFileSelected} 
          displayable={
            <div style={{ paddingLeft: 8, paddingRight: 8 }}>{t("menu.file")}</div>
          } />
          {/*
        <GGCMenu menuId={1} menuList={[
          { value: 'change', label: 'Change Source', disabled: menuDataChangeDisabled },
        ]}
          expanded={expandedMenuId === 1}
          onClick={menuDisplayableClicked}
          onSelect={menuDataSelected}
          displayable={
            <div style={{ paddingLeft: 8, paddingRight: 8 }}>Data</div>
          } />
        */}
      </div>
    )
  }

  const generateDashboardUrl = (id, key) => { 
    let url = `https://dashboards.${getRootDomain()}/?gid=${id}&dbkey=${key}`;
    return url;
  }

  const renderDashboardUrl = () => {
    let apik = getApiKey();
    if (dashboardOpenId && apik) {
      return (
        <FaRegCopy title={t("title.copy_dashboard_url")} style={{ marginLeft: 8, color: colors.primary, marginRight: 8, fontSize: 20, cursor: 'pointer' }}
          onClick={() => {
            let url = generateDashboardUrl(dashboardOpenId, apik);
            navigator.clipboard.writeText(url);
            toast.success(t("msg.dashboard_url_copied"));
          }}
        />
      )
    } else {
      return null;
    }
  }

  // fetch stuff...
  const openItem = useCallback((ix) => {
    switch (ix) {
      case ACC_DASHBOARD_SETTINGS:
        break;
      case ACC_DATA_SELECTION:
        break;
      case ACC_THEMES:
        break;
      case ACC_GRAPH_TYPE:
        break;
      case ACC_GRAPH_SETTINGS:
        break;
      case ACC_EDIT_GRAPH:
        break;
      default:
        break;
    }
  }, [accGroup]);

  const listItem = useCallback((ix) => {
    switch (ix) {
      case ACC_DASHBOARD_SETTINGS:
        return renderDashboardSettings();
      case ACC_DATA_SELECTION:
        return renderTreeAccordion();   // View();
      case ACC_THEMES:
        return renderThemes();
      case ACC_GRAPH_TYPE:
        return renderGraphType();
      case ACC_GRAPH_SETTINGS:
        return renderGraphSettings();
      case ACC_EDIT_GRAPH:
        return renderZipTest();
      default:
        return null;
    }
 
  }, [accGroup,
    gridWidth, gridHeight, aspect, dashboardBackgroundColor,
    treeState.treeSource, toolsWidth, treeState.fetching, graphItems, editingItem, configParams, selectedItem, /*checkedLeaves,*/
    menuFileSaveDisabled, menuFileSaveAsDisabled,
    toggleEditDisabled, enableApplyImages, selectionInEditMode, iconPickerDisabled, graphType,cellBackgroundColor,
    txtColor, txtFontStyle, txtFontWeight, txtDecoration,
    txtLocationColor, txtLocationFontStyle, txtLocationFontWeight, txtLocationDecoration,
    txtCampaignColor, txtCampaignFontStyle, txtCampaignFontWeight, txtCampaignDecoration,
    txtSorterColor, txtSorterFontStyle, txtSorterFontWeight, txtSorterDecoration,
    itemXLocation, itemYLocation, itemWidthLocation, itemHeightLocation,
    showQuestion, showLocationName, showCampaignName, showSorter, gridLocked,
    lockLocation, lockCampaign, lockSorter,
    copyBufferState.locationWidthHeight, copyBufferState.textStyles,
    labelItems, categoryItems, legendItems
  ]);


  const renderUI = (signOut) => { 
    if (!tryUI) {
      setTryUI(true);
    }   
    if (configParams) {   // existing saved dashboard
      var monitorStyle;
      var stackStyle;
      if (isDeveloperDomain()) {
        monitorStyle = { 
          position: 'absolute', 
          top: 80, left: 260, right: 20, bottom: 20, 
          //borderColor: '#F000B4', borderWidth: 2, borderStyle: 'solid'
          backgroundColor: colors.canvasBackground
        };
          stackStyle = {
            borderColor: colors.primary, borderWidth: 2, borderStyle: 'solid',
            position: 'absolute', ...aspectPosition
          };
        } else {
        monitorStyle = { width: '100%', height: '100%'};
        stackStyle = { width: '100%', height: '100%'};
      }

      return (
        <div id="surface" style={{width: '100%', backgroundColor: '#EEEEEE', minWidth:375, height: '100%', overflowY:'scroll'}}>
          <Toaster />
          {isDeveloperDomain() && isDeveloper &&
            <div style={{zIndex: 300}}>
              <div style={{
                backgroundColor: '#F5F5F5',
                borderBottom: '1px solid rgb(220,220,220)',
                display: 'flex', flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                height: 45,
                padding: 4,
              }}>
                {renderMenuItems()}
                <div style={{ fontSize: '1.2rem', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginLeft: 'auto', marginRight: 'auto' }}>
                  {dashboardTitle} {renderDashboardUrl()}
                </div>
                <div style={{ marginLeft: 'auto', marginRight: 6, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <UserMenu username={user ? user.username : ''} menuList={
                    [
                      {value: null, label: (user ? user.username : '')},
                      { value: "team", label: t("menu.change_team") },
                      { value: "logout", label: t("menu.sign_out") }
                    ]
                  } onSelect={handleUserSelect}
                  />
                  <a style={{ marginLeft: 6 }} href="https://qr-answers.com" target="_blank" rel="noreferrer">
                    <img alt={"logo"} src={qrLogo} width={38} height={38} />
                  </a>
                </div>
              </div>
            </div>
          }
          {isDeveloperDomain() && isDeveloper &&
            renderDialogComponent()
          }
          {isDeveloperDomain() && isDeveloper &&
            <div style={{ width: toolsWidth }}>
              <AccordionGroup
                titles={accGroup}
                listItem={listItem}
                openItem={openItem}
                expand={expandedAccordion} />
            </div>
          }
          <div id="monitor" style={monitorStyle}>
            <div id="stackStyle" style={stackStyle}>
              <ControlledStack
                stackProps={configParams.stackProps}
                ref={stackRef}
                developer={isDeveloper}
                cascadeRefresh={refreshState.refreshCount}
                dashboardBackgroundColor={dashboardBackgroundColor}
                notifyContainer={notificationFromChild}
                events={
                  {
                    'item.selected': (props, gcRef, ix) => {
                      if (props && props.baseId) {  // active selection could also say ix!==-1 (same thing)
                        let gt = gcRef.getProperty('graphType');
                        setGraphType(gt.graphType);

                        // Reset uncontrolled properties..
                        setToggleEditDisabled(false);

                        let curProps = gcRef.getProperty([
                          'txtColor', 'txtFontWeight', 'txtFontStyle', 'txtDecoration', 
                          'txtLocationColor', 'txtLocationFontWeight', 'txtLocationFontStyle', 'txtLocationDecoration', 
                          'txtCampaignColor', 'txtCampaignFontWeight', 'txtCampaignFontStyle', 'txtCampaignDecoration', 
                          'txtSorterColor', 'txtSorterFontWeight', 'txtSorterFontStyle', 'txtSorterDecoration', 
                          'showSorter', 'lockLocation', 'lockCampaign', 'lockSorter',
                          'noResponseData', 'showLocationName', 'showCampaignName',
                          'cellBackgroundColor', 'pivot', 'categories', 'legends']);
                        let usedFields = [];
                        for (const prop in curProps) {
                          switch (prop) {
                            case 'pivot':
                              {
                                let piv = curProps[prop];
                                //Fa $('#checkboxPivot').prop('disabled', false);
                                setPivotDisabled(false);

                                $('#pivotLabel').css('color', 'black');
                                if (isHierarchicalChart(gt.graphType)) {  // overly cautious here. *should* be false regardless
                                  //Fa $('#checkboxPivot').prop('disabled', true);
                                  setPivotDisabled(true);

                                  $('#pivotLabel').css('color', 'gray');
                                  setPivot(false);
                                } else {
                                  setPivot(piv);
                                  //Fa $('#checkboxPivot').prop('disabled', false);
                                  setPivotDisabled(false);

                                  $('#pivotLabel').css('color', 'black');
                                  //Fa $('#checkboxPivot').prop('checked', piv);
                                }        
                              }
                              break;
                            case 'txtColor':
                              setTxtColor(curProps[prop]);
                              break;
                            case 'txtFontStyle':
                              setTxtFontStyle(curProps[prop]);
                              break;
                            case 'txtFontWeight':
                              setTxtFontWeight(curProps[prop]);
                              break;
                            case 'txtDecoration':
                              setTxtDecoration(curProps[prop]);
                              break;
                            case 'txtLocationColor':
                              setTxtLocationColor(curProps[prop]);
                              break;
                            case 'txtLocationFontStyle':
                              setTxtLocationFontStyle(curProps[prop]);
                              break;
                            case 'txtLocationFontWeight':
                              setTxtLocationFontWeight(curProps[prop]);
                              break;
                            case 'txtLocationDecoration':
                              setTxtLocationDecoration(curProps[prop]);
                              break;
                            case 'txtCampaignColor':
                              setTxtCampaignColor(curProps[prop]);
                              break;
                            case 'txtCampaignFontStyle':
                              setTxtCampaignFontStyle(curProps[prop]);
                              break;
                            case 'txtCampaignFontWeight':
                              setTxtCampaignFontWeight(curProps[prop]);
                              break;
                            case 'txtCampaignDecoration':
                              setTxtCampaignDecoration(curProps[prop]);
                              break;
                            case 'txtSorterColor':
                              setTxtSorterColor(curProps[prop]);
                              break;
                            case 'txtSorterFontStyle':
                              setTxtSorterFontStyle(curProps[prop]);
                              break;
                            case 'txtSorterFontWeight':
                              setTxtSorterFontWeight(curProps[prop]);
                              break;
                            case 'txtSorterDecoration':
                              setTxtSorterDecoration(curProps[prop]);
                              break;
                            case 'showSorter':
                              setShowSorter(curProps[prop]);
                              break;
                            case 'showLocationName':
                              setShowLocationName(curProps[prop]);
                              break;
                            case 'showCampaignName':
                              setShowCampaignName(curProps[prop]);
                              break;
                            case 'lockLocation':
                              setLockLocation(curProps[prop]);
                              break;
                            case 'lockCampaign':
                              setLockCampaign(curProps[prop]);
                              break;
                            case 'lockSorter':
                              setLockSorter(curProps[prop]);
                              break;
                            case 'cellBackgroundColor':
                              setCellBackgroundColor(curProps[prop]);
                              break;
                            case 'categories':
                              usedFields.push(...curProps[prop]);
                              setCategoryItems(curProps[prop].map((itm) => {return ({name: itm})}));
                              break;
                            case 'legends':
                              if (isHierarchicalChart(gt.graphType)) {
                                setLegendItems([]);
                              } else {
                                usedFields.push(...curProps[prop]);
                                setLegendItems(curProps[prop].map((itm) => { return ({ name: itm }) }));
                              }
                              break;
                            case 'noResponseData':
                              setNoResponseData(curProps[prop]);
                              break;
                            default:
                              break;
                          }

                        }
                        // Need to setFieldItems to whatever isn't in categories and legends
                        // campaign, location, answer are choices
                        let allFields = ['campaign', 'location', 'answer'];
                        let unusedFields = allFields.filter((itm) => {
                          return usedFields.indexOf(itm) === -1;
                        });
                        setLabelItems(unusedFields.map((itm) => {return ({name: itm})}));
                      } else {    // nothing selected
                        setToggleEditDisabled(true);
                        //Fa $('#checkboxPivot').prop('disabled', true);
                        setPivotDisabled(true);

                        $('#pivotLabel').css('color', 'grey');
                        setPivot(false);
                      }
                      localSetSelectedItem(ix);
                      // Open Graph Settings
                      if (ix === -1 && expandedAccordion === ACC_GRAPH_SETTINGS) {
                        setExpandedAccordion(ACC_DASHBOARD_SETTINGS);
                      } else {
                        //setExpandedAccordion(ACC_GRAPH_SETTINGS);
                      }
                    },
                  }
                }
              />
            </div>
          </div>
          <div id="fontSizer">
	        </div>
        </div>
      );
    } else if (loading) {
      return (
        <div style={{height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Toaster />
          <Spinner />
        </div>
      )
    } else {
      return renderNoContent();
    }
  
  }

  const cellBackgroundChanged = useCallback((color) => {
    setCellBackgroundColor(color);
    cellBgPicked(color);
  }, [selectedItem]);

  const pivotChange = () => {
    let oldPivot = pivot;
    setPivot(!pivot);

    let cp = JSON.parse(JSON.stringify(configParams));
    cp.stackProps.gridProps[selectedItem].saveProps.pivot = !oldPivot;
    setConfigParams(cp);
  }

  const lockGridChange = () => {
    setGridLocked(!gridLocked);
  }
  useEffect(() => {
    if (stackRef && stackRef.current) {
      stackRef.current.setGridSize(gridWidth, gridHeight);
    }
  }, [gridWidth, gridHeight]);
    
  const gridWidthChange = (width) => {
    if (gridLocked) {
      setGridWidth(width);
      setGridHeight(width);
      $('#gridHeight').val(width);
    } else {
      setGridWidth(width);
    }
  }
  const gridHeightChange = (height) => {
    setGridHeight(height);
  }

  const renderGridSnap = () => {
    return (
      <div style={{marginLeft: 16}}>
      <div>{t("lbl.grid_snap")}</div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 4, alignContent: 'center' }}>
          <InputNumber style={{ width: 60 }} id="gridWidth" min={0} max={25} value={gridWidth} step={1} tabIndex={34} onChange={gridWidthChange} />
          {gridLocked ?
            <InputNumber style={{ width: 60 }} id="gridHeight" min={0} max={25} value={gridHeight} step={1} tabIndex={34} onChange={gridHeightChange} disabled />
            :
            <InputNumber style={{ width: 60 }} id="gridHeight" min={0} max={25} value={gridHeight} step={1} tabIndex={34} onChange={gridHeightChange} />
          }
          <LinkUnlink style={{ marginLeft: 8 }} label={t("link")} checked={gridLocked} onClick={lockGridChange} />
        </div>
    </div >
    )
  }
  const handleAspectChange = (e) => {
    let asp = e.target.value;
    if (asp === 'custom') {
      // set custom sizes - where are they?
      setAspect({name: asp, ratio: {w: aspect.ratio.w, h:aspect.ratio.h}});
    } else if (asp === 'fit') {
      setAspect({name: asp, ratio: {w: 1, h:1}});
    } else {
      setAspect({name: asp, ratio: 
        {w: parseInt(asp.split(':')[0]), h: parseInt(asp.split(':')[1])}});
    }
  }

  const aspectWidthChange = (width) => {
    if (width !== aspect.ratio.w) {
      let asp = { ...aspect };
      asp.ratio.w = width;
      setAspect(asp);
    }
  }
  const aspectHeightChange = (height) => {
    if (height !== aspect.ratio.h) {
      let asp = { ...aspect };
      asp.ratio.h = height;
      setAspect(asp);
    }
  }
  const renderBackgroundColorPicker = () => {
    return (
      <div style={{ marginLeft: 16 }}>
        <div>{t("lbl.dashboard_color")}</div>
        <ColorPicker onChange={setDashboardBackgroundColor} color={dashboardBackgroundColor} />
      </div>
    )
  }
  const renderCellBackgroundColor = () => {
    return (
      <div style={{ marginLeft: 16 }}>
        <div>{t("lbl.graph_background_color")}</div>
        <ColorPicker onChange={cellBackgroundChanged} color={cellBackgroundColor} disabled={selectionInEditMode || iconPickerDisabled} />
      </div>
    )
  }

  const toggleItalic = () => {
    let it;
    if (txtFontStyle === 'normal') {
      it = 'italic';
    } else {
      it = "normal";
    }
    setTxtFontStyle(it);
    stackRef.current.setProperty({txtFontStyle: it});
}
  const toggleWeight = () => {
    let fw;
    if (txtFontWeight === 'normal') {
      fw = 'bold';
    } else {
      fw = 'normal';
    }
    setTxtFontWeight(fw);
    stackRef.current.setProperty({txtFontWeight: fw});
  }
  const toggleDecoration = () => {
    let td;
    if (txtDecoration === 'none') {
      td = 'underline';
    } else {
      td = 'none';
    }
    setTxtDecoration(td);
    stackRef.current.setProperty({txtDecoration: td});
  }
  const updateTxtColor = (color) => {
    setTxtColor(color);
    stackRef.current.setProperty({txtColor: color});
  }
  // Location
  const toggleLocationItalic = () => {
    let it;
    if (txtLocationFontStyle === 'normal') {
      it = 'italic';
    } else {
      it = "normal";
    }
    setTxtLocationFontStyle(it);
    stackRef.current.setProperty({txtLocationFontStyle: it});
}
  const toggleLocationWeight = () => {
    let fw;
    if (txtLocationFontWeight === 'normal') {
      fw = 'bold';
    } else {
      fw = 'normal';
    }
    setTxtLocationFontWeight(fw);
    stackRef.current.setProperty({txtLocationFontWeight: fw});
  }
  const toggleLocationDecoration = () => {
    let td;
    if (txtLocationDecoration === 'none') {
      td = 'underline';
    } else {
      td = 'none';
    }
    setTxtLocationDecoration(td);
    stackRef.current.setProperty({txtLocationDecoration: td});
  }
  const updateTxtLocationColor = (color) => {
    setTxtLocationColor(color);
    stackRef.current.setProperty({txtLocationColor: color});
  }

  // Campaign
  const toggleCampaignItalic = () => {
    let it;
    if (txtCampaignFontStyle === 'normal') {
      it = 'italic';
    } else {
      it = "normal";
    }
    setTxtCampaignFontStyle(it);
    stackRef.current.setProperty({txtCampaignFontStyle: it});
}
  const toggleCampaignWeight = () => {
    let fw;
    if (txtCampaignFontWeight === 'normal') {
      fw = 'bold';
    } else {
      fw = 'normal';
    }
    setTxtCampaignFontWeight(fw);
    stackRef.current.setProperty({txtCampaignFontWeight: fw});
  }
  const toggleCampaignDecoration = () => {
    let td;
    if (txtCampaignDecoration === 'none') {
      td = 'underline';
    } else {
      td = 'none';
    }
    setTxtCampaignDecoration(td);
    stackRef.current.setProperty({txtCampaignDecoration: td});
  }
  const updateTxtCampaignColor = (color) => {
    setTxtCampaignColor(color);
    stackRef.current.setProperty({txtCampaignColor: color});
  }

  // Sorter
  const toggleSorterItalic = () => {
    let it;
    if (txtSorterFontStyle === 'normal') {
      it = 'italic';
    } else {
      it = "normal";
    }
    setTxtSorterFontStyle(it);
    stackRef.current.setProperty({txtSorterFontStyle: it});
}
  const toggleSorterWeight = () => {
    let fw;
    if (txtSorterFontWeight === 'normal') {
      fw = 'bold';
    } else {
      fw = 'normal';
    }
    setTxtSorterFontWeight(fw);
    stackRef.current.setProperty({txtSorterFontWeight: fw});
  }
  const toggleSorterDecoration = () => {
    let td;
    if (txtSorterDecoration === 'none') {
      td = 'underline';
    } else {
      td = 'none';
    }
    setTxtSorterDecoration(td);
    stackRef.current.setProperty({txtSorterDecoration: td});
  }
  const updateTxtSorterColor = (color) => {
    setTxtSorterColor(color);
    stackRef.current.setProperty({txtSorterColor: color});
  }

  const toggleShowQuestion = () => {
    setShowQuestion(!showQuestion);
    stackRef.current.setProperty({showQuestion: !showQuestion});
    refreshDispatch({type: 'addOne'});
  }
  const toggleShowLocation = () => {
    setShowLocationName(!showLocationName);
    stackRef.current.setProperty({showLocationName: !showLocationName});
    refreshDispatch({type: 'addOne'});
  }
  const toggleShowCampaign = () => {
    setShowCampaignName(!showCampaignName);
    stackRef.current.setProperty({showCampaignName: !showCampaignName});
    refreshDispatch({type: 'addOne'});
  }
  const toggleShowSorter = () => {
    setShowSorter(!showSorter);
    stackRef.current.setProperty({showSorter: !showSorter});
    refreshDispatch({type: 'addOne'});
  }

  // Color, style, weight, decoration TBD: font
  const renderTextControls = () => {
    return (
      <div style={{ marginLeft: 16, display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row', marginRight: 10 }}>
        <div>{t("lbl.text_attributes")}</div>
            <FaCopy
              style={{marginTop: 6, marginLeft: 'auto', marginRight: 10}}
              color={selectedItem === -1 ? 'grey' : colors.primary}
              disabled={selectedItem === -1}
              cursor={selectedItem === -1 ? 'auto' : 'pointer'}
              onClick={() => {
                if (selectedItem !== -1) {
                  copyBufferDispatch({
                    type: 'setTextStyles', textStyles: {
                      txtColor: txtColor,
                      txtFontStyle: txtFontStyle,
                      txtFontWeight: txtFontWeight,
                      txtDecoration: txtDecoration,
                      txtLocationColor: txtLocationColor,
                      txtLocationFontStyle: txtLocationFontStyle,
                      txtLocationFontWeight: txtLocationFontWeight,
                      txtLocationDecoration: txtLocationDecoration,
                      txtCampaignColor: txtCampaignColor,
                      txtCampaignFontStyle: txtCampaignFontStyle,
                      txtCampaignFontWeight: txtCampaignFontWeight,
                      txtCampaignDecoration: txtCampaignDecoration,
                    }
                  });
                }
              }}
            />
            <FaPaste
              style={{ marginTop: 6, marginRight: 0 }}
              color={(selectedItem === -1 || !copyBufferState.textStyles) ? 'grey' : colors.primary}
              disabled={selectedItem === -1 || !copyBufferState.textStyles}
              cursor={(selectedItem === -1 || !copyBufferState.textStyles) ? 'auto' : 'pointer'}
              onClick={() => {
                if (!(selectedItem === -1 || !copyBufferState.textStyles)) {
                  const ts = copyBufferState.textStyles;
                  setTxtColor(ts.txtColor);
                  setTxtFontStyle(ts.txtFontStyle);
                  setTxtFontWeight(ts.txtFontWeight);
                  setTxtDecoration(ts.txtDecoration);
                  setTxtLocationColor(ts.txtLocationColor);
                  setTxtLocationFontStyle(ts.txtLocationFontStyle);
                  setTxtLocationFontWeight(ts.txtLocationFontWeight);
                  setTxtLocationDecoration(ts.txtLocationDecoration);
                  setTxtCampaignColor(ts.txtCampaignColor);
                  setTxtCampaignFontStyle(ts.txtCampaignFontStyle);
                  setTxtCampaignFontWeight(ts.txtCampaignFontWeight);
                  setTxtCampaignDecoration(ts.txtCampaignDecoration);

                  setShowQuestion(ts.showQuestion);
                  setShowLocationName(ts.showLocationName);
                  setShowCampaignName(ts.showCampaignName);
                  setLockLocation(ts.lockLocation);
                  setLockCampaign(ts.lockCampaign);
                  setLockSorter(ts.lockSorter);
              
                  stackRef.current.setProperty({
                    txtColor: ts.txtColor,
                    txtFontStyle: ts.txtFontStyle,
                    txtFontWeight: ts.txtFontWeight,
                    txtDecoration: ts.txtDecoration,
                    txtLocationColor: ts.txtLocationColor,
                    txtLocationFontStyle: ts.txtLocationFontStyle,
                    txtLocationFontWeight: ts.txtLocationFontWeight,
                    txtLocationDecoration: ts.txtLocationDecoration,
                    txtCampaignColor: ts.txtCampaignColor,
                    txtCampaignFontStyle: ts.txtCampaignFontStyle,
                    txtCampaignFontWeight: ts.txtCampaignFontWeight,
                    txtCampaignDecoration: ts.txtCampaignDecoration,
                    showQuestion: ts.showQuestion,
                    showLocationName: ts.showLocationName,
                    showCampaignName: ts.showCampaignName,
                    locCampHeight: ts.locCampHeight,
                    questionHeight: ts.questionHeight,
                    locationWidth: ts.locationWidth,
                    campaignWidth: ts.campaignWidth,
                    containerPadding: ts.containerPadding,
                    lockLocation: ts.lockLocation,
                    lockCampaign: ts.lockCampaign,
                    lockSorter: ts.lockSorter,
    
                  });
                }
              }}
            />
          </div>

          <ViewHideIcon style={{ marginLeft: 0 }} label={t("lbl.lbl_location")} disabled={iconPickerDisabled}
            checked={showLocationName}  onClick={toggleShowLocation} eyeTitle={t("lbl.lbl_location_hideshow")}
            locked={lockLocation} onLockClick={toggleLockLocation} lockTitle={t("lbl.lbl_location_lock")}
            />
        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: -8 }}>
          <ColorPicker outerStyle={{marginRight: 4}} onChange={updateTxtLocationColor} color={txtLocationColor} disabled={iconPickerDisabled} />
          <IconCheckbox style={{marginLeft: 3}} icon={<div style={{ alignSelf: 'center', marginLeft: '4px', width: '1em' }}><strong>B</strong></div>} checked={txtLocationFontWeight === 'bold'} disabled={iconPickerDisabled} onClick={toggleLocationWeight} />
          <IconCheckbox icon={<div style={{ alignSelf: 'center', marginLeft: '4px', width: '1em' }}><i>I</i></div>} checked={txtLocationFontStyle === 'italic'} disabled={iconPickerDisabled} onClick={toggleLocationItalic} />
          <IconCheckbox icon={<div style={{ alignSelf: 'center', marginLeft: '4px', width: '1em' }}><u>U</u></div>} checked={txtLocationDecoration === 'underline'} disabled={iconPickerDisabled} onClick={toggleLocationDecoration} />
        </div>
          <ViewHideIcon style={{ marginLeft: 0 }} label={t("lbl.lbl_campaign")} disabled={iconPickerDisabled}
          checked={showCampaignName}  onClick={toggleShowCampaign} eyeTitle={t("lbl.lbl_campaign_hideshow")}
          locked={lockCampaign} onLockClick={toggleLockCampaign} lockTitle={t("lbl.lbl_campaign_lock")}
          />
        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: -8 }}>
          <ColorPicker outerStyle={{marginRight: 4}} onChange={updateTxtCampaignColor} color={txtCampaignColor} disabled={iconPickerDisabled} />
          <IconCheckbox style={{marginLeft: 3}} icon={<div style={{ alignSelf: 'center', marginLeft: '4px', width: '1em' }}><strong>B</strong></div>} checked={txtCampaignFontWeight === 'bold'} disabled={iconPickerDisabled} onClick={toggleCampaignWeight} />
          <IconCheckbox icon={<div style={{ alignSelf: 'center', marginLeft: '4px', width: '1em' }}><i>I</i></div>} checked={txtCampaignFontStyle === 'italic'} disabled={iconPickerDisabled} onClick={toggleCampaignItalic} />
          <IconCheckbox icon={<div style={{ alignSelf: 'center', marginLeft: '4px', width: '1em' }}><u>U</u></div>} checked={txtCampaignDecoration === 'underline'} disabled={iconPickerDisabled} onClick={toggleCampaignDecoration} />
        </div>

        <ViewHideIcon style={{ marginLeft: 0 }} label={t("lbl.lbl_question")} disabled={iconPickerDisabled}
          checked={showQuestion} onClick={toggleShowQuestion} eyeTitle={t("lbl.lbl_question_hideshow")}/>
        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: -8 }}>
          <ColorPicker outerStyle={{marginRight: 4}} onChange={updateTxtColor} color={txtColor} disabled={iconPickerDisabled} />
          <IconCheckbox style={{marginLeft: 3}} icon={<div style={{ alignSelf: 'center', marginLeft: '4px', width: '1em' }}><strong>B</strong></div>} checked={txtFontWeight === 'bold'} disabled={iconPickerDisabled} onClick={toggleWeight} />
          <IconCheckbox icon={<div style={{ alignSelf: 'center', marginLeft: '4px', width: '1em' }}><i>I</i></div>} checked={txtFontStyle === 'italic'} disabled={iconPickerDisabled} onClick={toggleItalic} />
          <IconCheckbox icon={<div style={{ alignSelf: 'center', marginLeft: '4px', width: '1em' }}><u>U</u></div>} checked={txtDecoration === 'underline'} disabled={iconPickerDisabled} onClick={toggleDecoration} />
        </div>
          <ViewHideIcon style={{ marginLeft: 0 }} label={t("lbl.lbl_sorter")} disabled={iconPickerDisabled || !canSort(graphType)}
          checked={showSorter}  onClick={toggleShowSorter} eyeTitle={t("lbl.lbl_sorter_hideshow")}
          locked={lockSorter} onLockClick={toggleLockSorter} lockTitle={t("lbl.lbl_sorter_lock")}
          />
        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: -8 }}>
          <ColorPicker outerStyle={{marginRight: 4}} onChange={updateTxtSorterColor} color={txtSorterColor} disabled={iconPickerDisabled || !canSort(graphType)} />
          <IconCheckbox style={{marginLeft: 3}} icon={<div style={{ alignSelf: 'center', marginLeft: '4px', width: '1em' }}><strong>B</strong></div>} checked={txtSorterFontWeight === 'bold'} disabled={iconPickerDisabled || !canSort(graphType)} onClick={toggleSorterWeight} />
          <IconCheckbox icon={<div style={{ alignSelf: 'center', marginLeft: '4px', width: '1em' }}><i>I</i></div>} checked={txtSorterFontStyle === 'italic'} disabled={iconPickerDisabled || !canSort(graphType)} onClick={toggleSorterItalic} />
          <IconCheckbox icon={<div style={{ alignSelf: 'center', marginLeft: '4px', width: '1em' }}><u>U</u></div>} checked={txtSorterDecoration === 'underline'} disabled={iconPickerDisabled || !canSort(graphType)} onClick={toggleSorterDecoration} />
        </div>

      </div>
    )
  }
 
  const itemXChange = (x) => {
//    let x = +ele.currentTarget.value;

    if (x > 90) {
      x = 90;
    }
    const xRounded = Math.round(10000 * x/100)/ 10000;
    setItemXLocation(xRounded);
    let size = {
      x: xRounded,
      y: itemYLocation,
      width: itemWidthLocation,
      height: itemHeightLocation
    }
    stackRef.current.setItemLocation(selectedItem, size);
  }
  const itemYChange = (y) => {
//    let y = +ele.currentTarget.value;
    if (y > 90) {
      y = 90;
    }
    let yRounded = Math.round(10000 * y/100)/ 10000;
    setItemYLocation(yRounded);
    let size = {
      x: itemXLocation,
      y: yRounded,
      width: itemWidthLocation,
      height: itemHeightLocation
    }
    stackRef.current.setItemLocation(selectedItem, size);
  }
  const itemWidthChange = (width) => {
//    let width = +ele.currentTarget.value;
    if (width > 100) {
      width = 100;
    }
    let widthRounded = Math.round(10000 * width/100)/ 10000;
    setItemWidthLocation(widthRounded);
    let size = {
      x: itemXLocation,
      y: itemYLocation,
      width: widthRounded,
      height: itemHeightLocation
    }
    stackRef.current.setItemLocation(selectedItem, size);
  }
  // e.g. 33.1234 -> .3312
  const itemHeightChange = (height) => {
//    let height = +ele.currentTarget.value;
    if (height > 100) {
      height = 100;
    }
    let heightRounded = Math.round(10000 * height/100)/ 10000;
    setItemHeightLocation(heightRounded);
    let size = {
      x: itemXLocation,
      y: itemYLocation,
      width: itemWidthLocation,
      height: heightRounded
    }
    stackRef.current.setItemLocation(selectedItem, size);
  }

  const renderSizeAndPlacement = () => {
    return (
      <div style={{ marginLeft: 16, display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row', marginRight: 10 }}>
          <div>{t("lbl.size_and_placement")}</div>
          <AiOutlineExpand
              style={{marginTop: 6, marginLeft: 'auto', marginRight: 10}}
              color={selectedItem === -1 ? 'grey' : colors.primary}
              disabled={selectedItem === -1}
              cursor={selectedItem === -1 ? 'auto' : 'pointer'}
              onClick={() => {
                if (selectedItem !== -1) {
                  setItemXLocation(0);
                  setItemYLocation(0);
                  setItemWidthLocation(1.0);
                  setItemHeightLocation(1.0);

                  let size = {
                    x: 0,
                    y: 0,
                    width: 1,
                    height: 1,
                  }
                  stackRef.current.setItemLocation(selectedItem, size);
                }
              }}
            />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>{t("lbl.x")}</div>
            <InputNumber disabled={selectedItem === -1} style={{ width: 60 }} id="ix" min={0} max={90} value={itemXLocation*100} step={1} tabIndex={34} onChange={itemXChange} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16 }}>
            <div>{t("lbl.y")}</div>
            <InputNumber disabled={selectedItem === -1} style={{ width: 60 }} id="iy" min={0} max={90} value={itemYLocation*100} step={1} tabIndex={34} onChange={itemYChange} />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>{t("lbl.width")}</div>
            <InputNumber disabled={selectedItem === -1} style={{ width: 60 }} id="iwidth" min={0} max={100} value={itemWidthLocation*100} step={1} tabIndex={34} onChange={itemWidthChange} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16 }}>
            <div>{t("lbl.height")}</div>
            <InputNumber disabled={selectedItem === -1} style={{ width: 60 }} id="iheight" min={0} max={100} value={itemHeightLocation*100} step={1} tabIndex={34} onChange={itemHeightChange} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 6, marginRight: 10, marginLeft: 'auto' }}>
            <FaCopy
              style={{marginRight: 10}}
              color={selectedItem === -1 ? 'grey' : colors.primary}
              disabled={selectedItem === -1}
              cursor={selectedItem === -1 ? 'auto' : 'pointer'}
              onClick={() => {
                if (selectedItem !== -1) {
                  copyBufferDispatch({type: 'setLocationWidthHeight', value: {width: itemWidthLocation, height: itemHeightLocation}});
                }
              }}
            />
            <FaPaste
              style={{ marginRight: 0 }}
              color={(selectedItem === -1 || !copyBufferState.locationWidthHeight) ? 'grey' : colors.primary}
              disabled={selectedItem === -1 || !copyBufferState.locationWidthHeight}
              cursor={(selectedItem === -1 || !copyBufferState.locationWidthHeight) ? 'auto' : 'pointer'}
              onClick={() => {
                if (!(selectedItem === -1 || !copyBufferState.locationWidthHeight)) {
                  const wh = copyBufferState.locationWidthHeight;
                  setItemWidthLocation(wh.width);
                  setItemHeightLocation(wh.height);
                  let size = {
                    width: wh.width,
                    height: wh.height,
                  }
                  stackRef.current.setItemSize(selectedItem, size);
              
                }
              }}
            />
          </div>

        </div>
      </div>
    )
  }

  const renderAspectSelect = () => {
    return (
      <div style={{marginLeft: 16}}>
        <div style={{display: 'flex', flexDirection: 'row'}}>
      <div>{t("lbl.destination_aspect")}</div>
      </div>
      <select name='aspectSelect' id="aspectSelect" tabIndex={34} 
          style={{ }} value={aspect.name}
          onChange={handleAspectChange}>
          {_aspectRatios.map((asp, ix) => {
              return <option key={ix} value={asp}>{asp}</option>
          })}
      </select>
        {aspect && aspect.name === 'custom' &&
          <div id="aspectCustom" style={{display: 'flex', flexDirection:'row'}}>
            <InputNumber style={{ width: 60 }} id="asCWidth" min={1} max={25} value={aspect.ratio.w} step={1} tabIndex={35} onChange={aspectWidthChange} />
            <InputNumber style={{ width: 60 }} id="asCWidth" min={1} max={25} value={aspect.ratio.h} step={1} tabIndex={36} onChange={aspectHeightChange} />
          </div>
        }
      </div>
    )
  }
  const graphIcons = [

    {label: t("lbl.chart.bar_chart"), value: "bar", image: iconBar},
    {label: t("lbl.chart.stacked_bar_chart"), value: "sbar", image: iconSBar},
    {label: t("lbl.chart.horizontal_bar_chart"), value: "hbar", image: iconHBar},
    {label: t("lbl.chart.horizontal_stacked_bar_chart"), value: "shbar", image: iconSHBar},
    {label: "", value: "empty", image: null},
    {label: t("lbl.chart.line_chart"), value: "line", image: iconLine},
    {label: t("lbl.chart.spline_chart"), value: "spline", image: iconSpline},
    {label: t("lbl.chart.area_spline_chart"), value: "aspline", image: iconASpline},
    {label: t("lbl.chart.treemap_chart"), value: "treemap", image: iconTreemap},
    {label: t("lbl.chart.normal_pivot"), value: "pivot", image: iconPivot},

    {label: t("lbl.chart.pie_chart"), value: "pie", image: iconPie},
    {label: t("lbl.chart.donut_chart"), value: "donut", image: iconDonut},
    {label: t("lbl.chart.sunburst_chart"), value: "sunburst", image: iconSunburst},
    {label: t("lbl.chart.circle_pack_chart"), value: "circlepack", image: iconCpack},
  ]

  const doAutoLayout = (e) => {
    if (configParams.stackProps.gridProps.length === 1) {
      // Maximize to window
      setItemXLocation(0);
      setItemYLocation(0);
      setItemWidthLocation(1.0);
      setItemHeightLocation(1.0);

      let size = {
        x: 0,
        y: 0,
        width: 1,
        height: 1,
      }
      stackRef.current.setItemLocation(0, size);
    } else {
      // Use d3.treemap layout. May have to deselect item if selected.
      stackRef.current.autoLayout();
      
      //stackRef.current.setSelectedItem(-1);
      //localSetSelectedItem(-1);

    }
  }

  const renderAutoLayout = () => {
    // configParams.stackProps.gridProps.length
    if (stackRef && stackRef.current && configParams && configParams.stackProps && configParams.stackProps.gridProps) {
      return (
        <>
          <ToolSeparator />
          <div style={{ marginLeft: 16, marginRight: 16 }}>
            <button className="button-qr" style={{ width: '100%' }} onClick={doAutoLayout} disabled={false}>{t("btn.auto_layout")}</button>
          </div>
        </>
      )
    }

    return null;
  } 

  const renderDashboardSettings = () => {
    return (
      <div style={{ paddingTop: 20, backgroundColor: 'white', display: 'flex', paddingBottom: 20, flexDirection: 'column' }}>
        {renderGridSnap()}
        <ToolSeparator />
        {renderAspectSelect()}
        <ToolSeparator />
        {renderBackgroundColorPicker()}
        {renderAutoLayout()}

      </div>
    )
  }



  const getZipper = async () => {
    // Replace URL with results of /res/tdump like activity
    //let url = "https://qranswerspdfs132025-devp.s3.amazonaws.com/public/tdump.zip";
    let url;
    const resDump = await API.get('apiPDF', `/res/tdump?dom=${getRootDomain()}`);
    if (resDump && resDump.success) {
      const resApi = await API.get('apiPDF', `/res/dbdump?dom=${getRootDomain()}`)
      url = resApi.url;
    } else if (resDump.error === 'canDownloadRawData = false') {
      toast.error(t("toast.error_candownloadrawdata"));
      return;
    } else {
      toast.error(t("toast.error_tdump"));
      return;
    }

    const res = await getRemoteZip(url);
    if (res.success) {
      setDatabaseFiles(res.files);
      const gData = getGanttData();
      // replicate gData.data a few times...
      let newData = [];
      let maxMo = 0;
      for (let i = 0; i < 4; i++) {
        let gData2 = JSON.parse(JSON.stringify(gData.data));
        let moAdd = Math.floor(Math.random() * 4);
        if (moAdd > maxMo) {
          maxMo = moAdd;
        }
        for (let i = 0; i < gData2.length; i++) {
          gData2[i].start = new Date(gData2[i].start);
          gData2[i].end = new Date(gData2[i].end);
          gData2[i].start = new Date(gData2[i].start.setMonth(gData2[i].start.getMonth() + moAdd));
          gData2[i].end = new Date(gData2[i].end.setMonth(gData2[i].end.getMonth() + moAdd));
        }
        newData.push(gData2);
      }

      gData.duration.end = new Date(gData.duration.end.setMonth(gData.duration.end.getMonth() + maxMo));
      gData.duration.duration = gData.duration.end.getTime() - gData.duration.start.getTime();

      for (let i=0; i<newData.length; i++) {
        gData.data = gData.data.concat(newData[i]);
      }

      let myId = 'G'+_genId();
      let elem = document.getElementById('stackStyle');
      elem.appendChild($('<div id="'+myId+'" style="position: absolute; left: 100px; top: 100px; width: 80%; height: 80%;"></div>')[0]);
      
      $('#'+myId).empty();
      let height = $('#'+myId).height();  // subtract border? do we need this?
      let width = $('#'+myId).width();      
      $('#'+myId).empty();
      let args = {
        container: '#'+myId,
        grouped: false,
        valueAxisFormat: 'd',
      };
      args.categoryClick = function (evt) {
        console.log(evt);
      }
      let sb = new ganttGGC(args);
      sb._setData(gData);

      sb.resize({ width: width, height: height });

    }

  }
  // Convert the base64 image string to blob
  /* FYI: This is the function that converts the base64 image string to a blob
  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }
  */

  const capture = async (oid, width) => {
    const monitor = document.getElementById(oid);


    try {
      const canvas = await html2canvas(monitor, { useCORS: true });
      //const frame = canvas.toDataURL("image/png");
      // base64 image URL

      const desiredWidth = width;   // really these are just thumbnails, so how big do we need?

      const newCanvas = document.createElement("canvas");
      const newCtx = newCanvas.getContext("2d");
      newCanvas.width = desiredWidth;
      newCanvas.height = desiredWidth * canvas.height / canvas.width;
      newCtx.drawImage(canvas, 0, 0, newCanvas.width, newCanvas.height);
      const newFrame = newCanvas.toDataURL("image/png");

      return newFrame;

    } catch (err) {
      console.error("Error: " + err);
      return null;
    }
  };

  const captureArea = () => {
    capture();
  }
  
  const renderZipTest = () => {
    return (
      <div style={{ marginTop: 10, display: 'flex', marginBottom: 10, flexDirection: 'column' }}>

        <div style={{ marginLeft: 16, marginTop: 10, width: 90 }}>
          <button className="button-a34" onClick={getZipper} >Ziptest</button> ignore this
        </div>
      </div>
    )
  }

  const renderThemes = () => {
    return (
      <div style={{ paddingTop: 20, backgroundColor: 'white', display: 'flex', paddingBottom: 20, flexDirection: 'column' }}>
        <div style={{ marginLeft: 16, marginRight: 16 }}>
          <button className="button-qr" style={{width: '100%'}} onClick={applyThemes} disabled={iconPickerDisabled}>{t("btn.apply_theme")}</button>
        </div>
        <div style={{ marginTop: 10, marginLeft: 16, marginRight: 16 }}>
          <button className="button-qr" style={{width: '100%'}} onClick={saveTheme} disabled={iconPickerDisabled}>{t("btn.save_theme")}</button>
        </div>
      </div>

    )
  }
  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};
// 

// offs = offset from original position - used if we are moving within a box
// clientOffsetY - offset from top of drop box.  Can use this to see if we dropped
// before or after any items already in the list.
  const handleLabelDrop = useCallback((dropTarget, item, offs, clientOffsetY) => {
    switch (dropTarget) {
      case 'fields':
          // Don't allow reorder of fields
          if (labelItems.findIndex((itm) => itm.name === item.name) === -1) {
            let flds = [...labelItems];
            flds.push({ ...item });
            setLabelItems(flds);
            const ixItm = categoryItems.findIndex((itm) => itm.name === item.name);
            if (ixItm !== -1) {
              let cats = [...categoryItems];
              cats.splice(ixItm, 1);
              setCategoryItems(cats);

              if (selectedItem !== -1) {
                /* executing this messes up the graphtype...
                let cp = JSON.parse(JSON.stringify(configParams));
                cp.stackProps.gridProps[selectedItem].saveProps.categories = cats.map((cat) => cat.name);
                setConfigParams(cp);
                */
                stackRef.current.setProperty({categories: cats.map((cat) => cat.name)});
              }
        
            } else {
              const ixItm = legendItems.findIndex((itm) => itm.name === item.name);
              if (ixItm !== -1) {
                let legs = [...legendItems];
                legs.splice(ixItm, 1);
                setLegendItems(legs);

                if (selectedItem !== -1) {
                  /*
                  let cp = JSON.parse(JSON.stringify(configParams));
                  cp.stackProps.gridProps[selectedItem].saveProps.legends = legs.map((leg) => leg.name);
                  setConfigParams(cp);
                  */
                  stackRef.current.setProperty({legends: legs.map((leg) => leg.name)});
                }
              }
            }
          }
        break;
      case 'category':
        {
          let ix = categoryItems.findIndex((itm) => itm.name === item.name);
          if (ix !== -1) {
            // Dropping on self. We know each item is 20px, so see how far we moved in y
            let nMoved = Math.round(offs.y / 20); // will be + or -
            let moveTo;
            if (ix + nMoved < 0) {
              // Move to pos 0
              moveTo = 0;
            } else {
              if (ix + nMoved > categoryItems.length - 1) {                
                // Move to end
                moveTo = categoryItems.length - 1;
              } else {
                // Move to ix + nMoved
                moveTo = ix + nMoved;
              }
            }
            if (ix !== moveTo) {
              array_move(categoryItems, ix, moveTo);
              setCategoryItems(categoryItems);
              stackRef.current.setProperty({ categories: categoryItems.map((cat) => cat.name) });
            }

          } else {
            // where are we dropping in the list (if any).  dropAreaPadding is 10px
            // dndLabelHeight is height of each label - 20px
            let dropIx = Math.round((clientOffsetY - dropAreaPadding) / dndLabelHeight);
            let cats = [...categoryItems];
            if (categoryItems.length === 0) {
              cats.push({ ...item });
            } else {
              if (dropIx < 0) {
                cats.unshift({ ...item });
              } else if (dropIx > categoryItems.length - 1) {
                cats.push({ ...item });
              } else {    // insert
                cats.splice(dropIx, 0, { ...item });
              }
            }

            setCategoryItems(cats);

            if (selectedItem !== -1) {
              /*
              let cp = JSON.parse(JSON.stringify(configParams));
              cp.stackProps.gridProps[selectedItem].saveProps.categories = cats.map((cat) => cat.name);
              setConfigParams(cp);
              */
              stackRef.current.setProperty({ categories: cats.map((cat) => cat.name) });
            }

            const ixItm = labelItems.findIndex((itm) => itm.name === item.name);
            if (ixItm !== -1) {
              let flds = [...labelItems];
              flds.splice(ixItm, 1);
              setLabelItems(flds);
            } else {
              const ixItm = legendItems.findIndex((itm) => itm.name === item.name);
              if (ixItm !== -1) {
                let legs = [...legendItems];
                legs.splice(ixItm, 1);
                setLegendItems(legs);

                if (selectedItem !== -1) {
                  /*
                  let cp = JSON.parse(JSON.stringify(configParams));
                  cp.stackProps.gridProps[selectedItem].saveProps.legends = legs.map((leg) => leg.name);
                  setConfigParams(cp);
                  */
                  stackRef.current.setProperty({ legends: legs.map((leg) => leg.name) });
                }

              }
            }
          }
        }
        break;
      case 'legend':
        {
          let ix = legendItems.findIndex((itm) => itm.name === item.name);
          if (ix !== -1) {
            // Dropping on self. We know each item is 20px, so see how far we moved in y
            let nMoved = Math.round(offs.y / 20); // will be + or -
            let moveTo;
            if (ix + nMoved < 0) {
              // Move to pos 0
              moveTo = 0;
            } else {
              if (ix + nMoved > legendItems.length - 1) {
                // Move to end
                moveTo = legendItems.length - 1;
              } else {
                // Move to ix + nMoved
                moveTo = ix + nMoved;
              }
            }
            if (ix !== moveTo) {
              array_move(legendItems, ix, moveTo);
              setLegendItems(legendItems);
              stackRef.current.setProperty({ legends: legendItems.map((leg) => leg.name) });
            }

          } else {
            let legs = [...legendItems];
            legs.push({ ...item });
            setLegendItems(legs);

            if (selectedItem !== -1) {
              /*
              let cp = JSON.parse(JSON.stringify(configParams));
              cp.stackProps.gridProps[selectedItem].saveProps.legends = legs.map((leg) => leg.name);
              setConfigParams(cp);
              */
              stackRef.current.setProperty({ legends: legs.map((leg) => leg.name) });
            }

            const ixItm = labelItems.findIndex((itm) => itm.name === item.name);
            if (ixItm !== -1) {
              let flds = [...labelItems];
              flds.splice(ixItm, 1);
              setLabelItems(flds);
            } else {
              const ixItm = categoryItems.findIndex((itm) => itm.name === item.name);
              if (ixItm !== -1) {
                let cats = [...categoryItems];
                cats.splice(ixItm, 1);
                setCategoryItems(cats);

                if (selectedItem !== -1) {
                  /*
                  let cp = JSON.parse(JSON.stringify(configParams));
                  cp.stackProps.gridProps[selectedItem].saveProps.categories = cats.map((cat) => cat.name);
                  setConfigParams(cp);
                  */
                  stackRef.current.setProperty({ categories: cats.map((cat) => cat.name) });
                }
              }
            }
          }
        }
        break;
      default:
        break;
    }  

  }, [labelItems, categoryItems, legendItems]);

  const renderGraphType = () => {
    return (
      <div style={{ paddingTop: 20, backgroundColor: 'white', display: 'flex', paddingBottom: 20, flexDirection: 'column' }}>
        <div style={{maxWidth: toolsWidth-8,marginTop: 4, marginLeft: 4, marginRight: 4}}>
          <IconPicker icons={graphIcons} disabled={selectionInEditMode || iconPickerDisabled} 
            value={graphType} columns={3} onSelect={graphChoicePicked} />
        </div>
        <ToolSeparator />
        <div style={{marginLeft: 16}}>
          <label style={{marginTop: 'auto', marginBottom: 'auto', display: 'inline-block', paddingRight: 10, whiteSpace: 'nowrap'}}>
            <LeftCheckbox style={{marginLeft: 0}} label={t("btn.pivot_data")} checked={pivot} disabled={pivotDisabled} onClick={pivotChange}/>
          </label>
        </div>
        {selectedItem !== -1 && noResponseData &&
          <div style={{
            marginLeft: 16, display: 'flex',
            flexDirection: 'row', alignItems: 'center',
          }}
            onClick={() => {
              if (stackRef) {
                stackRef.current.generateSampleData();
              }
            }}
          >
            <FaRecycle style={{
              marginRight: 4,
              color: colors.primary,
              cursor: 'pointer'
            }}
            />
            <div style={{ marginLeft: 8 }}>
              {t("btn.generate_sample_data")}
            </div>
          </div>
        }
        {selectedItem !== -1 && configParams.stackProps.gridProps[selectedItem].leafType === 'question' ?
          <>
            <ToolSeparator />
            <div style={{ marginLeft: 16, display: 'flex', flexDirection: 'column' }}>
              <div>{t("lbl.data_grouping")}</div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ fontSize: 12 }}>{t("lbl.fields")}</div>
                  <DropArea
                    border={'none'}
                    accept={[ItemTypes.LABEL]}
                    droppedItems={labelItems}
                    onDrop={(item, offs, coffy) => handleLabelDrop('fields', item, offs, coffy)}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ fontSize: 12 }}>{t("lbl.grouping")}</div>
                  <DropArea
                    accept={[ItemTypes.LABEL]}
                    droppedItems={categoryItems}
                    onDrop={(item, offs, coffy) => handleLabelDrop('category', item, offs, coffy)}
                  />
                </div>
              </div>
              {isHierarchicalChart(graphType) ?
                null
                :
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{marginLeft: 'auto', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ fontSize: 12 }}>{t("lbl.legend")}</div>
                    <DropArea
                      accept={[ItemTypes.LABEL]}
                      droppedItems={legendItems}
                      onDrop={(item, offs, coffy) => handleLabelDrop('legend', item, offs, coffy)}
                    />
                  </div>
                </div>
              }
            </div>
          </>
          : null
        }
        <ToolSeparator />        
        <div style={{marginLeft: 16, marginRight: 16}}>
          <button className="button-qr" style={{width: '100%'}} onClick={useImagesAsFill} disabled={!enableApplyImages}>{t("btn.use_images_as_fill")}</button>
        </div>
        <div style={{marginTop: 8, marginLeft: 16, marginRight: 16}}>
          <button className="button-qr" style={{width: '100%'}} onClick={useAnswerColorAsFill} disabled={!enableApplyImages}>{t("btn.use_answer_color_as_fill")}</button>
        </div>

      </div>
    )
  }

  const renderGraphSettings = () => {
    return (
      <div style={{ paddingTop: 20,backgroundColor: 'white', display: 'flex', paddingBottom: 20, flexDirection: 'column' }}>
        {renderSizeAndPlacement()}

        <ToolSeparator />

        {renderCellBackgroundColor()}
        <ToolSeparator />
        {renderTextControls()}
      </div>
    )
  }

  if (isDeveloperDomain()) {
      return (
        <Authenticator
          hideSignUp={true}
        >
          {({ /*signOut,*/ user }) => (
            renderCheckDeveloperStatus(/*signOut*/)
          )}
        </Authenticator>
      )
  } else {
    return renderUI();
  }
}

export default App;

