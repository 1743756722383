import React, {useEffect, useState, useRef, forwardRef, useReducer, useImperativeHandle, useCallback} from 'react';
import {hBarGGC} from './graphs/hbarggc';
import { stackedBarGGC } from './graphs/stackbarggc';
import {pieGGC} from './graphs/pieggc';
import { pivotGGC } from './graphs/pivotggc';
import {areasplineGGC} from './graphs/areasplineggc';
import { sunburstGGC } from './graphs/sunburstggc';
import { treemapGGC } from './graphs/treemapggc';
import { circlepackGGC } from './graphs/circlepackggc';
import $ from 'jquery';
import { _genId,  genCleanId } from './graphs/domutils';
import {isQRContest, debounce, getApiKey} from './utils/Utils';
import QRScaleText from './components/QRScaleText/QRScaleText';
import {tabularToChildren} from './graphs/sampledata';
import { API } from 'aws-amplify';
import { onResponseBySpecificQuestion, onResponseByProjectId } from './subscriptions';
import {biaColors} from './graphs/graphbaseggc';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import VerticalSizer from './components/VerticalSizer/VerticalSizer';
import HorizontalSizer from './components/HorizontalSizer/HorizontalSizer';
import { colors, isHierarchicalChart, canSort } from './utils/Utils';
import questionTreeData from './utils/gvars';
import DropDownChecks from './components/DropDownChecks/DropDownChecks';
import D3Wrapper from './components/D3Wrapper/D3Wrapper';
import { FaChevronDown } from 'react-icons/fa';

const uidReducer = (state, action) => {
  switch (action.type) {
    case "set":
      return {
        ...state,
        uid: action.uid,
        txtFontSize: action.txtFontSize,
        txtFontStyle: action.txtFontStyle,
        txtFontWeight: action.txtFontWeight,
        txtDecoration: action.txtDecoration,
        txtColor: action.txtColor,
        txtLocationFontSize: action.txtLocationFontSize,
        txtLocationFontStyle: action.txtLocationFontStyle,
        txtLocationFontWeight: action.txtLocationFontWeight,
        txtLocationDecoration: action.txtLocationDecoration,
        txtLocationColor: action.txtLocationColor,
        txtCampaignFontSize: action.txtCampaignFontSize,
        txtCampaignFontStyle: action.txtCampaignFontStyle,
        txtCampaignFontWeight: action.txtCampaignFontWeight,
        txtCampaignDecoration: action.txtCampaignDecoration,
        txtCampaignColor: action.txtCampaignColor,
        cellBGColor: action.cellBGColor,
        locCampHeight: action.locCampHeight,
        questionHeight: action.questionHeight,
        locationWidth: action.locationWidth,
        campaignWidth: action.campaignWidth,
        containerPadding: action.containerPadding,
        categories: action.categories,
        legends: action.legends,
        showQuestion: action.showQuestion,
        showCampaignName: action.showCampaignName,
        showLocationName: action.showLocationName,
        showSorter: action.showSorter,
        lockLocation: action.lockLocation,
        lockCampaign: action.lockCampaign,
        lockSorter: action.lockSorter,
      }

    case "setTxtColor":
      {
        let retState = {...state};
        const prop = action.sub === "" ? "txtColor" : "txt"+action.sub+"Color";
        retState[prop] = action.pval;
        return retState;
      }
    case "setTxtFontSize":
      {
        let retState = {...state};
        const prop = action.sub === "" ? "txtFontSize" : "txt"+action.sub+"FontSize";
        retState[prop] = action.pval;
        return retState;
      }
    case "setTxtFontStyle":
      {
        let retState = {...state};
        const prop = action.sub === "" ? "txtFontStyle" : "txt"+action.sub+"FontStyle";
        retState[prop] = action.pval;
        return retState;
      }
    case "setTxtFontWeight":
      {
        let retState = {...state};
        const prop = action.sub === "" ? "txtFontWeight" : "txt"+action.sub+"FontWeight";
        retState[prop] = action.pval;
        return retState;
      }
    case "setTxtDecoration":
      {
        let retState = {...state};
        const prop = action.sub === "" ? "txtDecoration" : "txt"+action.sub+"Decoration";
        retState[prop] = action.pval;
        return retState;
      }
    case "setCellBGColor":
      return {
        ...state,
        cellBGColor: action.pval,
      }
    case "setSelected":
      return {
        ...state,
        selected: action.selected,
      }
    case "setHeightFromLocCamp":  // showQuestion, (showLocationName || showCampaignName).
      {
        let totalHeight = state.locCampHeight;
        if (state.showQuestion) {
          totalHeight += state.questionHeight;
        }
        return {
          ...state,
          locCampHeight: action.pos,
          questionHeight: totalHeight - action.pos,
        }
      }
    case "setHeightFromSplitter": // splitter is between the locCamp+Question and the graph.
      {
        // pos is % of locCamp+Question ht
        let topHeight = 0;
        if (state.showQuestion) {
          topHeight += state.questionHeight;
        }
        if (state.showLocationName || state.showCampaignName) {
          topHeight += state.locCampHeight;
        }

        return {
          ...state,
          locCampHeight: (state.showLocationName || state.showCampaignName) ?  state.locCampHeight * (action.pos / topHeight) : state.locCampHeight,
          questionHeight: state.questionHeight ? state.questionHeight * (action.pos / topHeight) : state.questionHeight
        }
      }

    case "setContainerPadding":
      return {
        ...state,
        containerPadding: action.containerPadding,
      }
    case "setLocCampHeight":
      return {
        ...state,
        locCampHeight: action.locCampHeight,
      }
    case "setQuestionHeight":
      return {
        ...state,
        questionHeight: action.questionHeight,
      }
    case "setLocCampWidth":
      return {
        ...state,
        locationWidth: action.pos,
        campaignWidth: 100 - action.pos,
      }
    case "setLocationWidth":
      return {
        ...state,
        locationWidth: action.locationWidth,
      }
    case "setCampaignWidth":
      return {
        ...state,
        campaignWidth: action.campaignWidth,
      }
    case "setCategories":
      return {
        ...state,
        categories: action.categories,
      }
    case 'setLegends':
      return {
        ...state,
        legends: action.legends,
      }

    case "showLocation":
      {
        return {
          ...state,
          showLocationName: action.show
        }
      }
    case "showCampaign":
      return {
        ...state,
        showCampaignName: action.show
      }
    case "showQuestion":
      return {
        ...state,
        showQuestion: action.show
      }
    case "showSorter":
      return {
        ...state,
        showSorter: action.show
      }
    case "lockLocation":
      return {
        ...state,
        lockLocation: action.lock
      }
    case "lockCampaign":
      return {
        ...state,
        lockCampaign: action.lock
      }
    case "lockSorter":
      return {
        ...state,
        lockSorter: action.lock
      }
    case "setEditMode":
      return {
        ...state,
        editMode: action.edit
      }
    case "setPickingLocations":
      return {
        ...state,
        pickingLocations: action.picking
      }
    case "setPickingCampaigns":
      return {
        ...state,
        pickingCampaigns: action.picking
      }
    
    default:
      break;
  }
  return {
    ...state
  }
}

const qraReducer = (state, action) => {
  switch (action.type) {
    case "fetching":
      return {
        ...state,
        fetching: action.fetching,
      }

    case "qraSet":
      return {
        ...state,
        qra: action.data,    // this was qra.data[0]
      }
    case "dataTableSet":
      return {
        ...state,
        dataTable: action.data,
        dataHeader: action.header
      }
    case "filteredDataSet":
      return {
        ...state,
        filteredData: action.filteredData,
        filteredHeader: action.filteredHeader,
        hasImages: action.hasImages,
      }
    case "questionTextSet":
      return {
        ...state,
        questionText: action.questionText,
      }

    case "campLocSet":
        return {
          ...state,
          campaigns: action.campaigns,
          locations: action.locations,
          answers: action.answers,
        }
    case "setNoResponseData":
      return {
        ...state,
        noResponseData: action.noResponseData,
      }

    case "response":    // occurs on subscription to baseId - response to a vote
      {       // .qra should be an array of 1 if we are here. However, like the Highlander
              // there can be only 1 match in the array.  So we just use the first one.
              // that way, this works for both question and assignment
        // hdr = ['baseId', 'campaign', 'location', 'answer', 'active', 'draft', 'inactive'];   // array of rows (array)

        const response = action.response;        
        const myBaseId = response.baseId;

        var voteType;
        if (myBaseId.endsWith('_draft#')) {
          voteType = 'draft';
        } else if (myBaseId.endsWith('_inactive#')) {
          voteType = 'inactive';
        } else {
          voteType = 'active';
        }

        let fldIx = state.dataHeader.findIndex((itm) => itm === voteType);
        //let matchBaseId = -1;
        let newTable = null;
        if (state.noResponseData) {
          let ixDraft = state.dataHeader.findIndex((itm) => itm === 'draft');
          let ixInactive = state.dataHeader.findIndex((itm) => itm === 'inactive');
          let ixActive = state.dataHeader.findIndex((itm) => itm === 'active');
          newTable = [...state.dataTable];
          for (let i = 0; i < state.dataTable.length; i++) {
            newTable[i][ixDraft] = 0;
            newTable[i][ixInactive] = 0;
            newTable[i][ixActive] = 0;
          }
        }

        for (let i = 0; i < state.dataTable.length; i++) {
          if (state.dataTable[i][0] === myBaseId) {
            //matchBaseId = i;
            if (state.dataTable[i][3].id === response.answerId) {
              if (fldIx !== -1) {
                if (!newTable) {
                  newTable = [...state.dataTable];
                }
                newTable[i][fldIx] = response.count;
                return {
                  ...state,
                  dataTable: newTable,
                  noResponseData: false,
                }
              }
            }
          }
        }
        // Catch if we don't match anything, don't think this can happen, but might as well
        // catch it.
        if (newTable) {
          return {
            ...state,
            dataTable: newTable,
            noResponseData: false,
          }
        }

        // New row added for this answer (not sure if this can happen...)
        // Only thing 'new' would be answer {id: name:..} but we don't have name.. and count
        // since this database is gotten from the RESPONSEQUESTIONANSWERS table, it will have
        // all the possible answers, so we can't get a new one... so don't try to add one here
        /*
        if (matchBaseId !== -1) {
          let newTable = [...state.dataTable];
          let newRow = [...newTable[matchBaseId]];
          newRow[fldIx] = response.count;
          newTable.splice(matchBaseId+1,0,newRow);
          return {
            ...state,
            dataTable: newTable
          }
        }
        */

        /*
        let newQRA = JSON.parse(JSON.stringify(state.qra));
        for (let i = 0; i < newQRA.length; i++) {
          const thisQra = newQRA[i];
          if (thisQra.id === response.baseId) {
            const fndA = thisQra.questionAnswers.answers.find((ans) => ans.id === response.answerId);
            if (fndA) {
              fndA.responses[voteType].visits = response.count;
              return {
                ...state,
                qra: newQRA,
              }
            }
          }
        }
        */
      }
      break;
    case "locUse":
      {
        let newLocs = JSON.parse(JSON.stringify(state.locations));
        for (let i = 0; i < newLocs.length; i++) {
          const thisLoc = newLocs[i];
          if (thisLoc.id === action.id) {
            thisLoc.use = action.use;
            break;
          }
        }
        return {
          ...state,
          locations: newLocs,
        }
      }

      case "campUse":
        {
          let newCamps = JSON.parse(JSON.stringify(state.campaigns));
          for (let i = 0; i < newCamps.length; i++) {
            const thisCamp = newCamps[i];
            if (thisCamp.id === action.id) {
              thisCamp.use = action.use;
              break;
            }
          }
          return {
            ...state,
            campaigns: newCamps,
          }
        }
  
    default:
      break;
    }

    return {
      ...state
    }
  }

// baseId, graph, style.  added srcType=='assignment' or 'question'.  Assignment is full baseId, question is just the questionId
const GraphComponent = forwardRef(({uid, baseId, rerender, developer, projectId, cascadeRefresh, 
  savedProps, graphType, srcType, pivot, style, notifyContainer=()=>null}, ref) => {
  useImperativeHandle(ref, () => ({
    resetAll() {
      setMyGraph(null);
    },

		saveProps() {
      var gSave = {     // these are the values of 'savedProps' above, passed to this component
        graphType: graphType,
        pivot: pivot,
        cellBackgroundColor: uidState.cellBGColor,

        txtSize: uidState.txtFontSize,
        txtColor: uidState.txtColor,
        txtStyle: uidState.txtFontStyle,
        txtWeight: uidState.txtFontWeight,
        txtDecoration: uidState.txtDecoration,

        txtLocationFontSize: uidState.txtLocationFontSize,
        txtLocationColor: uidState.txtLocationColor,
        txtLocationFontStyle: uidState.txtLocationFontStyle,
        txtLocationFontWeight: uidState.txtLocationFontWeight,
        txtLocationDecoration: uidState.txtLocationDecoration,

        txtCampaignFontSize: uidState.txtCampaignFontSize,
        txtCampaignColor: uidState.txtCampaignColor,
        txtCampaignFontStyle: uidState.txtCampaignFontStyle,
        txtCampaignFontWeight: uidState.txtCampaignFontWeight,
        txtCampaignDecoration: uidState.txtCampaignDecoration,

        showQuestion: uidState.showQuestion,
        showCampaignName: uidState.showCampaignName,
        showLocationName: uidState.showLocationName,
        lockCampaign: uidState.lockCampaign,
        lockLocation: uidState.lockLocation,
        lockSorter: uidState.lockSorter,
        //showSorter: uidState.showSorter,  moved saving to graph

        locCampHeight: uidState.locCampHeight,
        questionHeight: uidState.questionHeight,
        locationWidth: uidState.locationWidth,
        campaignWidth: uidState.campaignWidth,

        containerPadding: uidState.containerPadding,

        categories: uidState.categories,
        legends: uidState.legends,

        saveProps: myGraph ? myGraph._saveProps() : {}, // myGraph is null if graphType is pivot
      }
      return gSave;
		},
    getContainerPropsOnly() {
      return {
        cellBackgroundColor: uidState.cellBGColor,
        txtSize: uidState.txtFontSize,
        txtColor: uidState.txtColor,
        txtStyle: uidState.txtFontStyle,
        txtWeight: uidState.txtFontWeight,
        txtDecoration: uidState.txtDecoration,
        txtLocationFontSize: uidState.txtLocationFontSize,
        txtLocationColor: uidState.txtLocationColor,
        txtLocationFontStyle: uidState.txtLocationFontStyle,
        txtLocationFontWeight: uidState.txtLocationFontWeight,
        txtLocationDecoration: uidState.txtLocationDecoration,
        txtCampaignFontSize: uidState.txtCampaignFontSize,
        txtCampaignColor: uidState.txtCampaignColor,
        txtCampaignFontStyle: uidState.txtCampaignFontStyle,
        txtCampaignFontWeight: uidState.txtCampaignFontWeight,
        txtCampaignDecoration: uidState.txtCampaignDecoration,
        showQuestion: uidState.showQuestion,
        showCampaignName: uidState.showCampaignName,
        showLocationName: uidState.showLocationName,
        lockCampaign: uidState.lockCampaign,
        lockLocation: uidState.lockLocation,
        lockSorter: uidState.lockSorter,
        //showSorter: uidState.showSorterr,  moved to graph
        locCampHeight: uidState.locCampHeight,
        questionHeight: uidState.questionHeight,
        locationWidth: uidState.locationWidth,
        campaignWidth: uidState.campaignWidth,
        containerPadding: uidState.containerPadding,

      }
    },
    getOuterId() {
      return 'grC_' + uid;
    },
    getAxisPropsOnly() {
      if (myGraph)
        if (typeof myGraph.getAxisPropsOnly === 'function') {
          return myGraph.getAxisPropsOnly();
        }
      return {};
    },
		getBasePropsOnly() {
      if (myGraph)
        if (typeof myGraph.getBasePropsOnly === 'function') {
          return myGraph.getBasePropsOnly();
        }
        else
				return {};
		},
		getPalettePropsOnly() {
      if (myGraph)
        if (typeof myGraph.getPalettePropsOnly === 'function') {
          return myGraph.getPalettePropsOnly();
        }
        else
				return {};
		},
    applyThemes(item) {
      if (myGraph) {
        if (item.containerProps) {
          this.setProperty(item.containerProps);
        }
        myGraph.applyThemes(item)
      }
    },
    useImagesAsFill() {
        /*
        Change the colorPaletteType to 'custom' and set the colorRef to the imageUrl for each answer that has an image.
        This could use less than .qra - as we only use the images
        */
        let pal = biaColors._d3ColorsCategorical[biaColors._d3ColorsCategoricalDefaultIx].colors;  // array of hex values
        let customPalette = {
          name: "newpalette",
          loc: null,
          interpolate: false,
          colorRef: [],
          hasDef: true,
        }
        let domain = [];
        for (let i = 0; i < qraState.qra[0].questionAnswers.answers.length; i++) {
          let ans = qraState.qra[0].questionAnswers.answers[i];
          domain.push(ans.text);
          if (ans.hasOwnProperty('imageUrl') && ans.imageUrl !== null && ans.imageUrl !== '') {
            customPalette.colorRef.push({
              "rgb": null,
              "stretch": false,
              "loc": ans.imageUrl,
              "opacity": 0.7,
              "ref": "U" + genCleanId()
            })
          } else {
            // put in a color table value
            customPalette.colorRef.push({
              rgb: pal[i % pal.length],
              loc: null,
              opacity: 0.7
            });
          }
        }
      myGraph.colorPaletteType = 'custom';
      myGraph.customPalette = customPalette;
      if (isHierarchicalChart(graphType)) {
        if (myGraph.hierPalette && myGraph.hierPalette.length > 0) {
          myGraph.hierPalette[0].type = 'custom';
          myGraph.hierDomain = domain;
        }
      }
      resizeComp();

    },
    useAnswerColorAsFill() {
      /*
      Change the colorPaletteType to 'custom' and set the colorRef to the imageUrl for each answer that has an image.
      This could use less than .qra - as we only use the images
      */
      let pal = biaColors._d3ColorsCategorical[biaColors._d3ColorsCategoricalDefaultIx].colors;  // array of hex values
      let customPalette = {
        name: "newpalette",
        loc: null,
        interpolate: false,
        colorRef: [],
        hasDef: true,
      }
      let domain = [];
      for (let i = 0; i < qraState.qra[0].questionAnswers.answers.length; i++) {
        let ans = qraState.qra[0].questionAnswers.answers[i];
        domain.push(ans.text);
        if (ans.hasOwnProperty('additionalInfo') && ans.additionalInfo.hasOwnProperty('graph') &&
            ans.additionalInfo.graph.hasOwnProperty('color')) {
          customPalette.colorRef.push({
            rgb: ans.additionalInfo.graph.color,
            loc: null,
            opacity: 0.7,
          })
        } else {
          // put in a color table value
          customPalette.colorRef.push({
            rgb: pal[i % pal.length],
            loc: null,
            opacity: 0.7
          });
        }
      }
    myGraph.colorPaletteType = 'custom';
    myGraph.customPalette = customPalette;
    if (isHierarchicalChart(graphType)) {
      if (myGraph.hierPalette && myGraph.hierPalette.length > 0) {
        myGraph.hierPalette[0].type = 'custom';
        myGraph.hierDomain = domain;
      }
    }
    resizeComp();
  },
    toggleEdit() {
      if (myGraph) {
        uidDispatch({type: 'setEditMode', edit: !myGraph.getEditMode()});
        return myGraph._toggleEdit();
      }
    },
    getEditMode() {
      if (myGraph) {
        return myGraph.getEditMode();
      } else {
        return false;
      }
    },
    getHasImages() {
      return hasImages;
    },
    getPivot() {
      return pivot;
    },
    getCellBackgroundColor() {
      return uidState.cellBGColor;
    },
    setCellBackgroundColor(c) {
      uidDispatch({type: 'setCellBGColor', pval: c});
    },
    setSelected(selected) {
      uidDispatch({type: 'setSelected', selected: selected});
    },
    getProperty(arProps) {     // e.g.  ["pivot", "txtColor", "txtStyle", "txtWeight", "txtDecoration", "cellBackgroundColor"]...
      let retVals = {};
      let arlProps;
      if (typeof arProps === 'string') {
        arlProps = [arProps];
      } else {
        arlProps = arProps;
      }
      for (let i=0; i<arlProps.length; i++) {
        switch (arlProps[i]) {
          case 'graphType':
            retVals.graphType = graphType;
            break;
          case 'pivot':
            retVals.pivot = pivot;
            break;
          case 'txtColor':
            retVals.txtColor = uidState.txtColor;
            break;
          case 'txtFontStyle':
            retVals.txtFontStyle = uidState.txtFontStyle;
            break;
          case 'txtFontWeight':
            retVals.txtFontWeight = uidState.txtFontWeight;
            break;
          case 'txtDecoration':
            retVals.txtDecoration = uidState.txtDecoration;
            break;
          case 'cellBackgroundColor':
            retVals.cellBackgroundColor = uidState.cellBGColor;
            break;
          case 'showLocationName':
            retVals.showLocationName = uidState.showLocationName;
            break;
          case 'showCampaignName':
            retVals.showCampaignName = uidState.showCampaignName;
            break;
          case 'showQuestion':
            retVals.showQuestion = uidState.showQuestion;
            break;
          case 'showSorter':
            //retVals.showSorter = uidState.showSorter;
            if (myGraph && myGraph.hasOwnProperty('showSorter')) {
              retVals.showSorter = myGraph.showSorter;
            } else {
              retVals.showSorter = true;
            }
            break;
          case 'lockLocation':
            retVals.lockLocation = uidState.lockLocation;
            break;
          case 'lockCampaign':
            retVals.lockCampaign = uidState.lockCampaign;
            break;
          case 'lockSorter':
            retVals.lockSorter = uidState.lockSorter;
            break;            
          case 'containerPadding':
            retVals.containerPadding = uidState.containerPadding;
            break;
          case 'locCampHeight':
            retVals.locCampHeight = uidState.locCampHeight;
            break;
          case 'questionHeight':
            retVals.questionHeight = uidState.questionHeight;
            break;
          case 'locationWidth':
            retVals.locationWidth = uidState.locationWidth;
            break;
          case 'campaignWidth':
            retVals.campaignWidth = uidState.campaignWidth;
            break;
          case 'categories':
            retVals.categories = uidState.categories;
            break;
          case 'legends':
            retVals.legends = uidState.legends;
            break;
          case 'txtSorterColor':
            if (myGraph && myGraph.hasOwnProperty('txtSorterColor')) {
              retVals.txtSorterColor = myGraph.txtSorterColor;
            } else {
              retVals.txtSorterColor = '#000000';
            }
            break;
          case 'txtSorterFontStyle':
            if (myGraph && myGraph.hasOwnProperty('txtSorterFontStyle')) {
              retVals.txtSorterFontStyle = myGraph.txtSorterFontStyle;
            } else {
              retVals.txtSorterFontStyle = 'normal';
            }
            break;
          case 'txtSorterFontWeight':
            if (myGraph && myGraph.hasOwnProperty('txtSorterFontWeight')) {
              retVals.txtSorterFontWeight = myGraph.txtSorterFontWeight;
            } else {
              retVals.txtSorterFontWeight = 'normal';
            }
            break;
          case 'txtSorterDecoration':
            if (myGraph && myGraph.hasOwnProperty('txtSorterDecoration')) {
              retVals.txtSorterDecoration = myGraph.txtSorterDecoration;
            } else {
              retVals.txtSorterDecoration = 'none';
            }
            break;
          case 'noResponseData':
            retVals.noResponseData = qraState.noResponseData;
            break;
          default:
            break;
        }
      }
      return retVals;
    },
    setProperty(hashNV) {   // {txtColor: {r: 0, g: 0, b: 0, a: 1.0}, txtStyle: 'normal', txtWeight: 'normal', txtDecoration: 'none', cellBackgroundColor: ''}
      for (const prop in hashNV) {
        switch (prop) {
          //case 'graphType':
          //  setGraph(hashNV[prop]);
          //  break;
          //case 'pivot':
          //  uidDispatch({type: 'setPivot', pval: hashNV[prop]});
          //  break;
          case 'txtColor':
            uidDispatch({ type: 'setTxtColor', sub: "", pval: hashNV[prop] });
            break;
          case 'txtFontStyle':
            uidDispatch({ type: 'setTxtFontStyle',  sub: "", pval: hashNV[prop] });
            break;
          case 'txtFontWeight':
            uidDispatch({ type: 'setTxtFontWeight',  sub: "", pval: hashNV[prop] });
            break;
          case 'txtDecoration':
            uidDispatch({ type: 'setTxtDecoration',  sub: "", pval: hashNV[prop] });
            break;
          case 'txtLocationColor':
            uidDispatch({ type: 'setTxtColor',  sub: "Location", pval: hashNV[prop] });
            break;
          case 'txtLocationFontStyle':
            uidDispatch({ type: 'setTxtFontStyle',  sub: "Location", pval: hashNV[prop] });
            break;
          case 'txtLocationFontWeight':
            uidDispatch({ type: 'setTxtFontWeight', sub: "Location", pval: hashNV[prop] });
            break;
          case 'txtLocationDecoration':
            uidDispatch({ type: 'setTxtDecoration',sub: "Location", pval: hashNV[prop] });
            break;
          case 'txtCampaignColor':
            uidDispatch({ type: 'setTxtColor', sub: "Campaign" ,pval: hashNV[prop] });
            break;
          case 'txtCampaignFontStyle':
            uidDispatch({ type: 'setTxtFontStyle', sub: "Campaign", pval: hashNV[prop] });
            break;
          case 'txtCampaignFontWeight':
            uidDispatch({ type: 'setTxtFontWeight', sub: "Campaign", pval: hashNV[prop] });
            break;
          case 'txtCampaignDecoration':
            uidDispatch({ type: 'setTxtDecoration',  sub: "Campaign", pval: hashNV[prop] });
            break;
          case 'cellBackgroundColor':
            uidDispatch({type: 'setCellBGColor', pval: hashNV[prop]});
            break;
          case 'showQuestion':
            uidDispatch({type: 'showQuestion', show: hashNV[prop]});
            break;
          case 'showLocationName':
            uidDispatch({type: 'showLocation', show: hashNV[prop]});
            break;
          case 'showCampaignName':
            uidDispatch({ type: 'showCampaign', show: hashNV[prop] });
            break;
          case 'showSorter':
            uidDispatch({ type: 'showSorter', show: hashNV[prop] });
            if (myGraph && typeof myGraph.setShowSorter === 'function') {
              myGraph.setShowSorter(hashNV[prop]);
            }
            break;
          case 'lockLocation':
            uidDispatch({type: 'lockLocation', lock: hashNV[prop]});
            break;
          case 'lockCampaign':
            uidDispatch({type: 'lockCampaign', lock: hashNV[prop]});
            break;
          case 'lockSorter':
            uidDispatch({type: 'lockSorter', lock: hashNV[prop]});
            if (myGraph && typeof myGraph.setLockSorter === 'function') {
              myGraph.setLockSorter(hashNV[prop]);
            }
            break;
          case 'containerPadding':
            uidDispatch({type: 'setContainerPadding', containerPadding: hashNV[prop]});
            break;
          case 'locCampHeight':
            uidDispatch({type: 'setLocCampHeight', locCampHeight: hashNV[prop]});
            break;
          case 'questionHeight':
            uidDispatch({type: 'setQuestionHeight', questionHeight: hashNV[prop]});
            break;
          case 'locationWidth':
            uidDispatch({type: 'setLocationWidth', locationWidth: hashNV[prop]});
            break;
          case 'campaignWidth':
            uidDispatch({type: 'setCampaignWidth', campaignWidth: hashNV[prop]});
            break;
          case 'categories':
            uidDispatch({type: 'setCategories', categories: hashNV[prop]});
            break;
          case 'legends':
            uidDispatch({type: 'setLegends', legends: hashNV[prop]});
            break;
          case 'txtSorterColor':
            if (myGraph && myGraph.hasOwnProperty('txtSorterColor')) {
              myGraph.setTxtSorterColor(hashNV[prop]);
            }
            break;
          case 'txtSorterFontStyle':
            if (myGraph && myGraph.hasOwnProperty('txtSorterFontStyle')) {
              myGraph.setTxtSorterFontStyle(hashNV[prop]);
            }
            break;
          case 'txtSorterFontWeight':
            if (myGraph && myGraph.hasOwnProperty('txtSorterFontWeight')) {
              myGraph.setTxtSorterFontWeight(hashNV[prop]);
            }
            break;
          case 'txtSorterDecoration':
            if (myGraph && myGraph.hasOwnProperty('txtSorterDecoration')) {
              myGraph.setTxtSorterDecoration(hashNV[prop]);
            }
            break;
          default:
            break;
        }
      }
      
    },
    isDataEmpty() {
      return qraState.noResponseData;
    },
    generateSampleData () {
      generateFakeData();
    },

	}));
  const [graphData, setGraphData] = useState(null);
	const [myId, setMyId] = useState(null);
  const [myGraph, setMyGraph] = useState(null);
  const [hasImages, setHasImages] = useState(false);
  //const [noResponseData, setNoResponseData] = useState(false);
  const [t] = useTranslation();


  const uidParamsInit = {
    uid: null,
    txtFontSize: 12,
    txtFontStyle: 'normal',
    txtFontWeight: 'normal',
    txtDecoration: 'none',
    txtColor: 'black',
    txtLocationFontSize: 12,
    txtLocationFontStyle: 'normal',
    txtLocationFontWeight: 'normal',
    txtLocationDecoration: 'none',
    txtLocationColor: 'black',
    txtCampaignFontSize: 12,
    txtCampaignFontStyle: 'normal',
    txtCampaignFontWeight: 'normal',
    txtCampaignDecoration: 'none',
    txtCampaignColor: 'black',
    cellBGColor: '',
    showLocationName: true,
    showCampaignName: true,
    showQuestion: true,
    showSorter: true,
    selected: false,
    editMode: false,
    pickingLocations: false,
    pickingCampaigns: false,
    lockLocation: false,
    lockCampaign: false,
    lockSorter: false,

    categories: ['answer'],
    legends: ['location'],
  }
  const [uidState, uidDispatch] = useReducer(uidReducer, uidParamsInit);

  const actualDataInit = {
    fetching: false,
    qra: null,
    campaigns: [],
    locations: [],
    answers: [],
    dataTable: [],    // full data unfiltered
    dataHeader: [],

    filteredHeader: [],
    filteredData: [],
    hasImages: false,

    questionText: "",
    noResponseData: false,
}
  const [qraState, qraDispatch] = useReducer(qraReducer, actualDataInit);


  
  // check if saveProps? if so, load them
//  const graph = savedProps ? (savedProps.graphType ? savedProps.graphType : 'hbar') : 'hbar';

  const checkUidParams = (uid) => {
    if (uid != null) {
      if (uid !== uidState.uid) {
        uidDispatch({type: 'set',
          uid: uid, 
          txtFontSize: savedProps.txtSize || 12, 
          txtFontStyle: savedProps.txtStyle || 'normal', 
          txtFontWeight: savedProps.txtWeight || 'normal', 
          txtDecoration: savedProps.txtDecoration || 'none', 
          txtColor: savedProps.txtColor || 'black', 
          txtLocationFontSize: savedProps.txtLocationSize || 12, 
          txtLocationFontStyle: savedProps.txtLocationStyle || 'normal', 
          txtLocationFontWeight: savedProps.txtLocationWeight || 'normal', 
          txtLocationDecoration: savedProps.txtLocationDecoration || 'none', 
          txtLocationColor: savedProps.txtLocationColor || 'black', 
          txtCampaignFontSize: savedProps.txtCampaignSize || 12, 
          txtCampaignFontStyle: savedProps.txtCampaignStyle || 'normal', 
          txtCampaignFontWeight: savedProps.txtCampaignWeight || 'normal', 
          txtCampaignDecoration: savedProps.txtCampaignDecoration || 'none', 
          txtCampaignColor: savedProps.txtCampaignColor || 'black', 
          cellBGColor: savedProps.cellBackgroundColor || '',
          locCampHeight: savedProps.locCampHeight || 10,
          questionHeight: savedProps.questionHeight || 10,
          locationWidth: savedProps.locationWidth || 50,
          campaignWidth: savedProps.campaignWidth || 50,
          containerPadding: savedProps.containerPadding || 10,
          showQuestion: savedProps.hasOwnProperty('showQuestion') ? savedProps.showQuestion : true,
          showCampaignName: savedProps.hasOwnProperty('showCampaignName') ? savedProps.showCampaignName : true,
          showLocationName: savedProps.hasOwnProperty('showLocationName') ? savedProps.showLocationName : true,
          showSorter: savedProps.hasOwnProperty('showSorter') ? savedProps.showSorter : true,
          lockLocation: savedProps.hasOwnProperty('lockLocation') ? savedProps.lockLocation : false,
          lockCampaign: savedProps.hasOwnProperty('lockCampaign') ? savedProps.lockCampaign : false,
          lockSorter: savedProps.hasOwnProperty('lockSorter') ? savedProps.lockSorter : false,
          categories: savedProps.categories || ['answer'],
          legends: savedProps.legends || ['location'],
        });
      }
    }
    return pivot;
  }

  const showGraph = () => {

    $('#'+myId).empty();

    var height = $('#'+myId).height();  // subtract border? do we need this?
    var width = $('#'+myId).width();
    
    $('#'+myId).empty();
    
    let args = {
      container: '#'+myId,
      grouped: false,
      valueAxisFormat: 'd',
    };
    args.categoryClick = function (evt) {
      console.log(evt);
    }

    if (graphData.hasOwnProperty('canSort') && uidState.showSorter) {
      args.canSort = graphData.canSort;
    } else {
      args.canSort = false;
    }

    // If saved props, load them here from saveProps.argprops
    let aggArgs = {...savedProps.saveProps, ...args};

    /*
    let argprops = {
      "colorPaletteType": "custom",
      "colorPaletteIndex": 2,
      "customPalette": {
        "name": "newpalette", "loc": null, "interpolate": false,
        "colorRef": [
          { "rgb": null, stretch: false, "loc": "https://img.freepik.com/free-photo/portrait-lion-ai-generated_268835-4278.jpg?w=2000", "opacity": 0.7, "ref": "U22577ad574303afd9a72af89c28855e1" },
          { "rgb": null, stretch: false, "loc": "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcT9XT89rHOJLr7fOg3wSlQLss0SVKd11QLerGhgXq2ZBB2IGGN1", "opacity": 0.7, "ref": "U22577ad574303afd9a72af89c28855e2" },
          { "rgb": null, stretch: false, "loc": "https://upload.wikimedia.org/wikipedia/commons/9/9e/Ours_brun_parcanimalierpyrenees_1.jpg", "opacity": 0.7, "ref": "U22577ad574303afd9a72af89c28855e3" },
          { "rgb": "#bf6cc7", "loc": null, "opacity": 0.7 },
          { "rgb": "#f7534d", "loc": null, "opacity": 0.7 },
          { "rgb": "#ffef76", "loc": null, "opacity": 0.7 },
          { "rgb": "#3b56b5", "loc": null, "opacity": 0.7 },
          { "rgb": "#fb90b0", "loc": null, "opacity": 0.7 },
          { "rgb": "#56b7f6", "loc": null, "opacity": 0.7 },
          { "rgb": "#0097a8", "loc": null, "opacity": 0.7 },
          { "rgb": "#805bc2", "loc": null, "opacity": 0.7 },
          { "rgb": "#ff8962", "loc": null, "opacity": 0.7 },
          { "rgb": "#c3e0a6", "loc": null, "opacity": 0.7 },
          { "rgb": "#8f6e63", "loc": null, "opacity": 0.7 },
          { "rgb": "#e0e0e0", "loc": null, "opacity": 0.7 },
          { "rgb": "#9e9e9e", "loc": null, "opacity": 0.7 },
          { "rgb": "#34474f", "loc": null, "opacity": 0.7 }],
        "hasDef": true
      },
      "margin": { "top": 30, "right": 30, "bottom": 5, "left": 50 }, 
      "colorPaletteOpacity": 0.7, 
      "backgroundColor": { "rgb": "#ffffff", "loc": null, "stretch": false, "opacity": 1 }, 
      "legendBackgroundColor": { "rgb": "#ffffff", "loc": null, "stretch": false, "opacity": 0.3333333333333333 },
      "font": "12px Verdana, Geneva, sans-serif", 
      "legendFont": "12px Verdana, Geneva, sans-serif", 
      "legendFontStyle": "", 
      "legendColor": "#434343", 
      "isHorizontal": false, 
      "sortOrder": ["2 ASC"], 
      "xAxisFont": "12px Verdana, Geneva, sans-serif", 
      "xAxisFontStyle": "", 
      "xAxisLabelColor": "#7E7F7F", 
      "xAxisColor": "#DEE2E6", 
      "xAxisStrokeWidth": 1, 
      "yAxisFont": "12px Verdana, Geneva, sans-serif", 
      "yAxisFontStyle": "", "yAxisLabelColor": "#7E7F7F", 
      "yAxisColor": "#DEE2E6", 
      "yAxisStrokeWidth": 1, 
      "ellipses": 30, 
      "xAxisGridColor": "#DEE2E6", 
      "xAxisGridStrokeWidth": 1, 
      "yAxisGridColor": "#DEE2E6", 
      "yAxisGridStrokeWidth": 1, 
      "_bStagger": false, 
      "hasYGrid": true, 
      "selectorHeight": 50, 
      "heightOverview": 40, 
      "grouped": true, 
      "minBarWidth": 10, 
      "barOutlineWidth": 1, 
      "barOutlineColor": { "rgb": null, "loc": "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcT9XT89rHOJLr7fOg3wSlQLss0SVKd11QLerGhgXq2ZBB2IGGN1", "stretch": true, "opacity": 1, "ref": "U12990d60fb9ec870b3e948eb5a0f02c5" }, 
      "barOutlineStrokeDashArray": "0", 
      "_computedBarWidth": 36.61875
    }

    let aggArgs = { ...argprops, ...args };
    */

    var sb;
    switch (graphType) {
      case 'pivot':
        sb = new pivotGGC(aggArgs);
        break;
      case 'bar':
        aggArgs.grouped = true;
        sb = new stackedBarGGC(aggArgs);
        break;
      case 'sbar':
        aggArgs.grouped = false;
        sb = new stackedBarGGC(aggArgs);
        break;
      case 'hbar':
        aggArgs.grouped = true;
        sb = new hBarGGC(aggArgs);
        break;
      case 'shbar':
        aggArgs.grouped = false;
        sb = new hBarGGC(aggArgs);
        break;
      case 'line':
        aggArgs.fill = false;
        aggArgs.line = true;
        sb = new areasplineGGC(aggArgs);
        break;
      case 'spline':
        aggArgs.fill = false;
        aggArgs.line = false;
        sb = new areasplineGGC(aggArgs);
        break;
      case 'aspline':
        aggArgs.fill = true;
        aggArgs.line = false;
        sb = new areasplineGGC(aggArgs);
        break;
      case 'pie':
        aggArgs.innerRadius = null;
        sb = new pieGGC(aggArgs);
        break;
      case 'donut':
        aggArgs.innerRadius = '30%';
        sb = new pieGGC(aggArgs);
        break;
      case 'sunburst':
        sb = new sunburstGGC(aggArgs);
        break;
      case 'treemap':
        sb = new treemapGGC(aggArgs);
        break;
      case 'map':
        //sb = new mapGGC(aggArgs);
        break;
      case 'circlepack':
        sb = new circlepackGGC(aggArgs);
        break;
      default:
        sb = new hBarGGC(aggArgs);
    }

    // treemap and sunburst need clientProc to convert to hierarchical data
      var clientProc = null;
      if (isHierarchicalChart(graphType)) {
        clientProc = tabularToChildren;
      }
      const cParam = graphData.dimensions.length;
      sb.setData(
        {
          tableName: 'none',
          type: graphType,
          ajax: {
            type: 'POST',
            url: '/gservices/v1/graphdata',
            data: {
              start: 0,
              length: 1000,
              // saveProps: {argprops: sortOrder}
            },
            sampleData: getGraphData,
            clientProc: clientProc,   // tabularToChildren,
            clientProcParam: cParam,
            callback: function () {
            }
          },
        });

    sb.resize({ width: width, height: height });

    setMyGraph(sb);

//    $('#'+myId).append(`<div id="title" style="text-align: center; margin-left: -10px; margin-right: -10px; background-color:${style.backgroundColor}; font-size:24px">${graphData.title}</div>`);

  }

  const doFetch = async (url) => {

		try {
      const apiK = getApiKey();
			const result = await fetch(url, {
				headers: {
					Accept: '*/*',
					'Authorization': apiK,
				}
			});
			const json = await result.json();
			return json;
		} catch (err) {
			console.log(err);
			return {};
		}
	}

  // We can't call the API directly because it requires the API_KEY, so we need to call
  // an internal function and check. isn't returning responses: {active: {visists, updatedAt} ...}
	const getResponseDetails = async (baseId) => {
		const url = `https://api.qr-answers.com/v1/responsedetails/${encodeURIComponent(baseId)}`;
		return await doFetch(url)
	}
  /*
  const getResponseDetailsByCampaign = async (campaignId) => {
		const url = `https://api.qr-answers.com/v1/responsedetails/list/${encodeURIComponent(campaignId)}`;
		return await doFetch(url)

  }
  */
	const getCampaign = async (campaignId) => {
		const url = `https://api.qr-answers.com/v1/campaigns/${encodeURIComponent(campaignId)}`;
		return await doFetch(url)
	}
	const getLocation = async (locationId) => {
		const url = `https://api.qr-answers.com/v1/locations/${encodeURIComponent(locationId)}`;
		return await doFetch(url)
	}
	const getCampaignList = async (projectId) => {
		const url = `https://api.qr-answers.com/v1/campaigns/list/${encodeURIComponent(projectId)}`;
		return await doFetch(url)
	}

  const getQuestionAssignmentsByProject = async (projectId) => {
    const url = `https://api.qr-answers.com/v1/questionassignments/listbyproject/${encodeURIComponent(projectId)}`;
    return await doFetch(url)
  }
	const getAnswerList = async (questionId) => {
		const url = `https://api.qr-answers.com/v1/answers/list/${encodeURIComponent(questionId)}`;
		return await doFetch(url)
	}


  // /v1/graphdata/location/{campaignId}/{locationId}
  // /v1/graphdata/campaign/{campaignId}
  // /v1/graphdata/project/{projectId}

  const fetchLocationsAndCampaignsByQuestion = async (projectId, questionId) => {

    try {
      const [qas, camps] = await Promise.all([
        getQuestionAssignmentsByProject(projectId),
        getCampaignList(projectId),
      ]);


      if (qas && qas.data && qas.data.length > 0 &&
        camps && camps.data /*&& camps.data.length > 0 fuck*/) {
        // Get unique campaigns and locations
        let campaigns = [];
        let locations = [];
        let answers = [];
        let assignments = [];
        for (let i = 0; i < qas.data.length; i++) {
          const qa = qas.data[i];
          if (qa.questionAnswers.question.id === questionId) {
            assignments.push({id: qa.id, name: qa.questionAnswers.question.text});  // don't think we use name
            if (campaigns.findIndex((c) => c.id === qa.campaignID) === -1) {
              const cName = camps.data.find((c) => c.id === qa.campaignID);
              if (cName && cName.name) {
                campaigns.push({ id: qa.campaignID, name: cName.name });
              }
            }
            if (locations.findIndex((loc) => loc.id === qa.questionAnswers.location.id) === -1) {
              locations.push({ id: qa.questionAnswers.location.id, name: qa.questionAnswers.location.name });
            }
            for (let j = 0; j < qa.questionAnswers.answers.length; j++) {
              //   .text, .imageUrl, .id, .ansType link..
              const ans = qa.questionAnswers.answers[j]; 
              if (answers.findIndex((a) => a.id === ans.id) === -1) {
                answers.push({ id: ans.id, text: ans.text, imageUrl: ans.imageUrl }); // imageUrl could be undefined
              }
            }
          }
        }

        return { campaigns: campaigns, locations: locations, answers: answers, assignments: assignments };

      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }

  const compCats = (catIndexes, lvl, a, b) => {
    if (lvl < catIndexes.length) {
      const catIx = catIndexes[lvl];  // name of category Answer, Campaign, Location
      if (catIx < a.length && catIx < b.length) {
        if (a[catIx].name < b[catIx].name) return -1;
        if (a[catIx].name > b[catIx].name) return 1;
        return compCats(catIndexes, lvl + 1, a, b);
      } else {  // don't think this can happen...
        return 0;
      }
    } else {
      return 0;
    }
  }

  const makeLegendLabels = (legends, lvl, campaigns, locations, answers) => {
    let labels = [];
    let ids = [];
    if (lvl < legends.length) {
      let res = makeLegendLabels(legends, lvl + 1, campaigns, locations, answers);
      labels = res.labels;
      ids = res.ids;
      let myArray = null;
      let myField = null;
      switch (legends[lvl]) {
        case 'campaign':
          myArray = campaigns;
          myField = 'name';
          break;
        case 'location':
          myArray = locations;
          myField = 'name';
          break;
        case 'answer':
          myArray = answers;
          myField = 'text';
          break;
        default:
          break;
      }
      if (myArray) {    // always should be...
        let newLabels = [];
        let newIds = [];
        if (labels.length > 0) {
          let origLabelLen = labels.length;
          for (let j = 0; j < myArray.length; j++) {
            for (let i = 0; i < origLabelLen; i++) {
              const myLabel = myArray[j][myField] + ' - ' + labels[i];
              newLabels.push (myLabel);
              newIds.push([myArray[j].id, ...ids[i]]);
            }
          }
        } else {
          for (let j = 0; j < myArray.length; j++) {
            newLabels.push(myArray[j][myField]);
            newIds.push([myArray[j].id]);
          }
        }
        labels = newLabels;
        ids = newIds;
        // Now sort the labels so that if we are using the same labels in multiple graphs on a dashboard
        // they will be in the same order.
        const sortedIndeces = labels.map((e, i) => i).sort((a, b) => {
          if (labels[a] < labels[b]) return -1;
          if (labels[a] > labels[b]) return 1;
          return 0;
        }
        );
        labels = labels.map((e, i) => labels[sortedIndeces[i]]);
        ids = ids.map((e, i) => ids[sortedIndeces[i]]);
      }
    }
    return {labels, ids};
  }

  // categories = ['answer', ...]  group by first, then 2nd, etc.
  // legends = ['location', <m...>] for multiple legends, we need to combine
  // the members into n * m entries; where n is # values in 1st, m in second, etc.
  // Need to call this after filters change or categories/legends change, etc.
  const shapeData = (dataTable, dataHeader, categories, legends, voteType, campaigns, locations, answers) => {

    // First convert categories to indexes
    let catIndexes = [];
    for (let i = 0; i < categories.length; i++) {
      catIndexes.push(dataHeader.indexOf(categories[i]));
    }

    // Filter the data first
    let newTable = [];
    let hasImages = false;
    for (let i=0; i<dataTable.length; i++) {
      let row = dataTable[i];   // baseid, campaign, location, answer, active, draft, inactive
      const myCamp = campaigns.find((c) => c.id === row[1].id);
      if ((myCamp && myCamp.use) || campaigns.length === 0) {
        const myLoc = locations.find((l) => l.id === row[2].id);
        if (myLoc && myLoc.use) {
          const myAns = answers.find((a) => a.id === row[3].id);
          if (myAns && myAns.use) {
            if (myAns.imageUrl) {
              hasImages = true;
            }
            newTable.push(row);
          }
        }
      }
    }

    // Now, sort it by categories
    let sortedArray = newTable.sort(function (a, b) {
      return compCats(catIndexes, 0, a, b);
    })

    // create a new table with the categories and legends
    let newTableHeader = [];
    for (let i = 0; i < categories.length; i++) {
      newTableHeader.push(categories[i]);
    }

    // Now we need to add the columns for the data values. We need to expand the legends
    // array and concatenate the values n * m
    const {labels, ids} = makeLegendLabels(legends, 0, campaigns, locations, answers);
    newTableHeader.push(...labels);

    let aggIndexes = [];
    if (legends.length > 0) {
      for (let i = 0; i < legends.length; i++) {
        aggIndexes.push(dataHeader.indexOf(legends[i]));
      }
    } else {
      newTableHeader.push('Votes');
    }

    let valueIndex = dataHeader.indexOf(voteType);

    let lastCat = new Array(categories.length).fill('');
    let resultsTable = [];
    let aggValues = new Array(labels.length || 1).fill(0);
    for (let i = 0; i < sortedArray.length; i++) {
      let row = sortedArray[i];
      let catValues = [];
      for (let j = 0; j < categories.length; j++) {
        catValues.push(row[catIndexes[j]].name);
      }
      if (i === 0) {
        lastCat = catValues;
      }
      if (catValues.join(',') !== lastCat.join(',')) {
        // write out the lastCat and aggValues
        resultsTable.push([...lastCat, ...aggValues]);
        lastCat = catValues;
        aggValues = new Array(labels.length || 1).fill(0);
      }

      if (ids.length > 0) {
        for (let j = 0; j < ids.length; j++) {
          let eq = true;
          for (let k = 0; eq && k < aggIndexes.length; k++) {
            if (row[aggIndexes[k]].id !== ids[j][k]) {
              eq = false;
            }
          }
          if (eq) {
            aggValues[j] += row[valueIndex];
          }

        }
      } else {
        aggValues[0] += row[valueIndex];
      }

    }
    resultsTable.push([...lastCat, ...aggValues]);

    return {header: newTableHeader, data: resultsTable, hasImages: hasImages}

  }

  // turbo pivot:
  // select pivot(Gender_Code_GDM,count(Gender_Code_GDM)) as g, Employees_Total_GDM from testdatasource GROUP by Employees_Total_GDM order by Employees_Total_GDM desc limit 10
  // G_B\tG_F\tG_M\tEmployees_Total_GDM\n0\t0\t0\t3600000\n0\t5\t7\t2200000\n0\t0\t0\t763522\n0\t0\t2\t707685\n0\t2\t5\t618000\n0\t1\t0\t435000\n0\t0\t1\t377757\n0\t0\t75\t305000\n0\t0\t1\t300000\n0\t0\t2\t287000
  // so prefixes 'as' value to data value on pivot 
  //
  // This takes our 'qra' data and flattens it out into a standard table.  We use a 2d array
  // baseId, Answer (id), Campaign (id), Location (id), Active_Votes, Draft_Votes, Inactive_Votes
  // The ids can then reference into the campaigns, locations, answers for the text values
  const setupDataTable = (qra, campaigns, locations, answers) => {
    //let parts, myCampId, myLocId;

    let dataHeader = ['baseId', 'campaign', 'location', 'answer', 'active', 'draft', 'inactive'];   // array of rows (array)
    let dataTable = [];

    for (let i = 0; i < qra.length; i++) {
      const thisQra = qra[i];
      let parts = thisQra.id.split('_');  // camp_qloc_loc_ques
      let myCampId = parts[0];
      let myLocId = parts[2];

      for (let j = 0; j < thisQra.questionAnswers.answers.length; j++) {
        const thisAns = thisQra.questionAnswers.answers[j];
        const myAns = answers.find((a) => a.id === thisAns.id);
        const myCamp = campaigns.find((c) => c.id === myCampId);
        const myLoc = locations.find((l) => l.id === myLocId);
        let myRow = [
          thisQra.id,
          {id: myCampId, name: (myCamp && myCamp.name ? myCamp.name : '')},
          {id: myLocId, name: myLoc.name},
          {id: thisAns.id, name: myAns.text},   // use name so we don't have to case on field type
          thisAns.responses.active.visits || 0,
          thisAns.responses.draft.visits || 0,
          thisAns.responses.inactive.visits || 0,
        ];

        dataTable.push(myRow);
      }

    }

    // Maybe just set dataTable and dataHeader here - since we later may change filters, categories, legends
    // so, no need to reconstruct the base table...

    qraDispatch({type: 'dataTableSet', header: dataHeader, data: dataTable});

    return {dataHeader: dataHeader, dataTable: dataTable};
  }

  // We replace the data in the table with 'fake' data for each answer type.
  // We modify the dataTable because *if* we get a real answer, the dataTable gets updated
  // with real votes.
  const generateFakeData = () => {
    // qraState.dataHeader, qraState.dataTable

    let newTable = [...qraState.dataTable];
    let dataHeader = [...qraState.dataHeader];

    let ixActive = dataHeader.indexOf('active');  // y = 1 + sin(x)
    let ixDraft = dataHeader.indexOf('draft');    // 3x
    let ixInactive = dataHeader.indexOf('inactive');  // x2 = y

    for (let i=0; i<qraState.dataTable.length; i++) {
      let row = newTable[i];
      row[ixActive] = Math.floor(Math.random() * 100);
      row[ixDraft] = Math.floor(Math.random() * 100);
      row[ixInactive] = Math.floor(Math.random() * 100);
    }

    qraDispatch({type: 'dataTableSet', header: qraState.dataHeader, data: newTable});

  }

  // qra = [{projectID, campaignID, id, questionAnswers: {
  //    question: {test, id}, location: {name, id}, 
  //    answers: [{text, tags, ansType, id, iamgeUrl, link, linkAction, linkDescription, 
  //            responses: {active: {visits, updatedAt}, draft: {visits, updatedAt}, inactive: {visits, updatedAt}}, }]
  // }}]
  const fetchResponseDetails = async () => {
    let currentPivot = checkUidParams(uid);
    qraDispatch({type: 'fetching', fetching: true});

    let qra;
    let localCampaigns = [];
    let localLocations = [];
    let localAnswers = [];

    let localQraData = null;
    if (srcType === 'assignment') {
      qra = await getResponseDetails(baseId);
      // Need id and name for campaign and location
      // baseId = <campaignId>_<questLocId>_<locationId>_<questionId>
      if (qra && qra.data && qra.data.length === 1) {
        //2024-10-22 qra.data[0].questionAnswers.answers[i] .additionalInfo.graph.color may have default color
        const cid = qra.data[0].campaignID;
        const campaign = await getCampaign(cid);
        const location = await getLocation(qra.data[0].questionAnswers.location.id);
        let answers = [];
        for (let i=0; i<qra.data[0].questionAnswers.answers.length; i++) {
          let additionalInfo = {};
          if (qra.data[0].questionAnswers.answers[i].hasOwnProperty('additionalInfo')) {
            additionalInfo = qra.data[0].questionAnswers.answers[i].additionalInfo;
          }
          answers.push({
            id: qra.data[0].questionAnswers.answers[i].id, 
            text: qra.data[0].questionAnswers.answers[i].text,
            imageUrl: qra.data[0].questionAnswers.answers[i].imageUrl,
            use: true,
            additionalInfo: additionalInfo
          });
        }

        if (campaign && campaign.data && /*campaign.data.length > 0 &&*/
          location && location.data && location.data.length > 0 && answers.length > 0) {
            // The '.use' flag is used to determine if the campaign/location is used in the graph
            if (campaign.data.length > 0) {
              localCampaigns.push({ id: campaign.data[0].id, name: campaign.data[0].name, use: true });
            }

            if (location.data.length > 0) {
              localLocations.push({ id: location.data[0].id, name: location.data[0].name, use: true });
            }
            localAnswers = answers;
          qraDispatch({
            type: 'campLocSet', campaigns: localCampaigns, locations: localLocations, answers: answers
          });
          localQraData = [qra.data[0]];
        }
      }

    } else if (srcType === 'question') {  // NOTE: we *only* show 'active' since no _draft# or _inactive#
      let qras;

      // questionTreeData[baseId (really questionId)] = {campaigns: [], locations: [], assignments: []}
      if (!(questionTreeData && questionTreeData[baseId])) {  // dashboard not dev, otherwise these are set when we load the treeview
        try {
          const qla = await fetchLocationsAndCampaignsByQuestion(projectId, baseId);
          questionTreeData[baseId] = { campaigns: qla.campaigns, locations: qla.locations, answers: qla.answers, assignments: qla.assignments };
          localCampaigns = qla.campaigns;
          localLocations = qla.locations;
          localAnswers = qla.answers;
          for (let i=0; i<localCampaigns.length; i++) {
            localCampaigns[i].use = true;
          }
          for (let i=0; i<localLocations.length; i++) {
            localLocations[i].use = true;
          }
          for (let i=0; i<localAnswers.length; i++) {
            localAnswers[i].use = true;
          }
          qraDispatch({type: 'campLocSet', campaigns: localCampaigns, locations: localLocations, answers: localAnswers});


        } catch (err) {
          toast.error(err.message);
          return;
        }
      } else { // else dev.dashboard, so set
        localCampaigns = questionTreeData[baseId].campaigns;
        localLocations = questionTreeData[baseId].locations;
        localAnswers = questionTreeData[baseId].answers;
        for (let i = 0; i < localCampaigns.length; i++) {
          localCampaigns[i].use = true;
        }
        for (let i = 0; i < localLocations.length; i++) {
          localLocations[i].use = true;
        }
        if (!localAnswers) {
          // get id, text, imageUrl
          try {
            const fullAnswers = await getAnswerList(baseId);
            localAnswers = fullAnswers.data.map((ans) => {
              return { id: ans.id, text: ans.text, imageUrl: ans.imageUrl };
            });
          } catch (err) {
            console.log(err);
            localAnswers = [];  // can't really go on here...
          }
        }
        for (let i=0; i<localAnswers.length; i++) {
          localAnswers[i].use = true;
        }
        qraDispatch({type: 'campLocSet', campaigns: localCampaigns, locations: localLocations, answers: localAnswers});
      }

      qras = await Promise.all(
        questionTreeData[baseId].assignments.map(async (qa) => {
          return getResponseDetails(qa.id);
        })
      );
      localQraData = [];
      for (let i=0; i<qras.length; i++) {
        if (qras[i] && qras[i].success && qras[i].data && qras[i].data.length > 0) {
          localQraData.push( qras[i].data[0] );
        }
      }
      // qras[n].success && qras[n].data[0].questionAnswers.answers[i].responses['active'].visits etc.

    }

    qraDispatch({type: 'fetching', fetching: false});

    if (localQraData) {

      var voteType; // this only really sets for 'assignment' vs. 'question', as the baseId
      // for assignment may have a _xxx# on it, where a question does not. We could add the extensions
      // to the 'questionId' (when passed as a srcType=='question' at some point)
      if (baseId.endsWith('_draft#')) {
        voteType = 'draft';
      } else if (baseId.endsWith('_inactive#')) {
        voteType = 'inactive';
      } else {
        voteType = 'active';
      }

      const {dataHeader, dataTable} = setupDataTable(localQraData, localCampaigns, localLocations, localAnswers);

      let ixActive = dataHeader.indexOf('active');
      let ixDraft = dataHeader.indexOf('draft');
      let ixInactive = dataHeader.indexOf('inactive');
      let sumAll = 0;
      for (let i = 0; i < dataTable.length; i++) {
        let row = dataTable[i];
        sumAll += row[ixActive] + row[ixDraft] + row[ixInactive];
      }
      if (sumAll === 0) {
        qraDispatch({ type: "setNoResponseData", noResponseData: true });
        toast.error(t("msg.no_registered_votes"));
      }


      // data, header, categories, legend. these are undefined if new, set if loaded from open dashboard
      let categories = savedProps.categories || ['answer'];
      let legends = savedProps.legends || ['location'];

      // Doing this shapes the data for the pivot...
      if (currentPivot) {
        let tmp = categories;
        categories = legends;
        legends = tmp;
      }

      const { header, data, hasImages } = shapeData(dataTable, dataHeader, categories, legends, voteType, localCampaigns, localLocations, localAnswers);

      qraDispatch({ type: 'filteredDataSet', header: header, data: data, hasImages: hasImages });


      let questionText = localQraData[0].questionAnswers.question.text
      qraDispatch({ type: 'questionTextSet', questionText: questionText });

      // parse up into graphData. Sort by 1st column before passing in.
      let gData = setupAssignmentGraphingData(
        header, 
        data, 
        questionText, 
        graphType, 
        currentPivot,
        categories);

      gData.campaignName = localCampaigns.length > 0 && localCampaigns[0].name ? localCampaigns[0].name : '';
      gData.locationName = localLocations[0].name;

      qraDispatch({type: 'qraSet', data: localQraData});
      setGraphData(gData);


      setHasImages(hasImages);

      //console.log(qra);
      setMyId('G' + _genId());

    } else if (qra && qra.message) {
      toast.error(qra.message);
    }
  }

  useEffect(() => {
    fetchResponseDetails();
  }, [uid]);

 
  // requires Auth for logged in user (Developer). We need a version for a non-dev like
  // qranswers.subscription.subscribeToSpecificQuestionResponse.
  const subscribeToQuestionResponse = (baseId, callBack, errCallBack) => {
    let subParams = {
      query: onResponseBySpecificQuestion,
      variables: { baseId: baseId },
    };
    if (!developer) {   // End User
      subParams.authToken = getApiKey()
    }
    const subscription = API.graphql(
      subParams
    ).subscribe({
      next: ({ provider, value }) => {
        const response = value.data.onResponseBySpecificQuestion;
        callBack(response);
      },
      error: (error) => {
        console.warn(error)
        if (errCallBack) {
          errCallBack(error);
        }
      },
    });
    return subscription;
  }
  const subscribeToProjectResponse = (projectId, callBack, errCallBack) => {
    let subParams = {
      query: onResponseByProjectId,
      variables: { projectId: projectId },  // really projectId
    };
    if (!developer) {   // End User
      subParams.authToken = getApiKey()
    }
    const subscription = API.graphql(
      subParams
    ).subscribe({
      next: ({ provider, value }) => {
        const response = value.data.onResponseByProjectId;
        // projectresponse
        const bParts = response.baseId.split('_');
        // Overkill here - srcType should be known and *all* baseIds are min 4 parts
        if (srcType === 'question' && bParts.length > 3 && bParts[3] === baseId) {
          // is our 'question'
          callBack(response);
        }
      },
      error: (error) => {
        console.warn(error)
        if (errCallBack) {
          errCallBack(error);
        }
      },
    });
    return subscription;
  }

  // srcType == 'assignment'
  // 2024-01-18 - changed localQraData from object to array to support 'question' leafs
  // vs. 'assignment' leafs
  const setupAssignmentGraphingData = (header, data, questionText, gType, lpivot, categories) => {
    // The theory here is that if we are a question and have multiple localQraData values
    // that the questionAnswers data is identical for each - since they are all the same
    // question...  So we can just use the first one.
    var gData = {
      data: [],
      type: gType,
      canSort: canSort(gType) && uidState.showSorter && !isQRContest(),
      recordsTotal: data.length,
      recordsFiltered: data.length,
      title: questionText,
    }

    // sets .header, .dimensions, .data
    gData.header = [...header];
    gData.dimensions = [...categories];
    gData.data = data;

    // Default is ascending sort on Answer Column
    if (false/*gType !== 'pie' && gType !== 'donut'*/) {
      // I think we should sort by categories ASC
      const dlen = categories.length;
      if (dlen > 0) {
        gData.data.sort((a, b) => {
          for (let i = 0; i < dlen; i++) {
            if (a[i] < b[i]) {
              return -1;
            } else if (a[i] > b[i]) {
              return 1;
            }
          }
          return 0;
        });
      }
    }

    gData.pivot = lpivot;

    return gData;
  }

  useEffect(() => {
    const actOnChanges = async () => {
      // Change data only don't delete graph and recreate. pivot changed here maybe
      if (qraState.dataTable && qraState.dataHeader && myGraph) {

        var voteType;
        if (baseId.endsWith('_draft#')) {
          voteType = 'draft';
        } else if (baseId.endsWith('_inactive#')) {
          voteType = 'inactive';
        } else {
          voteType = 'active';
        }

        let categories = uidState.categories || ['answer'];
        let legends = uidState.legends || ['location'];
        if (pivot) {
          let tmp = categories
          categories = legends;
          legends = tmp;
        }

        const { header, data, hasImages } = shapeData(qraState.dataTable, qraState.dataHeader, categories, legends, voteType, qraState.campaigns, qraState.locations, qraState.answers);

        qraDispatch({ type: 'filteredDataSet', header: header, data: data, hasImages: hasImages });

        // parse up into graphData. Sort by 1st column before passing in.
        // (header, data, questionText, gType, lpivot, categories) => {

        let gData = setupAssignmentGraphingData(
          header,
          data,
          qraState.questionText,
          graphType, pivot,
          categories);

        gData.locationName = qraState.locations[0].name;
        gData.campaignName = qraState.campaigns[0].name;

        setHasImages(hasImages);

        if (graphType !== 'pivot') {
          //setGraphData(gData); do not update this, it redraws whole thing from scratch
          var clientProc = null;
          if (isHierarchicalChart(graphType)) {
            clientProc = tabularToChildren;
          }
          const cParam = gData.dimensions.length;


          // have to set data, header, recordsTotal, recordsFiltered, etc.
          if (myGraph.getGraphType() === graphType) { // not updated yet, will call showGraph shortly...
            myGraph.setData(
              {
                tableName: 'none',
                type: graphType,
                ajax: {
                  type: 'POST',
                  url: '/gservices/v1/graphdata',
                  data: {
                    start: 0,
                    length: 1000,
                    // saveProps: {argprops: sortOrder}
                  },
                  sampleData: getGraphData,
                  clientProc: clientProc,   // tabularToChildren,
                  clientProcParam: cParam,
                  callback: function () {
                  }
                },
              });

            // Need gData.data set here. FYI, updateGraphData calls _setGraphData - which would trigger
            // _setupSorters
            myGraph.updateGraphData(gData);
            resizeComp();
          }

        } else {
          // draw pivot - there are no settings, so could just comletely redraw it.
          setGraphData(gData);    // was setAltData 2023/11/06
        }
      }
    }
    actOnChanges();
  }, [qraState.dataTable, qraState.dataHeader]); // should we add a flag on 'response' ?

  // Called on change of pivot.  Should also be triggered when subscription of responses
  // is triggered. The problem is that it redraws the whole graph because this sets
  // graphData.  If we *only* change dataTable, then we should be able to just update the
  // data and not force a complete teardown and rebuild of the graph.
  // 2024-02-27 - removing dataTable and dataHeader as dependency seems to work for
  // not triggering this.  We need to trigger a reduced version of this when the dataTable
  // changes.
  useEffect(() => {
    const actOnChanges = async () => {
      // Change data only don't delete graph and recreate. pivot changed here maybe
      if (qraState.dataTable && qraState.dataHeader && myGraph) {

        var voteType;
        if (baseId.endsWith('_draft#')) {
          voteType = 'draft';
        } else if (baseId.endsWith('_inactive#')) {
          voteType = 'inactive';
        } else {
          voteType = 'active';
        }

        let categories = uidState.categories || ['answer'];
        let legends = uidState.legends || ['location'];
        if (pivot) {
          let tmp = categories
          categories = legends;
          legends = tmp;
        }
  
        const { header, data, hasImages } = shapeData(qraState.dataTable, qraState.dataHeader, categories, legends, voteType, qraState.campaigns, qraState.locations, qraState.answers);
 
        qraDispatch({ type: 'filteredDataSet', header: header, data: data, hasImages: hasImages });

        // parse up into graphData. Sort by 1st column before passing in.
        // (header, data, questionText, gType, lpivot, categories) => {

        let gData = setupAssignmentGraphingData(
          header, 
          data, 
          qraState.questionText, 
          graphType, pivot,
          categories);

        gData.locationName = qraState.locations[0].name;
        gData.campaignName = qraState.campaigns.length > 0 ? 
              qraState.campaigns[0].hasOwnProperty('name') ? qraState.campaigns[0].name : "" : "";
    
        setHasImages(hasImages);

        if (graphType !== 'pivot') {
          setGraphData(gData);    // was setAltData 2023/11/06
          var clientProc = null;
          if (isHierarchicalChart(graphType)) {
            clientProc = tabularToChildren;
          }
          const cParam = gData.dimensions.length;


          // have to set data, header, recordsTotal, recordsFiltered, etc.
          if (myGraph.getGraphType() === graphType) { // not updated yet, will call showGraph shortly...
            myGraph.setData(
              {
                tableName: 'none',
                type: graphType,
                ajax: {
                  type: 'POST',
                  url: '/gservices/v1/graphdata',
                  data: {
                    start: 0,
                    length: 1000,
                    // saveProps: {argprops: sortOrder}
                  },
                  sampleData: getGraphData,
                  clientProc: clientProc,   // tabularToChildren,
                  clientProcParam: cParam,
                  callback: function () {
                  }
                },
              });

            // Need gData.data set here. FYI, updateGraphData calls _setGraphData - which would trigger
            // _setupSorters
            myGraph.updateGraphData(gData);
            resizeComp();
          }

        } else {
          // draw pivot - there are no settings, so could just comletely redraw it.
          setGraphData(gData);    // was setAltData 2023/11/06
        }
      }
    }
    actOnChanges();

  }, [pivot, graphType /*, qraState.dataTable, qraState.dataHeader*/, uidState.categories, uidState.legends, myId]);

  useEffect(() => {
    if (baseId) {
      let sub;
      if (srcType === 'question') {
        sub = subscribeToProjectResponse(projectId, (response) => {
          qraDispatch({ type: 'response', response: response });
          notifyContainer({ type: 'noresponsedata', noResponseData: false });
        })

      } else {
        sub = subscribeToQuestionResponse(baseId, (response) => {
          // {clientId: <>, projectId: <>, baseId: <>, answerId: <>, count: 1 or -1}
          // update graphData.data
          qraDispatch({ type: 'response', response: response });
          notifyContainer({ type: 'noresponsedata', noResponseData: false });
        })
      }

      return () => {
        if (sub)
          sub.unsubscribe();
      }
    }

  }, [baseId]); // removed myId

/*
  const fixHeaderAndData = (dSource, qra, campaigns, locations, voteType) => {
    let answerData = [];
    let answerOrder = [];
    for (let i = 0; i < qra[0].questionAnswers.answers.length; i++) {
      let ans = qra[0].questionAnswers.answers[i];
      answerData.push([ans.text]);
      answerOrder.push(ans.id);
    }

    dSource.header = [];
    dSource.dimensions = [];
    dSource.header.push( t("graph.lbl.answer") );
    dSource.dimensions = [{name: t("graph.lbl.answer")}];

    if (qra.length > 1) {
      //NOTE: the qra array could span across multiple compaigns. So, the 'location' names
      // could be duplicated.  So, we only push 'unique' locations.
      // So, need to aggregate across locations.  We can do the same w/ Campaigns if they are
      // used as the Values vs. Locations.
      for (let i = 0; i < qra.length; i++) {
        const thisQra = qra[i];
        const parts = thisQra.id.split('_');  // camp_qloc_loc_ques
        const myCampId = parts[0];
        const myLocId = parts[2];
        const myCamp = campaigns.find((c) => c.id === myCampId);
        const myLoc = locations.find((l) => l.id === myLocId);
        let useThisOne = myCamp.use && myLoc.use;   
        if (useThisOne) {
          dSource.header.push(myLoc.name);
        }
      }
    } else {
      dSource.header.push(t("graph.lbl.votes"));
    }

    let parts, myCampId, myCamp, myLoc, myLocId, useThisOne;

    for (let k = 0; k < answerData.length; k++) {
      for (let j = 0; j < qra.length; j++) {
        const thisQra = qra[j];
        parts = thisQra.id.split('_');  // camp_qloc_loc_ques
        myCampId = parts[0];
        myLocId = parts[2];
        myCamp = campaigns.find((c) => c.id === myCampId);
        myLoc = locations.find((l) => l.id === myLocId);
        useThisOne = myCamp.use && myLoc.use;
        if (useThisOne) {
          const qAns = thisQra.questionAnswers.answers.find((a) => a.id === answerOrder[k]);
          if (qAns) {
            answerData[k].push(qAns.responses[voteType].visits || 0);
          } else {
            answerData[k].push(0);
          }
        }
      }
    }
    dSource.data = answerData;
  }
  */

    const getGraphData = useCallback((sortOrder) => {
      var dSource = {...graphData};
      // Filter by used campaigns and locations
      // 
      let voteType;
      if (baseId.endsWith('_draft#')) {
        voteType = 'draft';
      } else if (baseId.endsWith('_inactive#')) {
        voteType = 'inactive';
      } else {
        voteType = 'active';
      }


      let categories = uidState.categories || ['answer'];
      let legends = uidState.legends || ['location'];
      if (pivot || graphData.pivot) {
        let tmp = categories
        categories = legends;
        legends = tmp;
      }

      if (qraState.dataTable && qraState.dataHeader) {
        const { header, data, hasImages } = shapeData(qraState.dataTable, qraState.dataHeader, categories, legends, voteType, qraState.campaigns, qraState.locations, qraState.answers);
        qraDispatch({ type: 'filteredDataSet', header: header, data: data, hasImages: hasImages });
        // sets .header, .dimensions, .data
        dSource.header = [...header];
        dSource.dimensions = [...categories];
        dSource.data = data;
        dSource.recordsTotal = data.length;
        dSource.recordsFiltered = data.length;
  
      } // otherwise we have the data already in graphData

  
      if (sortOrder) {
        // array of dimension `1 ASC` or `1 DESC`. 1 based off of position in data array
        // so, if we have  ['1 ASC', '2 DESC'] that means sort by column 0 in data, then column 1
        try {
          let dataArray;
          let sortedArray;

          dataArray = [...dSource.data];
          sortedArray = dataArray.sort((a, b) => {
            for (let i = 0; i < sortOrder.length; i++) {
              let sort = sortOrder[i];
              let sortCol = parseInt(sort.split(' ')[0]) - 1;
              let sortDir = sort.split(' ')[1];
              if (a[sortCol] < b[sortCol]) {
                return sortDir === 'ASC' ? -1 : 1;
              }
              if (a[sortCol] > b[sortCol]) {
                return sortDir === 'ASC' ? 1 : -1;
              }
            }
            return 0;
          });

          if (JSON.stringify(sortedArray) !== JSON.stringify(dSource.data)) {
            dSource.data = sortedArray;
          }
  
        } catch (err) {
          console.log(err);
        }
      }
  
      return dSource;
  
    }, [graphData, pivot, uidState.categories, uidState.legends, qraState.locations, qraState.campaigns]);
 

  useEffect(() => {
    if (graphData) {
      showGraph();    // showGraph actually deletes and recreates the graph (losing internal state of the graph)
    }

  }, [graphData, savedProps, graphType, pivot]);

  const resizeComp = () => {
    if (graphData && myGraph) {
      const opts = {width: $('#'+myId).width(), height: $('#'+myId).height()};
      myGraph.resize(opts);
    }
  }

  useEffect(() => {
    const debounceResize = debounce(function rc() {
      resizeComp();
    }, 200)

    if (cascadeRefresh > 0) {
      // This is a hack to let the UI resize before we repaint the jQuery graph.
      debounceResize();
    }
  }, [cascadeRefresh]);

  useEffect(() => {
    if (rerender ) {
      resizeComp();
    }
  }, [rerender]);

  useEffect(() => {
    if (graphData && myGraph) {
      const opts = {width: $('#'+myId).width(), height: $('#'+myId).height(),refreshData: true};
      myGraph.resize(opts);
    }
  }, [qraState.campaigns, qraState.locations]);
  
	useEffect(() => {
		window.addEventListener('resize', resizeComp);
		return () => {window.removeEventListener('resize', resizeComp)};
	}, [graphData, myGraph]);
  
  const d3Ref = useRef();

	// style={[{width: '100%', height: '100%'},{...style}]}
  let gHeight = 100;
  let roomForLocCamp = false;
  let roomForQuestion = false;
  if (graphData && (uidState.showLocationName || uidState.showCampaignName) && 
      ((graphData.locationName && graphData.locationName.length > 0) || 
       (graphData.campaignName && graphData.campaignName.length > 0))) {
    gHeight -= uidState.locCampHeight;
    roomForLocCamp = true;
  }
  if (graphData && graphData.title && graphData.title.length > 0 && uidState.showQuestion) {
    gHeight -= uidState.questionHeight;
    roomForQuestion = true;
  }
  // 2024-01-29 added position relative so position absolute of tooltip works.
  const newStyle = {position: 'relative', margin: 0, width: '100%', height: `${gHeight}%`, ...style };
  // padding: 10 is below for margins so some background shows all the way around
  return (
    <div id={"grC_"+uid} style={{
      border: uidState.editMode ? '1px dashed '+ colors.primary : 'none',
      padding: uidState.containerPadding, 
      display: 'flex', 
      backgroundColor: uidState.cellBGColor, 
      flexDirection: 'column', 
      width: '100%', height: '100%' 
    }}>
      {roomForLocCamp &&
        <>
          <div id={"lcP_"+uid} style={{ height: `${uidState.locCampHeight}%`, display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: `${uidState.locationWidth}%`, display: 'flex', flexDirection: 'row' }}>
              {uidState.showLocationName ?
              <>
                {((developer && uidState.selected && !uidState.editMode) || (!developer)) && 
                      !uidState.lockLocation && !uidState.pickingLocations && qraState.locations.length > 1 ?
                  <FaChevronDown style={{
                    width: 18,
                    marginLeft: 4,
                    marginRight: 4,
                    alignSelf: 'center',
                    cursor: 'pointer',
                  }} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    uidDispatch({ type: 'setPickingLocations', picking: true });
                  }}
                  />
                  : null
                }
                <QRScaleText
                  style={{ display: 'flex' }}
                  overflow={'visible'}
                  onResize={(fnt) => {
                    //uidDispatch({ type: 'setTxtFontSize', pval: fnt });
                  }}
                >
                  <div
                    style={{
                      color: uidState.txtLocationColor,
                      fontStyle: uidState.txtLocationFontStyle,
                      fontWeight: uidState.txtLocationFontWeight,
                      textDecorationLine: uidState.txtLocationDecoration,
                      marginRight: 'auto',
                      marginLeft: 8,
                      alignSelf: 'center',      // 'flex-end',
                      whiteSpace: 'nowrap',
                    }}>{graphData && graphData.locationName}</div>
                </QRScaleText>
                {((developer && uidState.selected && !uidState.editMode) || (!developer)) && 
                          !uidState.lockLocation && uidState.pickingLocations && qraState.locations.length > 1 ?
                  <DropDownChecks
                    style={{
                      position: 'absolute', top: 5, left: 5, width: 240, bottom: 20, zIndex: 100,
                      background: '#ffffff',
                      marginLeft: 16,
                      boxShadow: '4px 8px #22222240',
                    }}
                    dataList={qraState.locations}
                    checkField="use"
                    onDone={() => {
                      uidDispatch({ type: 'setPickingLocations', picking: false });
                    }}
                    onChange={(newItem) => {
                      qraDispatch({ type: 'locUse', id: newItem.id, use: newItem.use });
                    }}
                  />
                  : null
                }
              </>
                : null}
            </div>
            {developer && uidState.selected && uidState.editMode ?
              <VerticalSizer parentId={"lcP_"+uid} value={uidState.locationWidth}
                padding={uidState.containerPadding}
                onChange={(pos) => {
                  uidDispatch({ type: 'setLocCampWidth', pos: pos });
                }}
              />
              : null}
            <div style={{ width: `${uidState.campaignWidth}%`, display: 'flex', flexDirection: 'row' }}>
              {uidState.showCampaignName ?
              <>
                <QRScaleText
                  style={{ display: 'flex' }}
                  overflow={'visible'}
                  onResize={(fnt) => {
                    //uidDispatch({ type: 'setTxtFontSize', pval: fnt });
                  }}
                >
                  <div
                    style={{
                      color: uidState.txtCampaignColor,
                      fontStyle: uidState.txtCampaignFontStyle,
                      fontWeight: uidState.txtCampaignFontWeight,
                      textDecorationLine: uidState.txtCampaignDecoration,
                      marginRight: 8,
                      marginLeft: 'auto',
                      alignSelf: 'center',      // 'flex-end',
                      whiteSpace: 'nowrap',
                    }}>{graphData && graphData.campaignName}</div>
                </QRScaleText>
                {((developer && uidState.selected && !uidState.editMode) || (!developer)) && 
                          !uidState.lockCampaign && !uidState.pickingCampaigns && qraState.campaigns.length > 1 ?
                  <FaChevronDown style={{
                    width: 18,
                    marginLeft: 4,
                    marginRight: 4,
                    alignSelf: 'center',
                    cursor: 'pointer',
                  }} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    uidDispatch({ type: 'setPickingCampaigns', picking: true });
                  }}
                  />
                  : null
                }
                {((developer && uidState.selected && !uidState.editMode) || (!developer)) && 
                    !uidState.lockCampaign && uidState.pickingCampaigns && qraState.campaigns.length > 1 ?
                  <DropDownChecks
                    style={{
                      position: 'absolute', top: 5, right: 5, width: 240, bottom: 20, zIndex: 100,
                      background: '#ffffff',
                      marginLeft: 16,
                      boxShadow: '4px 8px #22222240',
                    }}
                    dataList={qraState.campaigns}
                    checkField="use"
                    onDone={() => {
                      uidDispatch({ type: 'setPickingCampaigns', picking: false });
                    }}
                    onChange={(newItem) => {
                      qraDispatch({ type: 'campUse', id: newItem.id, use: newItem.use });
                    }}
                  />
                  : null
                }
              </>
              : null}
            </div>
          </div>
        {developer && uidState.selected && uidState.editMode && roomForQuestion &&
          <HorizontalSizer parentId={"grC_" + uid} max={uidState.locCampHeight + uidState.questionHeight} value={uidState.locCampHeight}
            padding={uidState.containerPadding}
            onChange={(pos) => {
              uidDispatch({ type: 'setHeightFromLocCamp', pos: pos });
              resizeComp();
            }} />
        }

        </>
      }
      {roomForQuestion &&
        <div style={{ height: `${uidState.questionHeight}%` }}>
          <QRScaleText
            style={{ display: 'flex' }}
            overflow={'visible'}
            onResize={(fnt) => {
              //uidDispatch({ type: 'setTxtFontSize', pval: fnt });
            }}
          >
            <div
              style={{
                color: uidState.txtColor,
                fontStyle: uidState.txtFontStyle,
                fontWeight: uidState.txtFontWeight,
                textDecorationLine: uidState.txtDecoration,
                marginRight: 'auto',
                marginLeft: 'auto',
                alignSelf: 'center',      // 'flex-end',
                whiteSpace: 'nowrap',
              }}>{graphData && graphData.title}</div>
          </QRScaleText>
        </div>
      }
      {developer && uidState.selected && uidState.editMode && (roomForLocCamp || roomForQuestion) &&
        <HorizontalSizer parentId={"grC_" + uid} value={
          (roomForLocCamp ? uidState.locCampHeight : 0) +
          (roomForQuestion ? uidState.questionHeight : 0)
        }
          padding={uidState.containerPadding}
          onChange={(pos) => {
            uidDispatch({ type: 'setHeightFromSplitter', pos: pos });
            resizeComp();
          }} />
      }

      <D3Wrapper
        myId={myId}
        ref={d3Ref}
        style={newStyle}
        myGraph={myGraph}
        getGraphData={getGraphData}
      />
    </div>
  );
}
)

export default GraphComponent;
